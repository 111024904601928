import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const InfoWeAreMakersStyle = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  :last-child {
    margin-bottom: 120px;
  }

  ${props => config(props).media['md']`
        padding-top: 69px;
        margin-top: 0px;
    `}
  .Text {
    height: 100%;
    display: flex;
    width: 100%;
    padding-left: 20px;
    flex-direction: column;
    ${props => config(props).media['md']`
        width: 61%;
    `}
    h1 {
      color: ${({theme}) => theme.colors.primary};
      font-weight: 500;
      font-size: 1.45em;
      width: 100%;
      ${props => config(props).media['md']`
         margin-top: 55px;
    `}
    }

    h2 {
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: 400;
    }
  }
  .ContainerImage {
    display: flex;
    width: 100%;
    padding-top: 20px;
    justify-content: flex-end;
  }
  button {
    width: 25%;
    .text {
      font-size: 10px;
      letter-spacing: 0.1em;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;
