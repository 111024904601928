import styled from 'styled-components';

export const StyledButtonCategory = styled.button`
  width: 180px;
  height: 44px;
  background-color: transparent;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid ${({theme}) => theme.colors.primary3};
  cursor: pointer;

  p {
    color: ${({theme}) => theme.colors.primary2};
    font-weight: 600;
    font-size: 13px;
  }

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.primary2};
  }

  &:active {
    border: 1px solid ${({theme}) => theme.colors.primary3};

    p {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
`;
