import * as React from 'react';
import {useGlobalContext} from '../../../context';
import TransitionPage from '../../TransferPage';
import {useLocation} from '@reach/router';
import {autoLogin} from '../../../API/makers/users';
import {useEffect} from 'react';
import {navigate} from 'gatsby';
import {useAlert} from 'react-alert';
import {getCurrentOrder} from '../../../API/makers/orders';
import {setOrder} from '../../../services/temporalOrder';
import {getCurrentUser} from '../../../services/authentication';

const MagicLink = () => {
  const {href} = useLocation();
  const token = href.split('token=')[1];
  const {refreshUser} = useGlobalContext();
  const alert = useAlert();

  useEffect(() => {
    setTimeout(() => {
      autoLogin(token, () => {
        getCurrentOrder(getCurrentUser()['Access-Token'], order => {
          if (order?.number !== undefined) {
            setOrder(order.number);
          }
        });
        navigate('/');
        refreshUser();
      }).catch(() => {
        alert.error('token is not valid');
        setTimeout(() => {
          navigate('/');
        }, 3000);
      });
    }, 3000);
  }, []);

  return (
    <>
      <TransitionPage isLoading />
    </>
  );
};

export default MagicLink;
