import {navigate} from 'gatsby';
import {useAlert} from 'react-alert';
import {useQueryParam, StringParam} from 'use-query-params';
import React, {useCallback, useEffect, useState, useRef} from 'react';

import {
  step1Schema,
  step2Schema,
  contactSchema,
} from '../../../formSchema/validator';
import FormRegister from '../../FormRegister';
import HeaderRegister from '../../HeaderRegister';
import {useGlobalContext} from '../../../context';
import TellUsAbout from '../../FormRegister/TellUsAbout';
import registerUser from '../../../API/makers/registerUser';
import CompleteRegister from '../../FormRegister/Complete';

const RegisterPage = () => {
  const [pageQueryRegister] = useQueryParam('step', StringParam);
  const [validForm, setValidForm] = useState(false);
  const [errors, setErrors] = useState([]);
  const schemaBySteps = [step1Schema, step2Schema, contactSchema];
  const {disableBtn, setDisableBtn} = useGlobalContext();
  const alert = useAlert();

  useEffect(() => {
    setDisableBtn(true);
  }, []);

  const formRef = useRef();

  const registerDefault = {
    name: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    company: {
      companyName: '',
      cellNumber: '',
      companyPhoneNumber: '',
      companyEmail: '',
      address: '',
      country: process.env['GATSBY_DEFAULT_COUNTRY_ID'],
      state: '',
      city: '',
      postCode: '',
      taxOrIdNumber: '',
      certificateCompany: '',
      webSiteURL: '',
    },
    termsConditions: false,
  };
  const [register, setRegister] = useState(registerDefault);

  const validate = useCallback(() => {
    const schema = schemaBySteps[pageQueryRegister - 1];
    const errors =
      schema.validate(register, {
        allowUnknown: true,
        abortEarly: false,
      })?.error?.details || [];
    const isValid = !errors.length;
    setValidForm(isValid);
    setErrors(errors);
    return isValid;
  }, [register]);

  const handleNextClick = event => {
    event && event.preventDefault();
    if (validate()) {
      if (pageQueryRegister == 1) {
        navigate('/register?step=2');
      } else if (pageQueryRegister == 2) {
        navigate('/register?step=3');
      }
    }
  };

  const handleInputChange = (key, defaultValue = undefined) => {
    return event => {
      setRegister({
        ...register,
        [key]: defaultValue || event.target.value,
      });
    };
  };

  const handleInputObjectChange = (objectKey, key, defaultValue = 0) => {
    return event => {
      setRegister({
        ...register,
        [objectKey]: {
          ...register[objectKey],
          [key]: defaultValue || event.target.value,
        },
      });
    };
  };

  const handleValueChange = key => {
    return () => {
      setRegister({
        ...register,
        [key]: !register[key],
      });
    };
  };

  const [userLogin, setUserLogin] = useState(null);

  const handleRegister = event => {
    event.preventDefault();
    const data = new FormData(formRef.current);
    if (validate()) {
      setDisableBtn(true);
      register.termsConditions === true &&
        registerUser(data, () => {
          navigate('/');
          setValidForm(false);

          setUserLogin({
            email: register.email,
            password: register.password,
          });
          handleNextClick();
        }).catch(({errors: {email}}) => alert.error(`this email ${email}`));
    }
  };

  return (
    <>
      {pageQueryRegister !== '3' && <HeaderRegister />}
      <form ref={formRef} onSubmit={handleRegister}>
        <FormRegister
          onNextClick={handleNextClick}
          display={pageQueryRegister === '1'}
          onInputChange={handleInputChange}
          register={register}
          validForm={validForm}
          errors={errors}
        />
        <TellUsAbout
          register={register}
          display={pageQueryRegister === '2'}
          onInputChange={handleInputChange}
          onInputObjectChange={handleInputObjectChange}
          onValueChange={handleValueChange}
          validForm={validForm}
          errors={errors}
          disableBtn={disableBtn}
        />

        {pageQueryRegister === '3' && (
          <CompleteRegister
            pageQueryRegister={pageQueryRegister}
            userLogin={userLogin}
          />
        )}
      </form>
    </>
  );
};

export default RegisterPage;
