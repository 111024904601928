import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const LookbookStyled = styled.div`
  z-index: -100;
  background: url(${({background}) => background}) no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 400px;
  float: left;
  margin: 40px 0;

  @supports (-webkit-touch-callout: none) {
    orientation: landscape;
    background: url(${({background}) => background}) no-repeat center center;
    background-attachment: scroll;
    width: 100vw;
    background-size: cover;
    margin-top: 0px;
    margin-bottom: 40px;
    ${props => config(props).media['sm']`
        margin: 40px 0;
      `}
  }
`;
