import * as React from 'react';
import {Container} from 'react-awesome-styled-grid';
import {Heading, Text} from 'styled-typography';
import {HeaderEmptySearchStyle} from './styles';

const HeaderEmptySearch = () => {
  return (
    <HeaderEmptySearchStyle>
      <Container>
        <Text level="6" as="h1">
          Search results for
        </Text>
        <Heading level="2" as="h2">
          “Product name”
        </Heading>
        <Text level="6" as="h3">
          <b>Sorry, we did not find any results for your search.</b> please
          review it and try again.
        </Text>
      </Container>
    </HeaderEmptySearchStyle>
  );
};

export default HeaderEmptySearch;
