import * as React from 'react';
import {Heading, Text} from 'styled-typography';
import {HeaderSearchStyle} from './styles';
import {useQueryParam, StringParam} from 'use-query-params';
import {useGlobalContext} from '../../context';
const HeaderSearch = () => {
  const [searchQuery] = useQueryParam('search', StringParam);
  const searchParam = searchQuery ? searchQuery : <></>;
  const {countResult} = useGlobalContext();

  return (
    <HeaderSearchStyle>
      <Text level="6" as="h1">
        Search results for
      </Text>
      <Heading level="2" as="h2">
        &quot;{searchParam.charAt(0).toUpperCase() + searchParam.slice(1)}
        &quot;
      </Heading>
      <Text level="6" as="h3">
        We have found <b>{countResult} results</b> that may interest you:
      </Text>
    </HeaderSearchStyle>
  );
};

export default HeaderSearch;
