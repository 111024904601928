import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const LoadingStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 570px);
  margin: 0 auto;
  max-width: 40%;
  ${props => config(props).media['md']`
  `}

  img {
    width: 120px;
    margin-bottom: 20px;
  }

  p {
    height: 20px;
  }

  #loadingText {
    animation: loadingAnimation infinite;
    overflow: hidden;
    border-right: 0.1em solid ${({theme}) => theme.colors.secondary2};
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.15em;
    animation-duration: 2s;
    animation-timing-function: steps(14, end);
  }

  #rotatingImage {
    animation: rotateAnimation infinite;
    animation-duration: 5s; /* Duración de la animación en segundos */
    animation-timing-function: linear; /* Función de tiempo lineal */
  }

  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
