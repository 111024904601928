import React from 'react';
import {Heading} from 'styled-typography';
import Skeleton from 'react-loading-skeleton';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import {CartPageStyle} from '../CartPage/styles';

const SkeletonFavorite = () => {
  return (
    <Container>
      <CartPageStyle>
        <Row>
          <Heading as="h2" level="6">
            Maker’s | <span>Favorite</span>
          </Heading>
        </Row>
        <Row>
          <Heading as="h1" level="3">
            Your favorites
          </Heading>
        </Row>
        <Row align="start">
          <Col xs={4} md={5.5}>
            <Skeleton className="skeletonStyle" circle={false} count={1} />
          </Col>
        </Row>
      </CartPageStyle>
    </Container>
  );
};

export default SkeletonFavorite;
