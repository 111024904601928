import React from 'react';
import PropTypes from 'prop-types';

import DropdownButton from './DropdownButton';
import DropdownItem from './DropdownItem';

const DropdowOption = ({...props}) => {
  return props.option.type === 'button' ? (
    <DropdownButton {...props} />
  ) : (
    <DropdownItem {...props} />
  );
};

DropdowOption.propTypes = {
  option: PropTypes.array,
  onClick: PropTypes.func,
};

export default DropdowOption;
