import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-awesome-styled-grid';
import {useAlert} from 'react-alert';

import {ConfirmStyle} from './styles';
import CartProduct from './CartProduct';
import InputForm from '../core/InputForm';
import {useGlobalContext} from '../../context';
import OrderSummary from '../Cart/OrderSummary';
import ButtonProduct from '../core/ButtonProduct';
import {CardCart} from '../pages/CartPage/styles';
import PersonalInformation from './PersonalInformation';
import {getCurrentUser} from '../../services/authentication';
import {clearCurrentOrder, getOrder} from '../../services/temporalOrder';
import {nextStepOrder, orderComplete, orderNote} from '../../API/makers/orders';

const Confirm = ({setStateLocal, setLocalCurrentOrder, setNumberState}) => {
  const alert = useAlert();
  const {currentOrder, fetchCurrentOrder, disableBtn, setDisableBtn} =
    useGlobalContext();
  const {
    number,
    item_total,
    adjustment_total,
    total,
    bill_address,
    ship_address,
  } = currentOrder;
  const {line_items} = currentOrder || '';

  const allBackorderable = line_items.some(
    item => item.variant.is_backorderable === false
  );
  const allInStock = line_items.some(item => item.variant.total_on_hand === 0);

  const [textShipment, setTextShipment] = useState('');

  const handleComplete = async () => {
    setDisableBtn(true);
    setLocalCurrentOrder(currentOrder);
    if (textShipment === '') {
      await nextStepOrder({
        user: getCurrentUser()['Access-Token'],
        orderNumber: getOrder(),
      })
        .then(async () => {
          await orderComplete(
            {
              user: getCurrentUser()['Access-Token'],
              orderNumber: getOrder(),
            },
            () => {
              clearCurrentOrder();
            }
          );
          setStateLocal('complete');
        })
        .catch(error => alert.error(error));
    } else {
      await orderNote({
        user: getCurrentUser()['Access-Token'],
        order_note: textShipment,
        orderNumber: getOrder(),
      })
        .then(async () => {
          await orderComplete(
            {
              user: getCurrentUser()['Access-Token'],
              orderNumber: getOrder(),
            },
            () => {
              clearCurrentOrder();
            }
          );
          setStateLocal('complete');
        })
        .catch(error => alert.error(error));
    }
  };

  const [blocked, setBlocked] = useState(false);

  const hanbleErrorConfirm = () => {
    setBlocked(true), alert.error('one product is out for stock ');
  };

  useEffect(() => {
    setDisableBtn(false);
    fetchCurrentOrder();
    setNumberState(2);
  }, []);

  return (
    <Container>
      <ConfirmStyle>
        <Heading level="4" as="h1" justify="start" align="start">
          CONFIRM ORDER # {number}
        </Heading>
        <div className="container-confirm">
          <Row justify="center">
            <Col xs={4} md={8} align="center">
              <div className="wrapper-information">
                <PersonalInformation
                  title="Billing Address"
                  name={bill_address.name}
                  address={bill_address.address1}
                  city={bill_address.city}
                  country={`${bill_address.country.iso3} - ${bill_address.country.name}`}
                  phone={bill_address.phone}
                />
                {ship_address && (
                  <>
                    <PersonalInformation
                      title="Shipping Address"
                      name={ship_address.name}
                      address={ship_address.address1}
                      city={ship_address.state.name}
                      country={`${ship_address.country.iso3} - ${ship_address.country.name}`}
                      phone={ship_address.phone}
                    />
                  </>
                )}
                <PersonalInformation
                  title="Payment Information"
                  name={bill_address.name}
                  address={bill_address.address1}
                />
              </div>
            </Col>
            <Col xs={3.8} md={7}>
              <CartProduct />
            </Col>
            <Col
              xs={3.8}
              md={6}
              xl={11}
              align={{xs: 'center', md: 'end', xl: 'end'}}
            >
              <CardCart
                width="55%"
                height="200px"
                justify="center"
                align="flex-end"
              >
                <OrderSummary
                  isCheckout
                  description={
                    <>
                      <div className="text-summary">
                        <Text level="6" as="h6">
                          Item Total:
                        </Text>
                        <Text level="6" as="h6">
                          Discount:
                        </Text>
                        <Text level="6" as="h6">
                          Shipping-UPS Ground(USD):
                        </Text>
                      </div>
                      <div className="calculate-summary">
                        <Text level="6" as="h4">
                          {`$ ${item_total}`}
                        </Text>
                        <Text level="6" as="h4">
                          {`$ ${adjustment_total || '0.0'}`}
                        </Text>
                        <Text level="6" as="h4">
                          to Calculate
                        </Text>
                      </div>
                    </>
                  }
                  total={`$ ${total}`}
                />
              </CardCart>
            </Col>
            <Col className="text-area">
              <label>Shipment Info:</label>
              <InputForm
                onChange={e => setTextShipment(e.target.value)}
                placeholder=""
                type="text"
                value={textShipment}
              />
              <Text level="6">
                Shipping methods available are UPS & USPS. <br /> If you have
                your own account please provide us with Account# and Billing Zip
                Code. If there is no shipping preference we will ship UPS. *Your
                final charge will have shipping included once we calculate it*
                If you prefer to speak with a representative you can call us at
                <span> (305) 576-1195.</span>
              </Text>
            </Col>
          </Row>
        </div>
        {allBackorderable && allInStock ? (
          <ButtonProduct
            width="200px"
            height="40px"
            onClick={hanbleErrorConfirm}
            disabled={blocked}
          >
            PLACE ORDER
          </ButtonProduct>
        ) : (
          <ButtonProduct
            width="200px"
            height="40px"
            onClick={handleComplete}
            disabled={disableBtn}
          >
            PLACE ORDER
          </ButtonProduct>
        )}
      </ConfirmStyle>
    </Container>
  );
};

export default Confirm;

Confirm.defaultProps = {
  item_total: 0,
  ship_total: 0,
  total: 0,
};

Confirm.propTypes = {
  setComplete: PropTypes.bool,
  setStateLocal: PropTypes.bool,
  setLocalCurrentOrder: PropTypes.func,
  setNumberState: PropTypes.number,
};
