import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const CartStyle = styled.div`
  display: flex;
  width: 95%;
  background-color: white;
  height: 176px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 33px;
  margin-left: 3%;
  ${props => config(props).media['md']`
    margin-left: 0%;
    width: 100%;
    justify-content: center;
    align-items: center;
  `}
  h2 {
    padding-right: 20px;
  }
  h3 {
    padding-right: 20px;
  }
  a {
    color: ${({theme}) => theme.colors.secondary};
    padding-right: 20px;
    :hover {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
  h4 {
    color: ${({theme}) => theme.colors.secondary};
    text-transform: uppercase;
  }
  > div {
    justify-content: center;
    margin-left: 2px;
    > div {
      > div {
        margin-left: 1px;
        > div {
          margin-left: 1px;
        }
      }
    }
  }
  .icon-cart {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${props => config(props).media['md']`
      flex-direction: row;
  `}
  }
`;
export const CardImage = styled.div`
  width: 115px;
  height: 136px;
  border: 1px solid ${({theme}) => theme.colors.secondary2};
  border-radius: 15px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    /* max-height: 100px;
    max-width: 100px; */
    width: 100%;
    ${props => config(props).media['md']`
      max-width: 115px;
    `}
  }
`;
export const ButtonLove = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.secondary};
  margin-right: 20px;
  margin-top: 10px;
  ${props => config(props).media['md']`
  margin-top: 40px;
  margin-right: 18px;
  `}
  :hover {
    cursor: pointer;
    border: 1px solid ${({theme}) => theme.colors.primary};
  }
  :active {
    border: 1px solid ${({theme}) => theme.colors.primary2};
  }
`;
export const ButtonTrash = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.secondary};
  margin-right: 20px;
  margin-top: 10px;
  ${props => config(props).media['md']`
    margin-top: 40px;
    margin-right: 18px;
  `}
  :hover {
    cursor: pointer;
    border: 1px solid #ff4768;
  }
  :active {
    border: 1px solid #d33c56;
  }
`;
export const OrderSummaryStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin-bottom: 15px;
    text-align: end;
    margin-right: 0px !important;
  }
  h5 {
    margin-left: 0px !important;
  }
  .text-summary {
    flex-direction: column;
    margin-left: 0px;
    ${props => config(props).media['md']`
      margin-left: 0px;
    `}
  }
  .calculate-summary {
    flex-direction: column;
    margin-right: 0px;
    ${props => config(props).media['md']`
      margin-right: 0px;
    `}
  }
  h6 {
    font-weight: 600;
    margin-bottom: 15px;
    margin-left: 0px !important;
  }
`;
export const OrderSummaryCard = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  h1 {
    font-size: ${({theme}) => theme.typography.fontSizes[3]};
    margin-bottom: 20px;
  }
  .containter-total {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${({theme}) => theme.colors.secondary2};
    margin-bottom: 0px;
    h4 {
      margin-top: 15px;
      margin-right: 0px;
      font-weight: 600;
    }
    h6 {
      font-weight: 600;
      margin-left: 0px !important;
      margin-top: 15px;
    }
  }
  .containter-button {
    align-items: center;
    justify-content: center;
  }
  button {
    margin-top: 20px;
  }
`;
