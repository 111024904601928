import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {CardCart} from '../pages/CartPage/styles';
import {OrdersStyleSkeleton} from './styles';

const SkeletonLoaderOrders = () => {
  return (
    <OrdersStyleSkeleton>
      <CardCart height="100%" width="95%">
        <div className="container">
          <div className="container-order">
            <Skeleton className="skeletonStyle" width={100} height={30} />
            <table>
              <tbody>
                <tr>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <Skeleton className="skeletonStyle" width={100} height={30} />
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <Skeleton className="skeletonStyle" width={100} height={30} />
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>

                  <td>
                    <Skeleton
                      className="skeletonStyle"
                      width={100}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </CardCart>
    </OrdersStyleSkeleton>
  );
};

export default SkeletonLoaderOrders;
