import * as React from 'react';
import {AppProvider} from '../../../context';
import HeaderSearch from '../../HeaderSearch';
import ResultProducts from '../../ResultProducts';

const ProductsPage = () => {
  return (
    <AppProvider>
      <HeaderSearch />
      <ResultProducts />
    </AppProvider>
  );
};

export default ProductsPage;
