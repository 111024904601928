import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const YourFavoritePageStyle = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  ${props => config(props).media['sm']`
    width: 100%;
  `}
  h2 {
    padding: 0px;
    margin: 0px;
    padding-bottom: 30px;
    color: ${({theme}) => theme.colors.secondary2};
    margin-left: 40px;
    ${props => config(props).media['sm']`
      margin-left: 0px;
    `}
  }
  h1 {
    padding-left: 40px;
    padding-bottom: 20px;
  }
  h5 {
    padding-top: 55px;
    color: ${({theme}) => theme.colors.secondary};
  }
  h6 {
    color: ${({theme}) => theme.colors.secondary};
  }
  span {
    color: black;
  }
`;
