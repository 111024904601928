import React from 'react';
import {Col, Container, Row} from 'react-awesome-styled-grid';
import {Text} from 'styled-typography';
import {useQueryParam, StringParam} from 'use-query-params';
import {HeaderRegisterStyle, PaginationRegister} from './styles';
const HeaderRegister = () => {
  const [PageQueryRegister, setPageQueryRegister] = useQueryParam(
    'step',
    StringParam
  );

  const handlePageQuery = page => {
    setPageQueryRegister(page);
  };
  return (
    <HeaderRegisterStyle>
      <Container>
        <Row justify="center">
          <Col align="center" md={8}>
            <Text level="3" as="h1">
              Create a Maker’s Account
            </Text>
          </Col>
          <Col align="center" md={8}>
            <Text level="5" as="h3">
              To create a new account and enjoy Maker&apos;s products, fill out
              the following form:
            </Text>
          </Col>
          <Row justify="center">
            <PaginationRegister
              active={PageQueryRegister == 1}
              onClick={() => handlePageQuery(1)}
            >
              1
            </PaginationRegister>
            <PaginationRegister
              active={PageQueryRegister == 2}
              disabled={PageQueryRegister !== 2}
              onClick={() => handlePageQuery(2)}
            >
              2
            </PaginationRegister>
          </Row>
        </Row>
      </Container>
    </HeaderRegisterStyle>
  );
};

export default HeaderRegister;
