import React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';

import {InfoFormStyle} from './styles';
import InputForm from '../core/InputForm';
import {fetchCountries, fetchStates} from '../../API/makers/countries';

const FormAddress = ({infoAddress, setInfoAddress, typeForm}) => {
  const {
    bill_address_attributes: {
      name,
      address1,
      city,
      country_id,
      state_id,
      zipcode,
      phone,
      company,
    },
    ship_address_attributes: {
      name_ship,
      address1_ship,
      city_ship,
      country_id_ship,
      state_id_ship,
      zipcode_ship,
      phone_ship,
      company_ship,
    },
  } = infoAddress;
  const {countries} = fetchCountries();
  const statesBill = fetchStates(country_id);
  const statesShip = fetchStates(country_id_ship);

  const handleInputObjectChange = (objectKey, key, defaultValue = 0) => {
    return event => {
      setInfoAddress({
        ...infoAddress,
        [objectKey]: {
          ...infoAddress[objectKey],
          [key]: defaultValue || event.target.value,
        },
      });
    };
  };

  return (
    <>
      {typeForm === 'bill' && (
        <InfoFormStyle>
          <Text level="6" fontWeight={500}>
            Name:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'name'
            )}
            placeholder="Name"
            type="text"
            value={name}
          />
          <Text level="6" fontWeight={500}>
            Street Address:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'address1'
            )}
            placeholder="address"
            type="text"
            value={address1}
          />
          <Text level="6" fontWeight={500}>
            City:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'city'
            )}
            placeholder="city"
            type="text"
            value={city}
          />
          <Text level="6" fontWeight={500}>
            Country:
          </Text>
          <select
            name="country"
            id="country"
            className="input-select"
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'country_id'
            )}
            value={country_id}
          >
            {countries.map(countrie => (
              <option key={countrie.id} value={countrie.id}>
                {countrie.name}
              </option>
            ))}
          </select>
          <Text level="6" fontWeight={500}>
            State:
          </Text>
          <select
            name="state"
            id="state"
            className="input-select"
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'state_id'
            )}
            value={state_id}
          >
            <option>Select a State/Province</option>
            {statesBill?.states.map(state => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
          <Text level="6" fontWeight={500}>
            Zip:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'zipcode'
            )}
            placeholder="zipcode"
            type="text"
            value={zipcode}
          />
          <Text level="6" fontWeight={500}>
            Phone:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'phone'
            )}
            placeholder="phone"
            type="text"
            value={phone}
          />
          <Text level="6" fontWeight={500}>
            Company:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'bill_address_attributes',
              'company'
            )}
            placeholder="company"
            type="text"
            value={company}
          />
        </InfoFormStyle>
      )}
      {typeForm === 'ship' && (
        <InfoFormStyle>
          <Text level="6" fontWeight={500}>
            Name:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'name_ship'
            )}
            placeholder="Name"
            type="text"
            value={name_ship}
          />
          <Text level="6" fontWeight={500}>
            Street Address:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'address1_ship'
            )}
            placeholder="address"
            type="text"
            value={address1_ship}
          />
          <Text level="6" fontWeight={500}>
            City:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'city_ship'
            )}
            placeholder="city"
            type="text"
            value={city_ship}
          />
          <Text level="6" fontWeight={500}>
            Country:
          </Text>
          <select
            name="country"
            id="country"
            className="input-select"
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'country_id_ship'
            )}
            value={country_id_ship}
          >
            {countries.map(countrie => (
              <option key={countrie.id} value={countrie.id}>
                {countrie.name}
              </option>
            ))}
          </select>
          <Text level="6" fontWeight={500}>
            State:
          </Text>
          <select
            name="state"
            id="state"
            className="input-select"
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'state_id_ship'
            )}
            value={state_id_ship}
          >
            <option>Select a State/Province</option>
            {statesShip?.states.map(state => (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            ))}
          </select>
          <Text level="6" fontWeight={500}>
            Zip:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'zipcode_ship'
            )}
            placeholder="zipcode"
            type="text"
            value={zipcode_ship}
          />
          <Text level="6" fontWeight={500}>
            Phone:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'phone_ship'
            )}
            placeholder="phone"
            type="text"
            value={phone_ship}
          />
          <Text level="6" fontWeight={500}>
            Company:
          </Text>
          <InputForm
            onChange={handleInputObjectChange(
              'ship_address_attributes',
              'company_ship'
            )}
            placeholder="company"
            type="text"
            value={company_ship}
          />
        </InfoFormStyle>
      )}
    </>
  );
};

export default FormAddress;

FormAddress.propTypes = {
  infoAddress: PropTypes.object,
  setInfoAddress: PropTypes.func,
  typeForm: PropTypes.string,
};
