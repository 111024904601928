import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import {StylePayments} from './styles';
import OrderSummary from '../Cart/OrderSummary';
import {CardCart} from '../pages/CartPage/styles';

const SkeletonPayment = () => {
  return (
    <StylePayments>
      <Container>
        <Row>
          <Col md={4} xs={4} align={{xs: 'center', md: 'center'}}>
            <div className="container-payments">
              <Heading level="5" as="h3">
                PAYMENT INFORMATION
              </Heading>
              <Skeleton className="skeletonStyle" circle={false} count={1} />
            </div>
          </Col>
          <Col md={3} xs={4} align="center">
            <div className="container-order">
              <CardCart height="220px">
                <OrderSummary
                  isCheckout
                  description={
                    <>
                      <div className="text-summary">
                        <Text level="5" as="h6">
                          Item Total:
                        </Text>
                        <Text level="5" as="h6">
                          North America 5.000%:
                        </Text>
                        <Text level="5" as="h6">
                          Shipping total:
                        </Text>
                      </div>
                      <div className="calculate-summary">
                        <Text level="5" as="h4">
                          $ 0
                        </Text>
                        <Text level="5" as="h4">
                          $ 0.0
                        </Text>
                        <Text level="5" as="h4">
                          To calculate
                        </Text>
                      </div>
                    </>
                  }
                  total="$ 0"
                />
              </CardCart>
            </div>
          </Col>
        </Row>
      </Container>
    </StylePayments>
  );
};

export default SkeletonPayment;
