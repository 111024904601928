import styled from 'styled-components';

export const StyledButtonSpecial = styled.button`
  width: 180px;
  height: 44px;
  background-color: transparent;
  margin-right: 10px;
  margin-bottom: 10px;
  // border-radius: 10px;
  // border: 1px solid ${({theme}) => theme.colors.secondary};
  cursor: pointer;

  p {
    color: ${({theme}) => theme.colors.primary};
    font-weight: 600;
    font-size: 13px;
  }

  &:hover {
    border: 2px solid white;
  }

  &:active {
    border: 1px solid ${({theme}) => theme.colors.secondary};
  }
`;
