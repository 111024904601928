import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import React, {useEffect} from 'react';
import {useQueryParam, StringParam} from 'use-query-params';
import {Col, Row} from 'react-awesome-styled-grid';

import Card from '../core/Card';
import Pagination from '../Pagination';
import oneWeek from '../../utils/oneWeek';
import {MoreProductsStyle} from './styles';
import todayFormat from '../../utils/today';
import MightAlsoLike from '../MightAlsoLike';
import {useGlobalContext} from '../../context';
import NoResultCategory from '../NoResultCategory';
import {fetchTaxons} from '../../API/makers/taxons';
import useImgixImage from '../../hooks/useImgixImage';
import SkeletonResultProducts from './SkeletonResultProducts';
import DropdownMultiSelect from '../core/DropdownMultiselect';
import {fetchOptionTypes} from '../../API/makers/optionTypes';
import {fetchProductsWithFilter} from '../../API/makers/products';
import Skeleton from 'react-loading-skeleton';

const MoreProducts = ({isWeekly}) => {
  const deleteIcon = useImgixImage('/images/icon/delete-icon.png');
  const {taxons, isLoading, error} = fetchTaxons();
  const {optionTypes} = fetchOptionTypes('size');
  const sizes = optionTypes[0]?.option_values;
  const {
    filterStyle,
    queryFilter,
    setQueryFilter,
    filter,
    setFilter,
    getStyleOption,
  } = useGlobalContext();
  useEffect(() => {
    setFilter({
      ...filter,
      style: queryFilter?.style?.map(getStyleOption),
    });
  }, [queryFilter.style]);
  const {setCountResult} = useGlobalContext();
  const pageParam = `per_page=${queryFilter.per_page}&page=${queryFilter.page}`;
  const [searchQuery] = useQueryParam('search', StringParam);
  const searchParam = searchQuery
    ? `q[taxons_name_or_description_or_variants_option_values_presentation_or_meta_keywords_or_name_start]=${searchQuery}`
    : '';
  const listStyle = filter?.style?.map(
    style => `q[taxons_id_in][]=${style.value}`
  );
  const wrapStyle = listStyle?.join('&');
  const listSize = filter?.size?.map(
    size =>
      `q[variants_including_master_option_values_presentation_in][]=${size.value}`
  );
  const wrapSize = listSize?.join('&');
  const prices = filter?.price?.map(price => price.value.split('-'));
  const listPrices = [];
  const wrapPrices = () => {
    prices?.forEach(items => {
      items.forEach(item => listPrices.push(parseInt(item)));
    });
  };
  wrapPrices();
  const min = prices !== [] ? Math.min(...listPrices) : 0;
  const max = prices !== [] ? Math.max(...listPrices) : 99999;
  const wrapPriceOne =
    max > 400
      ? `q[variants_including_master_prices_amount_gteq]=${min}&q[variants_including_master_prices_amount_lteq]=${100000}`
      : `q[variants_including_master_prices_amount_gteq]=${min}&q[variants_including_master_prices_amount_lteq]=${max}`;
  const wrapPrice =
    min === Infinity && max === -Infinity
      ? `q[variants_including_master_prices_amount_gteq]=${1}&q[variants_including_master_prices_amount_lteq]=${10000}`
      : wrapPriceOne;
  const orderProducts = filter?.order_by?.map(item => item.value);
  const orderProduct = orderProducts ? `order_by=${orderProducts}` : '';
  const wrapSearch = wrapStyle?.concat(
    `&${wrapSize}`.concat(
      `&${wrapPrice}`.concat(
        `&${searchParam}`.concat(`&${pageParam}`.concat(`&${orderProduct}`))
      )
    )
  );

  const weeklyProducts = `q[created_at_gteq]=${oneWeek}&q[created_at_lteq]=${todayFormat}`;
  const {products, pages, perPage, currentPage, count} = !isWeekly
    ? fetchProductsWithFilter(`${wrapSearch}`)
    : fetchProductsWithFilter(`${weeklyProducts}`);
  setCountResult(count);

  const handleFilterChange = (value, selected) => {
    const labelMapping = {
      'Sort By': 'order_by',
    };

    value = labelMapping[value] || value;
    value === 'order_by'
      ? setQueryFilter(
          (queryFilter.order_by = [
            {
              label: `${selected.label}`,
              value: `${selected.value}`,
            },
          ])
        )
      : setQueryFilter({
          ...queryFilter,
          [value]: selected.map(({value, label}) => `${value},${label}`),
        });

    setFilter({
      ...filter,
      [value]: selected,
    });
  };

  const handleDeleteItem = (key, index) => () => {
    const selectedFilters = [...filter[key]];
    const newFilter = selectedFilters.splice(index, 1);
    setQueryFilter({
      ...queryFilter,
      [key]: newFilter.map(({key}) => key),
    });
    setFilter({
      ...filter,
      [key]: selectedFilters,
    });
  };

  useEffect(() => {
    if (filterStyle) {
      const style =
        filterStyle === 'ALL'
          ? []
          : [
              {
                label: filterStyle.name,
                value: filterStyle.id,
              },
            ];
      setFilter({
        ...filter,
        style,
      });
    }
  }, [filterStyle]);

  useEffect(() => {
    if (filter.length > 1) {
      setFilter([filter[filter.length - 1]]);
    }
  }, [filter, queryFilter]);

  const isActive = products.filter(item => item.active === true);

  if (isLoading) {
    return <SkeletonResultProducts />;
  }

  return (
    <MoreProductsStyle>
      <div className="ContainerFilter">
        <div className="wrap__filter-button">
          <div className="wrap_style-price">
            <DropdownMultiSelect
              selected={filter['style']}
              label="style"
              width="95px"
              onChange={handleFilterChange}
              options={taxons?.map(taxon => ({
                label: taxon.name,
                value: taxon.id,
              }))}
            />
            <DropdownMultiSelect
              selected={filter['size']}
              label="size"
              width="85px"
              onChange={handleFilterChange}
              options={sizes?.map(size => ({
                label: size.name,
                value: size.presentation,
              }))}
            />
          </div>
          <div className="wrap_size-sortby">
            <DropdownMultiSelect
              selected={filter['price']}
              label="price"
              width="90px"
              onChange={handleFilterChange}
              options={[
                {label: '$1 to $100', value: '1-100'},
                {label: '$101 to $200', value: '101-200'},
                {label: '$201 to $300', value: '201-300'},
                {label: '$301 to $400', value: '301-400'},
                {label: 'More than $400', value: '401'},
              ]}
              rightSide
            />
            <DropdownMultiSelect
              selected={filter.order_by}
              label="Sort By"
              width="111px"
              onChange={handleFilterChange}
              hasSelectAll={false}
              options={[
                {label: 'Most Recent Desc', value: 'created_at_desc'},
                {label: 'Most Recent Asc', value: 'created_at_asc'},
                // {label: 'Best Seller', value: 'BestSeller'},
                // {label: 'Most Popular', value: 'MostPopular'},
              ]}
              rightSide
            />
          </div>
        </div>
      </div>
      <div className="FilterSelected">
        {filter?.style?.value !== 'All Shoes' &&
          Object.entries(filter).map(([filterType, selected]) =>
            selected?.map((select, index) => (
              <div className="ContainerSelected" key={select.label}>
                <div className="DeleteIcon">
                  <img
                    src={deleteIcon}
                    onClick={handleDeleteItem(filterType, index)}
                  ></img>
                </div>
                <Text level="6">{select.label}</Text>
              </div>
            ))
          )}
      </div>
      <Row justify="center">
        {!isLoading && error ? (
          <>
            <NoResultCategory />
            <MightAlsoLike />
          </>
        ) : (
          <>
            {isActive.length > 0 ? (
              isActive.map(product => (
                <Col
                  xs={1.8}
                  sm={3.5}
                  md={2}
                  lg={3}
                  xl={2.2}
                  align="center"
                  key={product.id}
                >
                  <Card
                    productId={product.id}
                    img={
                      product.master.images[0] &&
                      product.master.images[0].product_url
                    }
                    productName={product.name}
                    product={product}
                    Price={product.master.display_price}
                    // buttonText="New Collection"
                  ></Card>
                </Col>
              ))
            ) : (
              <Skeleton className="skeletonStyle-loadingCard" />
            )}
            {isActive.length > 0 && pages > 1 && (
              <Pagination
                pages={pages}
                perPage={perPage}
                currentPage={currentPage}
              />
            )}
          </>
        )}
      </Row>
    </MoreProductsStyle>
  );
};

export default MoreProducts;

MoreProducts.propTypes = {
  isWeekly: PropTypes.bool,
};
