import * as React from 'react';

import useImgixImage from '../../hooks/useImgixImage';
import {Container, Row, Col, Hidden} from 'react-awesome-styled-grid';
import {Text} from 'styled-typography';
import {InfoWeAreMakersStyle} from './styles';
import ButtonProduct from '../core/ButtonProduct';

const InfoWeAreMakers = () => {
  const weAreMakers = useImgixImage('/images/banner-weare.png', {
    w: 682,
    h: 454,
  });
  const weAreMakersXS = useImgixImage('/images/banner-weare.png', {
    w: 282,
    h: 154,
  });
  const weAreMakersSM = useImgixImage('/images/banner-weare.png', {
    w: 302,
    h: 254,
  });

  return (
    <Container>
      <InfoWeAreMakersStyle>
        <Row justify="center">
          <Col align="center" xs={2} md={4}>
            <div className="Text">
              <Text as="h1">Our Values</Text>
              <Text level="5" as="h3">
                <li>Result Oriented </li>
                <li>Hunger to Grow</li>
                <li> We handle complexity as a team </li>
                <li> Believers</li>
              </Text>
              <Text as="h1">Production Speed.</Text>
              <Text level="5" as="h2">
                The pandemic pushed our boundaries to perform in a more agile
                way to maintain business and deliver products to our clients.
                Today we are proud to say that we have a turnaround time of 2-3
                weeks! In an uncertain time where prices are going up and
                there’s delays in transportation, we are able to still offer our
                customers the latest trends at a record delivery time.
              </Text>
              {!process.env['GATSBY_ORDER_ACTIVATION'] === false ? (
                ''
              ) : (
                <ButtonProduct>SHOP NOW</ButtonProduct>
              )}
            </div>
          </Col>
          <Col align="center" xs={2} md={4}>
            <Hidden xs sm>
              <div className="ContainerImage">
                <img src={weAreMakers}></img>
              </div>
            </Hidden>
            <Hidden sm md lg xl>
              <div className="ContainerImage">
                <img src={weAreMakersXS}></img>
              </div>
            </Hidden>
            <Hidden xs md lg xl>
              <div className="ContainerImage">
                <img src={weAreMakersSM}></img>
              </div>
            </Hidden>
          </Col>
        </Row>
      </InfoWeAreMakersStyle>
    </Container>
  );
};

export default InfoWeAreMakers;
