import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const CheckoutPageStyle = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  h1 {
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 30px;
  }
  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    ${props => config(props).media['md']`
      margin-left: 20px;
    `}
    :hover {
      font-weight: 800;
    }
  }
  h4 {
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 15px;
    ${props => config(props).media['md']`
      margin-left: 40px;
      margin-right: 0px;
  `}
  }
  .container-form {
    margin-top: 60px;
    width: 85%;
    ${props => config(props).media['md']`
      width: 100%;
    `}
    padding-left: 40px;
  }
  .input-select {
    display: flex;
    height: 44px;
    width: 100%;
    background-color: white;
    border-radius: 6px;
    outline: none;
    padding: 0 20px;
    font-size: 0.8em;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.secondary2};
    border-width: 1.5px;
    margin-top: 10px;
    margin-bottom: 28px;
    :focus {
      outline: none;
      border: solid 1px ${({theme}) => theme.colors.primary2};
    }
  }
  input {
    margin-right: 5px;
  }
  label {
    font-size: 14px;
    margin-left: 10px;
  }
  .wrapper-check {
    margin-left: 0px;
    ${props => config(props).media['md']`
      margin-left: 20px;
    `}
  }
  button {
    margin-top: 30px;
    margin-bottom: 60px;
  }
  .head-menu-checkout {
    justify-content: flex-start;
    ${props => config(props).media['md']`
      justify-content: space-around;
    `}
  }
  .menu-checkout {
    flex-direction: row;
    margin-left: 20px;
    align-items: center;
    ${props => config(props).media['md']`
      margin-left: 0px;
    `}
  }
  a {
    align-items: center;
    margin-bottom: 0px;
    cursor: pointer;
    :hover {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
`;
