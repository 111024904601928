import {Heading} from 'styled-typography';
import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import {AccountStyle} from './styles';
import Orders from '../../UserData/Orders';
import MenuAccount from '../../MenuAccount';
import Addresses from '../../UserData/Addresses';
import PersonalInfo from '../../UserData/PersonalInfo';
import {getListOrders} from '../../../API/makers/orders';
import {useQueryParam, StringParam} from 'use-query-params';
import EmptyOrders from '../../EmptyAccount/EmptyOrders';
import EmptyAddress from '../../EmptyAccount/EmptyAddress';
import {getCurrentUser} from '../../../services/authentication';
import SkeletonLoaderOrders from '../../UserData/SkeletonLoaderOrders';
import {getAddress} from '../../../API/makers/users';

const AccountPage = () => {
  const [PageQueryAccount] = useQueryParam('setting', StringParam);
  const [listOrders, setListOrders] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const {orders} = listOrders && listOrders;
  const [listAddress, setListAddress] = useState('');

  const handleGetListOrders = () => {
    getListOrders(getCurrentUser()['Access-Token'], listOrders => {
      listOrders && setListOrders(listOrders);
    }).catch(error => setError(error));
  };

  const handleGetListAddress = id => {
    getAddress(
      {user: getCurrentUser()['Access-Token'], idUser: id},
      getAddress => getAddress && setListAddress(getAddress)
    ).catch(error => setError(error));
  };

  useEffect(() => {
    handleGetListOrders();
  }, []);

  useEffect(() => {
    orders && handleGetListAddress(getCurrentUser().id);
    orders && setIsLoading(false);
    error && setIsLoading(false);
  }, [orders, error]);

  return (
    <Container>
      <AccountStyle>
        <Row className="links-routes">
          <Heading as="h4" level="6">
            Maker’s | &nbsp;
          </Heading>
          <Heading as="h5" level="6">
            Account
          </Heading>
        </Row>
        <Row className="wrap_menu_acount">
          <Col md={2}>
            <MenuAccount />
          </Col>
          <Col align="center" xs={4} md={6} lg={8} xl={9}>
            {PageQueryAccount == 'personal-info' ? <PersonalInfo /> : ''}
            {PageQueryAccount === 'orders' ? (
              isLoading ? (
                <SkeletonLoaderOrders />
              ) : orders ? (
                <Orders orders={orders} />
              ) : (
                <EmptyOrders title={'Order'} />
              )
            ) : (
              ''
            )}
            {PageQueryAccount === 'addresses' ? (
              isLoading ? (
                <SkeletonLoaderOrders />
              ) : orders ? (
                <Addresses listAddress={listAddress} />
              ) : (
                <EmptyAddress />
              )
            ) : (
              ''
            )}
          </Col>
        </Row>
      </AccountStyle>
    </Container>
  );
};

export default AccountPage;
