import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {FontWeight, Link, Text} from 'styled-typography';

import {ButtonAddres} from './styles';
import InputForm from '../core/InputForm';
import {ModalStyle} from '../Header/styles';
import ButtonBlack from '../core/ButtonBlack';
import useImgixImage from '../../hooks/useImgixImage';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: 'auto',
    height: '92vh',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '18px',
  },
};

const Address = ({name, address, location}) => {
  const edit = useImgixImage('/images/edit-btn.png');
  const trash = useImgixImage('/images/trash.png');

  const [modalIsOpen, setModalIsOpen] = useState(false);
  let subtitle;
  const openModal = () => {
    setModalIsOpen(true);
  };

  const afterOpenModal = () => {
    subtitle.style.color = '#000';
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <td>
        <div className="container-info">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 600)}>
            {name}
          </Text>
          <Link>Select Favorite</Link>
        </div>
      </td>
      <td>
        <div className="container-info">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 450)}>
            {address}
          </Text>
        </div>
      </td>
      <td>
        <div className="container-info">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 450)}>
            {location}
          </Text>
        </div>
      </td>
      {!process.env['GATSBY_ORDER_ACTIVATION'] === true ? (
        ''
      ) : (
        <td>
          <div className="wrapper-button">
            <div>
              <ButtonAddres onClick={openModal}>
                <img src={edit} />
              </ButtonAddres>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              contentLabel="Example Modal"
              style={customStyles}
            >
              <ModalStyle>
                <h2 ref={_subtitle => (subtitle = _subtitle)}>
                  Add new address
                </h2>

                <Text level="5" as="h3">
                  Enter the following information to add your new address
                </Text>
                <form>
                  <Text level="6" as="h2">
                    Address name*
                  </Text>
                  <InputForm />
                  <Text level="6" as="h2">
                    Address line*
                  </Text>
                  <InputForm />
                  <Text level="6">Country</Text>
                  <select name="country" id="country" className="input-select">
                    <option value="usa">United States</option>
                    <option value="mexico">Mexico</option>
                    <option value="argetina">Argentina</option>
                    <option value="puertorico">Puerto Rico</option>
                  </select>
                </form>
                <Text level="6">State/Province</Text>
                <select name="country" id="country" className="input-select">
                  <option value="florida">Florida</option>
                  <option value="ohaio">Ohaio</option>
                  <option value="opel">Hueston</option>
                  <option value="audi">California</option>
                </select>
                <Text level="6" as="h2">
                  Suburb/City*
                </Text>
                <InputForm />
                <Text level="6" as="h2">
                  Zip/Postcode*
                </Text>
                <InputForm />
                <ButtonBlack width="100%" onClick={closeModal}>
                  ADD ADDRESS
                </ButtonBlack>
              </ModalStyle>
            </Modal>
            <div>
              <ButtonAddres>
                <img src={trash} />
              </ButtonAddres>
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default Address;
Address.propTypes = {
  name: PropTypes.string,
  address: PropTypes.string,
  location: PropTypes.string,
};
