import React from 'react';
import {Router} from '@reach/router';
import {positions, Provider as AlertProvider} from 'react-alert';

import Layout from '../components/Layout';
import Default from '../components/pages/Default';
import CartPage from '../components/pages/CartPage';
import PrivateRoute from '../components/PrivateRoute';
import AlertTemplate from 'react-alert-template-basic';
import LookBook from '../components/pages/LookbookPage';
import AccountPage from '../components/pages/AccountPage';
import AllShoesPage from '../components/pages/AllShoesPage';
import RegisterPage from '../components/pages/RegisterPage';
import CheckoutPage from '../components/pages/CheckoutPage';
import WeAreMakersPage from '../components/pages/WeAreMakersPage';
import ProductsDetailPage from '../components/pages/ProductDetails';
import YourFavoritePage from '../components/pages/YourFavoritePage';
import ProductSearchPage from '../components/pages/ProductSearchPage';
import PrivacyPolicyPage from '../components/pages/PrivacyPolicyPage';
import ResetPasswordPage from '../components/pages/ResetPasswordPage';
import LetsBePartnersPage from '../components/pages/LetsBePartnersPage';
import WeeklyArrivalsPage from '../components/pages/WeeklyArrivalsPage';
import ProductsEmptyState from '../components/pages/ProductsPageEmptyState';
import MagicLink from '../components/pages/MagicLink';

const options = {
  position: positions.BOTTOM_TOP,
  timeout: 3000,
  offset: '10px',
};
const App = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <Layout>
      <Router>
        <ProductsDetailPage path="/products/:productId" />
        <ProductSearchPage path="/search-page" />
        <ProductsEmptyState path="/empty-result" />
        <WeAreMakersPage path="/we-are-makers" />
        <AllShoesPage path="/all-shoes" />
        <MagicLink path="/magic_link" />
        <WeeklyArrivalsPage path="/weekly-arrivals" />
        <PrivateRoute path="/cart" component={CartPage} />
        <RegisterPage path="/register" />
        <PrivateRoute path="/checkout" component={CheckoutPage} />
        <AccountPage path="/account" />
        <YourFavoritePage path="/favorite-page" />
        <LetsBePartnersPage path="/lets-be-partners" />
        <PrivacyPolicyPage path="/privacy-policy" />
        <ResetPasswordPage path="/reset-password" />
        <LookBook path="/lookbook" />
        <Default path="/" />
      </Router>
    </Layout>
  </AlertProvider>
);

export default App;
