import React from 'react';
import {Container, Row} from 'react-awesome-styled-grid';
import {Heading} from 'styled-typography';

import {fetchTaxons} from '../../API/makers/taxons';
import SkeletonAllShoes from '../HeaderAllShoes/SkeletonAllShoes';
import {HeaderWeeklyStyled} from './styles';

const HeaderPrivacyPolicy = () => {
  const {isLoading} = fetchTaxons();

  if (isLoading) {
    return <SkeletonAllShoes />;
  }

  return (
    <HeaderWeeklyStyled>
      <Container>
        <Row justify="center">
          <Heading level="2" as="h1">
            Privacy Policy
          </Heading>
        </Row>
      </Container>
    </HeaderWeeklyStyled>
  );
};

export default HeaderPrivacyPolicy;
