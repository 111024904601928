import * as React from 'react';

import HeaderWeAreMakers from '../../HeaderWeAreMakers';
import InfoWeAreMakers from '../../InfoWeAreMakers';

const WeAreMakersPage = () => {
  return (
    <>
      <HeaderWeAreMakers />
      <InfoWeAreMakers />
    </>
  );
};

export default WeAreMakersPage;
