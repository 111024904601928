import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import {FontWeight, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import useImgixImage from '../../hooks/useImgixImage';
import {generateImageUrl} from '../../utils/productHelper';
import {CartStyle, CardImage, ButtonLove, ButtonTrash} from '../Cart/styles';

const Cart = ({
  img,
  name,
  reff,
  price,
  onClick,
  trashLogo,
  imageHeart,
  numberReff,
  description,
  disableFavorite,
  onClickFavorite,
}) => {
  const imgEmptySmall = useImgixImage('/images/no-image.png');
  return (
    <Container>
      <CartStyle>
        <Row>
          <Col xs={1.5} md={1.9} align="center" justify="center">
            <CardImage>
              <img src={img ? generateImageUrl(img) : imgEmptySmall} />
            </CardImage>
          </Col>
          <Col xs={1.8} md={4.1}>
            <Row>
              <Col>
                <Text fontWeight={(FontWeight.Bold = '600')} as="h3">
                  {name}
                </Text>
                <Link
                  to={`/products/${numberReff}`}
                  fontWeight={(FontWeight.Light = '600')}
                  level="6"
                  as="h4"
                >
                  {reff}
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>{description}</Col>
            </Row>
          </Col>
          <Col xs={0.7} md={1.8} align="flex-end" justify="start">
            <Row>
              <Text fontWeight={(FontWeight.Bold = '600')} as="h3">
                ${price}
              </Text>
            </Row>
            <Row>
              {!process.env['GATSBY_ORDER_ACTIVATION'] === true
                ? ''
                : !disableFavorite && (
                    <ButtonLove onClick={onClickFavorite}>
                      <img src={imageHeart}></img>
                    </ButtonLove>
                  )}
              <ButtonTrash onClick={onClick}>
                <img src={trashLogo}></img>
              </ButtonTrash>
            </Row>
          </Col>
        </Row>
      </CartStyle>
    </Container>
  );
};

export default Cart;
Cart.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  reff: PropTypes.string,
  price: PropTypes.string,
  onClick: PropTypes.func,
  trashLogo: PropTypes.string,
  imageHeart: PropTypes.string,
  numberReff: PropTypes.string,
  description: PropTypes.string,
  onClickFavorite: PropTypes.func,
  disableFavorite: PropTypes.bool,
};
