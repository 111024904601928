import React, {useState, useEffect} from 'react';
import {FontWeight, Heading, Text} from 'styled-typography';
import useImgixImage from '../../hooks/useImgixImage';
import InputForm from '../core/InputForm';
import {CardCart} from '../pages/CartPage/styles';
import {PersonalInfoStyle} from './styles';
import ButtonBlack from '../core/ButtonBlack';
import {fetchAccount} from '../../API/makers/users';
import {useGlobalContext} from '../../context';
import {fetchCountries, fetchStates} from '../../API/makers/countries';
import updateUser from '../../API/makers/users';
import {navigate} from 'gatsby-link';
import {useAlert} from 'react-alert';
import {getCurrentUser, getUser, setUser} from '../../services/authentication';

const PersonalInfo = () => {
  const tooltip = useImgixImage('/images/tooltip.png');
  const {refreshUser, userLocal} = useGlobalContext();
  const {accountUser} = fetchAccount(userLocal?.id);
  const alert = useAlert();
  const accountDefault = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    company: {
      companyName: '',
      phoneNumber: '',
      cellNumber: '',
      emailCompany: '',
      address: '',
      country: process.env['GATSBY_DEFAULT_COUNTRY_ID'],
      state: '',
      city: '',
      postCode: '',
      taxOrIdNumber: '',
      webSiteURL: '',
    },
  };
  const {countries} = fetchCountries();
  const [account, setAccount] = useState(accountDefault);
  useEffect(() => {
    setAccount({
      firstName: accountUser.first_name,
      lastName: accountUser.last_name,
      email: accountUser.email,
      phoneNumber: accountUser.phone_number,
      password: '',
      passwordConfirmation: '',
      company: {
        id: accountUser?.company?.id,
        companyName: accountUser?.company?.company_name,
        phoneNumber: accountUser?.company?.phone_number,
        cellNumber: accountUser?.company?.cell_number,
        companyEmail: accountUser?.company?.email,
        addressLine: accountUser?.company?.address,
        city: accountUser?.company?.city,
        postCode: accountUser?.company?.zip_code,
        taxOrIdNumber: accountUser?.company?.tax_id_number,
        webSiteURL: accountUser?.company?.website_url,
        country: accountUser?.company?.spree_country_id,
        state: accountUser?.company?.spree_state_id,
      },
    });
  }, [accountUser.id]);

  const {states} = fetchStates(
    account.company.country
      ? account.company.country
      : accountDefault.company.country
  );

  const handleValueChange = (key, value) => {
    setAccount({
      ...account,
      [key]: value,
    });
  };

  const handleInputObjectChange = (objectKey, key, value) => {
    setAccount({
      ...account,
      [objectKey]: {
        ...account[objectKey],
        [key]: value,
      },
    });
  };

  const handleUpdate = e => {
    e.preventDefault();
    if (account.password === account.passwordConfirmation) {
      updateUser(
        userLocal?.id,
        getCurrentUser()['Access-Token'],
        account,
        navigate('/account?setting=personal-info')
      );
      alert.success('Account Update');
      setUser({
        ...getUser(),
        first_name: account.firstName,
        email: account.email,
      });
      refreshUser();
    } else {
      alert.error('Passwords must match ');
    }
  };

  return (
    <PersonalInfoStyle>
      <CardCart height="100%" width="100%">
        <form onSubmit={handleUpdate}>
          <div className="container">
            <Heading level="4" as="h1">
              Account
            </Heading>
            <Text level="5" as="h2" fontWeight={FontWeight.Bold}>
              Personal Information
            </Text>
            <div className="wrapper-info">
              <div className="field">
                <label>
                  <Text level="6">First Name*</Text>
                  <InputForm
                    value={account.firstName}
                    onChange={e =>
                      handleValueChange('firstName', e.target.value)
                    }
                  />
                </label>
                <label>
                  <Text level="6">Last Name*</Text>
                  <InputForm
                    value={account.lastName}
                    onChange={e =>
                      handleValueChange('lastName', e.target.value)
                    }
                  />
                </label>
              </div>

              <div className="field">
                <label>
                  <Text level="6">Phone Number</Text>
                  <InputForm
                    onChange={e =>
                      handleValueChange('phoneNumber', e.target.value)
                    }
                    placeholder="+1122002221212"
                    value={account.phoneNumber}
                  />
                </label>
                <label>
                  <Text level="6">Email</Text>
                  <InputForm
                    value={account.email}
                    onChange={e => handleValueChange('email', e.target.value)}
                  />
                </label>
              </div>
              <Text level="5" as="h2" fontWeight={FontWeight.Bold}>
                Company or boutique information
              </Text>
              <div className="field">
                <label>
                  <Text level="6">Company or boutique name</Text>
                  <InputForm
                    value={account.company.companyName}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'companyName',
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  <Text level="6">Phone Number</Text>
                  <InputForm
                    placeholder="+1 470-673-0203"
                    value={account.company.phoneNumber}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'phoneNumber',
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
              <div className="field">
                <label>
                  <Text level="6">Cell Number</Text>
                  <InputForm
                    placeholder="+305 121-411"
                    value={account.company.cellNumber}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'cellNumber',
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  <Text level="6">Email</Text>
                  <InputForm
                    value={account.company.companyEmail}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'companyEmail',
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
              <div className="field">
                <label>
                  <Text level="6">Address line</Text>
                  <InputForm
                    value={account.company.addressLine}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'addressLine',
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  <Text level="6">Country</Text>
                  <select
                    name="country"
                    id="country"
                    className="input-select"
                    value={account.company.country}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'country',
                        e.target.value
                      )
                    }
                  >
                    {countries.map(countrie => (
                      <option key={countrie.id} value={countrie.id}>
                        {countrie.name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="field">
                <label>
                  <Text level="6">State/Province</Text>
                  <select
                    name="state"
                    id="state"
                    className="input-select"
                    value={account.company.state}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'state',
                        e.target.value
                      )
                    }
                  >
                    {states.map(state => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  <Text level="6">Suburb/City</Text>
                  <InputForm
                    value={account.company.city}
                    onChange={e =>
                      handleInputObjectChange('company', 'city', e.target.value)
                    }
                  />
                </label>
              </div>
              <div className="field">
                <label>
                  <Text level="6">Zip/Postcode</Text>
                  <InputForm
                    value={account.company.postCode}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'postCode',
                        e.target.value
                      )
                    }
                  />
                </label>
                <label>
                  <Text level="6">Tax-ID Number</Text>
                  <InputForm
                    value={account.company.taxOrIdNumber}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'taxOrIdNumber',
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
              <div className="field">
                <label>
                  <Text level="6">Website URL</Text>
                  <InputForm
                    placeholder="example@makers.shoes"
                    value={account.company.webSiteURL}
                    onChange={e =>
                      handleInputObjectChange(
                        'company',
                        'webSiteURL',
                        e.target.value
                      )
                    }
                  />
                </label>
              </div>
              <Text level="5" as="h2" fontWeight={FontWeight.Bold}>
                Password
              </Text>
              <div className="field">
                <label>
                  <Text level="6">
                    New password <img src={tooltip} />
                  </Text>
                  <InputForm
                    value={account.password}
                    type="password"
                    onChange={e =>
                      handleValueChange('password', e.target.value)
                    }
                  />
                </label>
                <label>
                  <Text level="6">
                    Confirm new password <img src={tooltip} />
                  </Text>
                  <InputForm
                    type="password"
                    value={account.passwordConfirmation}
                    onChange={e =>
                      handleValueChange('passwordConfirmation', e.target.value)
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="container-button">
            <ButtonBlack width="212px" type="submit" onClick={handleUpdate}>
              UPDATE INFORMATION
            </ButtonBlack>
          </div>
        </form>
      </CardCart>
    </PersonalInfoStyle>
  );
};

export default PersonalInfo;
