import useSWR from 'swr';
import fetcher from './fetcher';
import {option_types_url} from './urls';

export const fetchOptionTypes = name => {
  const {data, error} = useSWR(option_types_url, fetcher);
  const optionTypes = data?.filter(item => item.name === name);
  return {
    optionTypes: (data && optionTypes) || [],
    error: error,
    isLoading: !data && !error,
  };
};
