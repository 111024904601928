import React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import InputForm from '../core/InputForm';
import {ContactFormStyle} from './styles';
import ReCAPTCHA from 'react-google-recaptcha';
import ButtonBlack from '../../components/core/ButtonBlack';

const Form = ({contact, onInputChange, errors, onRegister}) => {
  const {nameContact, companyOrStore, emailContact, phoneNumber, subject} =
    contact;

  function onChangeCaptcha(value) {
    'Captcha value:', value;
  }

  return (
    <ContactFormStyle>
      <Text className="title1-normal" as="h2">
        How Can We Help You?
      </Text>
      <Text level="5" as="h3">
        For any customer service inquiries, please fill out the form below and
        we will get back to you by the following business day. For any urgent
        cases, please call us at 305-576-1195 from Monday to Friday 9 am to 5 pm
        EST.
      </Text>
      <form>
        <div className="field">
          <div>
            <Text level="6">Name*</Text>
            <InputForm
              type="text"
              field="nameContact"
              placeholder="Name"
              value={nameContact}
              errors={errors}
              onChange={onInputChange('nameContact')}
            />
          </div>
          <div>
            <Text level="6">Company or Store Name*</Text>
            <InputForm
              type="text"
              field="companyOrStore"
              placeholder="Company or Store Name"
              value={companyOrStore}
              errors={errors}
              onChange={onInputChange('companyOrStore')}
            />
          </div>
        </div>

        <div className="field">
          <div>
            <Text level="6">Email Address*</Text>
            <InputForm
              type="email"
              errors={errors}
              value={emailContact}
              placeholder="example@makers.com"
              field="emailContact"
              onChange={onInputChange('emailContact')}
            />
          </div>
          <div>
            <Text level="6">Phone number*</Text>
            <InputForm
              type="number"
              field="phoneNumber"
              placeholder="+1"
              value={phoneNumber}
              errors={errors}
              onChange={onInputChange('phoneNumber')}
            />
          </div>
        </div>

        <div className="field">
          <div className="wrapper-text">
            <Text level="6">Your Subject*</Text>
            <InputForm
              type="text"
              field="subject"
              placeholder="Your Subject"
              value={subject}
              errors={errors}
              onChange={onInputChange('subject')}
            />
          </div>
        </div>
      </form>
      <ReCAPTCHA
        sitekey={process.env.GATSBY_NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        onChange={onChangeCaptcha}
      />
      <div className="form-footer">
        <ButtonBlack onClick={onRegister}>SEND NOW</ButtonBlack>
      </div>
    </ContactFormStyle>
  );
};

export default Form;
Form.propTypes = {
  onRegister: PropTypes.func,
  onInputChange: PropTypes.func,
  contact: PropTypes.shape({
    nameContact: PropTypes.string,
    companyOrStore: PropTypes.string,
    emailContact: PropTypes.string,
    phoneNumber: PropTypes.number,
    subject: PropTypes.string,
  }),
  errors: PropTypes.array,
};
