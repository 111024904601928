import styled from 'styled-components';
import {Text} from 'styled-typography';

export const MenuItem = styled(Text)`
  color: ${({theme}) => theme.colors.secondary};
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  filter: grayscale(1);
  cursor: pointer;
  img {
    margin-right: 12px;
  }
  :hover {
    color: ${({theme}) => theme.colors.primary2};
    filter: unset;
  }
  ${({active, theme}) =>
    active &&
    ` 
  color:${theme.colors.primary2};
  filter: unset;
  `}
`;

export const MenuAccountStyle = styled.div`
  .wrapper-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 20px;
    margin-top: 20px;
    width: 300px;

    h1 {
      margin-bottom: 22px;
      margin-top: 0px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
