import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';
import {Heading, Text} from 'styled-typography';
import ButtonAllShoes from '../core/ButtonAllShoes';
import {HeaderAllShoesStyled} from './styles';
import {fetchTaxons} from '../../API/makers/taxons';
import useImgixImage from '../../hooks/useImgixImage';
import SkeletonAllShoes from './SkeletonAllShoes';
import {useGlobalContext} from '../../context';

const HeaderAllShoes = () => {
  const {taxons, isLoading} = fetchTaxons();
  const logoEmpty = useImgixImage('/images/logo-empty.png');
  const {setFilterStyle} = useGlobalContext();

  if (isLoading) {
    return <SkeletonAllShoes />;
  }

  const handleFilterStyle = filter => {
    setFilterStyle(filter);
  };

  return (
    <HeaderAllShoesStyled>
      <Row justify="center">
        <Heading level="2" as="h1">
          all shoes
        </Heading>
      </Row>
      <Row className="wrap__categories">
        {!taxons.length && !isLoading ? (
          <></>
        ) : (
          <div className="btn-categories">
            <ButtonAllShoes
              height="40px"
              onClick={() => handleFilterStyle('ALL')}
            >
              <Text as="p" level="5">
                all shoes
              </Text>
            </ButtonAllShoes>
          </div>
        )}
        {!taxons.length && !isLoading ? (
          <Col align="center">
            <img src={logoEmpty} className="empty-state"></img>
          </Col>
        ) : (
          taxons.map(taxon => (
            <div className="btn-categories" key={taxon.id}>
              <ButtonAllShoes onClick={() => handleFilterStyle(taxon)}>
                <Text level="5">{taxon.name}</Text>
              </ButtonAllShoes>
            </div>
          ))
        )}
      </Row>
    </HeaderAllShoesStyled>
  );
};

export default HeaderAllShoes;
