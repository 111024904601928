import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Text} from 'styled-typography';
import {Col, Row} from 'react-awesome-styled-grid';

import ReCAPTCHA from 'react-google-recaptcha';
import {FormRegisterStyle} from './styles';
import ButtonProduct from '../core/ButtonProduct';
import InputForm, {getError} from '../core/InputForm';
import {fetchCountries, fetchStates} from '../../API/makers/countries';
import {useGlobalContext} from '../../context';
import {Link} from 'gatsby';

const TellUsAbout = ({
  register,
  onRegister,
  onValueChange,
  onInputObjectChange,
  errors,
  display,
}) => {
  const {
    termsConditions,
    company: {
      companyName,
      cellNumber,
      companyPhoneNumber,
      companyEmail,
      address,
      country,
      state,
      city,
      postCode,
      taxOrIdNumber,
      webSiteURL,
      certificateCompany,
    },
  } = register;
  const {disableBtn, setDisableBtn} = useGlobalContext();
  const {countries} = fetchCountries();
  const {states} = fetchStates(country);

  function onChange(value) {
    'Captcha value:', value;
    termsConditions && setDisableBtn(false);
  }

  useEffect(() => {
    onChange(true);
  }, [termsConditions]);

  return (
    <FormRegisterStyle display={display}>
      <div className="container-form">
        <Row justify="start">
          <Col md={8} xs={4}>
            <Text className="title1-normal" as="h2">
              Tell us about your company or boutique
            </Text>
          </Col>
          <Col md={8} xs={4}>
            <Text level="6">Company or boutique name*</Text>
            <InputForm
              onChange={onInputObjectChange('company', 'companyName')}
              placeholder="Company or boutique name"
              type="text"
              value={companyName}
              field="companyName"
              name="user[company_attributes][company_name]"
              errors={errors}
            />
            <div className="field">
              <div>
                <Text level="6">Cell Number*</Text>
                <InputForm
                  onChange={onInputObjectChange('company', 'cellNumber')}
                  placeholder="+1 1315-794-0592"
                  type="text"
                  value={cellNumber}
                  field="cellNumber"
                  name="user[company_attributes][cell_number]"
                  errors={errors}
                />
              </div>
              <div>
                <Text level="6">Phone Number</Text>
                <InputForm
                  onChange={onInputObjectChange(
                    'company',
                    'companyPhoneNumber'
                  )}
                  placeholder="+1-202-555-0100"
                  type="text"
                  value={companyPhoneNumber}
                  field="companyPhoneNumber"
                  name="user[company_attributes][phone_number]"
                  errors={errors}
                />
              </div>
            </div>
            <Text level="6">Email*</Text>
            <InputForm
              onChange={onInputObjectChange('company', 'companyEmail')}
              placeholder="email@nmakers.shoes"
              type="email"
              value={companyEmail}
              field="companyEmail"
              name="user[company_attributes][email]"
              errors={errors}
            />
            <Text level="6">Address Line*</Text>
            <InputForm
              onChange={onInputObjectChange('company', 'address')}
              placeholder="Ms Alice Smith Apartment 1c 213 Derrick Street Boston, MA 02130 USA"
              type="text"
              value={address}
              field="address"
              name="user[company_attributes][address]"
              errors={errors}
            />
            <div className="field">
              <div>
                <Text level="6">Country*</Text>
                <select
                  id="country"
                  className="input-select"
                  onChange={onInputObjectChange('company', 'country')}
                  name="user[company_attributes][spree_country_id]"
                  value={country}
                >
                  {countries.map(countrie => (
                    <option key={countrie.id} value={countrie.id}>
                      {countrie.name}
                    </option>
                  ))}
                </select>
                <div className="error-wrapper">
                  <span>{getError(errors, 'country')}</span>
                </div>
              </div>
              <div>
                <Text level="6">State/Province*</Text>
                <select
                  id="state"
                  className="input-select"
                  onChange={onInputObjectChange('company', 'state')}
                  value={state}
                  name="user[company_attributes][spree_state_id]"
                >
                  <option>Select a State/Province</option>
                  {states.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                <div className="error-wrapper">
                  <span>{getError(errors, 'state')}</span>
                </div>
              </div>
            </div>
            <div className="field">
              <div>
                <Text level="6">Suburb/City*</Text>
                <InputForm
                  onChange={onInputObjectChange('company', 'city')}
                  name="user[company_attributes][city]"
                  placeholder="Suburb/City"
                  type="text"
                  value={city}
                  field="city"
                  errors={errors}
                />
              </div>
              <div>
                <Text level="6">Zip/Postcode*</Text>
                <InputForm
                  onChange={onInputObjectChange('company', 'postCode')}
                  name="user[company_attributes][zip_code]"
                  placeholder="Zip/Postcode"
                  type="number"
                  value={postCode}
                  field="postCode"
                  errors={errors}
                />
              </div>
            </div>
            <Text level="6">Tax-ID Number*</Text>
            <InputForm
              onChange={onInputObjectChange('company', 'taxOrIdNumber')}
              name="user[company_attributes][tax_id_number]"
              placeholder="Tax-ID Number"
              type="text"
              value={taxOrIdNumber}
              field="taxOrIdNumber"
              errors={errors}
            />
            <Text level="6">Attach your company certificate* </Text>
            <InputForm
              type="file"
              placeholder="Click here to attach the document"
              name="user[company_attributes][certificate]"
              onChange={onInputObjectChange('company', 'certificateCompany')}
              value={certificateCompany}
              field="certificateCompany"
              errors={errors}
            />
            <Text level="6">Website URL</Text>
            <InputForm
              onChange={onInputObjectChange('company', 'webSiteURL')}
              name="user[company_attributes][website_url]"
              placeholder="market.markersshoes.com"
              type="text"
              value={webSiteURL}
              field="webSiteURL"
              errors={errors}
            />
            <div className="container-captcha">
              <ReCAPTCHA
                sitekey={process.env.GATSBY_NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                onChange={onChange}
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  onClick={onValueChange('termsConditions')}
                />
                By registering, you agree to
                <Link to={'/privacy-policy'}>
                  {' '}
                  General Terms and Conditions
                </Link>
              </label>
            </div>
          </Col>
          <ButtonProduct
            onClick={onRegister}
            width="100%"
            height="44px"
            disabled={disableBtn}
          >
            FINISH
          </ButtonProduct>
        </Row>
      </div>
    </FormRegisterStyle>
  );
};

export default TellUsAbout;

TellUsAbout.propTypes = {
  disableBtn: PropTypes.bool,
  onRegister: PropTypes.func,
  onValueChange: PropTypes.func,
  onInputObjectChange: PropTypes.func,
  display: PropTypes.bool,
  register: PropTypes.shape({
    termsConditions: PropTypes.bool,
    company: PropTypes.shape({
      companyName: PropTypes.string,
      cellNumber: PropTypes.number,
      companyPhoneNumber: PropTypes.number,
      companyEmail: PropTypes.string,
      address: PropTypes.string,
      country: PropTypes.string,
      state: PropTypes.string,
      city: PropTypes.string,
      postCode: PropTypes.number,
      taxOrIdNumber: PropTypes.number,
      certificateCompany: PropTypes.string,
      webSiteURL: PropTypes.string,
    }),
  }),
  errors: PropTypes.array,
};
