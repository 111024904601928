import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const ClipsStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  text-align: center;
  :last-child {
    margin-bottom: 40px;
  }
  .clip-section {
    align-items: center;
    justify-content: center;
    :first-child {
      margin-top: -40px;
      ${props => config(props).media['sm']`
        margin-top: 0px;
      `}
    }
    .clip-section-info {
      align-items: center;
      justify-content: center;
      .line {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 50%;
        z-index: inherit;
        &::before {
          content: ' ';
          width: 40%;
          border-bottom: solid 2px ${({theme}) => theme.colors.primary2};
        }
      }
    }
    h4 {
      margin-top: 10px;
      padding-left: 10px;
      :last-child {
        margin-bottom: ${({tag}) => (tag === 'right' ? '0px' : '40px')};
        ${props => config(props).media['sm']`
          margin-bottom: 0px;
        `}
      }
    }
    h3 {
      margin-top: ${({tag}) => (tag === 'right' ? '0px' : '40px')};
      ${props => config(props).media['sm']`
        margin-top: 0px;
      `}
    }
  }
`;
