import React, {useState} from 'react';
import {Container} from 'react-awesome-styled-grid';
import {Text} from 'styled-typography';
import {CardCart} from '../CartPage/styles';
import {ResetPasswordStyle} from './styles';
import {SendEmailPassword} from '../../../API/makers/users';
import PropTypes from 'prop-types';
import InputForm from '../../core/InputForm';
import ButtonProduct from '../../core/ButtonProduct';
import {useAlert} from 'react-alert';
import ResetPassword from './ResetPassword';
import {useQueryParam, StringParam} from 'use-query-params';

const ResetPasswordPage = () => {
  const [PageQueryPassword] = useQueryParam(
    'reset_password_token',
    StringParam
  );

  const alert = useAlert();

  const [email, setEmail] = useState('');

  const handleInputChange = e => {
    setEmail(e.target.value);
  };

  const handleSendEmail = e => {
    e.preventDefault();
    email === ''
      ? alert.error('Please enter a valid email')
      : (email &&
          SendEmailPassword({
            email,
          }),
        setEmail(''),
        alert.success('Your request has been sent to the mail'));
  };

  return (
    <Container>
      <ResetPasswordStyle>
        {!PageQueryPassword ? (
          <CardCart width="390px" height="250px">
            <form onSubmit={handleSendEmail}>
              <Text as="h1" level="4" fontWeight={500}>
                Reset Password
              </Text>
              <div className="wrapper-input">
                <Text level="5" fontWeight={400}>
                  E-Mail Address
                </Text>
                <InputForm value={email} onChange={e => handleInputChange(e)} />
              </div>
              <div className="container-button">
                <ButtonProduct
                  width="345px"
                  height="50px"
                  onClick={e => handleSendEmail(e)}
                >
                  <Text level="5"> Send password reset link</Text>
                </ButtonProduct>
              </div>
            </form>
          </CardCart>
        ) : (
          <ResetPassword />
        )}
      </ResetPasswordStyle>
    </Container>
  );
};
export default ResetPasswordPage;
ResetPasswordPage.propTypes = {
  emailPassword: PropTypes.shape({
    email: PropTypes.string,
  }),
};
