import styled from 'styled-components';

export const PaginationStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  .ButtonPagination {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 100px;
    border: 1px solid ${({theme}) => theme.colors.secondary2};
    margin-left: 15px;
    width: 35px;
    height: 35px;
    margin-bottom: 90px;
    h1 {
      font-weight: 600;
      justify-content: center;
    }
    :hover {
      border: 1px solid ${({theme}) => theme.colors.secondary};
    }

    :focus {
      background-color: ${({theme}) => theme.colors.primary2};
      border-color: ${({theme}) => theme.colors.primary2};
      color: white;
      h1 {
        color: white;
      }
    }
    :active {
      background-color: ${({theme}) => theme.colors.primary2};
      border-color: ${({theme}) => theme.colors.primary2};
      color: white;
      h1 {
        color: white;
      }
    }
  }
`;
export const ButtonPagination = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid ${({theme}) => theme.colors.secondary2};
  margin-left: 15px;
  width: 35px;
  height: 35px;
  margin-bottom: 90px;
  &:disabled {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: default;
    border: none;
    opacity: 0.5;
    :hover {
      border: 1px solid transparent;
      background-color: rgba(255, 255, 255, 0.5);
    }
    :focus {
      background-color: transparent;
    }
  }
  h1 {
    font-weight: 600;
    justify-content: center;
  }
  :hover {
    border: 1px solid ${({theme}) => theme.colors.secondary};
  }

  ${({active, theme}) =>
    active &&
    `
    background-color: ${theme.colors.primary2};
    border-color: ${theme.colors.primary2};
    color: white;
    h1 {
      color: white;
    }
  `}
`;
