import React from 'react';
import {Col, Row} from 'react-awesome-styled-grid';
import {FontWeight, Text} from 'styled-typography';

import {ProductStyle} from './styles';
import {useGlobalContext} from '../../context';
import useImgixImage from '../../hooks/useImgixImage';
import {
  generateImageUrl,
  getOptionValueByOptionType,
} from '../../utils/productHelper';

const CartProduct = () => {
  const imgEmptySmall = useImgixImage('/images/no-image.png', {
    w: 150,
    h: 150,
  });
  const {currentOrder} = useGlobalContext();
  const {line_items} = currentOrder || '';

  return (
    <div>
      <ProductStyle>
        {line_items?.map(
          ({quantity, id, variant: {images, name, option_values, price}}) => {
            return (
              <Row className="container-product" key={id}>
                <Col xs="1.4" lg="4" className="img-wrapper">
                  {images.length ? (
                    <img
                      className="image-setting"
                      src={generateImageUrl(images[0].product_url)}
                    />
                  ) : (
                    <img className="image-setting-empty" src={imgEmptySmall} />
                  )}
                </Col>
                <Col lg="6" className="container-information">
                  <Text level="5" fontWeight={(FontWeight.Bold = '700')}>
                    {name}
                  </Text>
                  <Text level="5" fontWeight={(FontWeight.Bold = '400')}>
                    Size:{' '}
                    {getOptionValueByOptionType(option_values, 'size', 'name')}
                  </Text>
                  <Text level="5" fontWeight={(FontWeight.Bold = '400')}>
                    Color:{' '}
                    {getOptionValueByOptionType(option_values, 'color', 'name')}
                  </Text>
                </Col>
                <Col lg="1" className="info-wrapper">
                  <Text
                    level="5"
                    as="h5"
                    fontWeight={(FontWeight.Bold = '400')}
                    justify="center"
                    align="center"
                  >
                    x{quantity}
                  </Text>
                </Col>
                <Col lg="1" className="info-wrapper">
                  <Text
                    level="5"
                    as="h5"
                    fontWeight={(FontWeight.Bold = '700')}
                  >
                    ${price}
                  </Text>
                </Col>
              </Row>
            );
          }
        )}
      </ProductStyle>
    </div>
  );
};

export default CartProduct;
