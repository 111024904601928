import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Text} from 'styled-typography';
import {createPopper} from '@popperjs/core';
import {Col, Row} from 'react-awesome-styled-grid';

import InputForm from '../core/InputForm';
import {FormRegisterStyle} from './styles';
import {useGlobalContext} from '../../context';
import ButtonProduct from '../core/ButtonProduct';
import useImgixImage from '../../hooks/useImgixImage';

const FormRegister = ({
  onNextClick,
  onInputChange,
  register,
  errors,
  display,
}) => {
  const {name, lastName, phoneNumber, email, password, confirmPassword} =
    register;

  const tooltipImg = useImgixImage('/images/tooltip.png');
  const {modalIsOpen, setModalIsOpen} = useGlobalContext();

  useEffect(() => {
    setModalIsOpen(false);
  }, [modalIsOpen]);

  const popcorn = document.querySelector('#popcorn');
  const tooltip = document.querySelector('#tooltip');

  createPopper(popcorn, tooltip, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <FormRegisterStyle display={display}>
      <div className="container-form">
        <Row justify="start">
          <Col md={8} xs={4}>
            <Text className="title1-normal" as="h2">
              Personal Information
            </Text>
          </Col>
          <Col md={8} xs={4}>
            <Text level="6">First Name*</Text>
            <InputForm
              onChange={onInputChange('name')}
              placeholder="Name"
              name="user[first_name]"
              type="text"
              value={name}
              field="name"
              errors={errors}
            />
            <Text level="6">Last Name*</Text>
            <InputForm
              onChange={onInputChange('lastName')}
              placeholder="Last Name"
              type="text"
              value={lastName}
              field="lastName"
              name="user[last_name]"
              errors={errors}
            />
            <Text level="6">Phone Number*</Text>
            <InputForm
              onChange={onInputChange('phoneNumber')}
              placeholder="+1 - 555 - 5555"
              type="text"
              value={phoneNumber}
              field="phoneNumber"
              name="user[phone_number]"
              errors={errors}
            />
            <Text level="6">Email*</Text>
            <InputForm
              onChange={onInputChange('email')}
              placeholder="E-mail"
              type="e-mail"
              value={email}
              field="email"
              name="user[email]"
              errors={errors}
            />
            <Text className="popper-password" level="6">
              Password
              <img id="popcorn" aria-describedby="tooltip" src={tooltipImg} />
              <div id="tooltip" role="tooltip">
                <ul>
                  <li>min 6 characters,</li>
                  <li>lowercase,</li>
                  <li>capital letters,</li>
                  <li>numbers,</li>
                  <li>special signs (#?!@+$%^&*-)</li>
                </ul>
                <div id="arrow" data-popper-arrow></div>
              </div>
            </Text>
            <InputForm
              onChange={onInputChange('password')}
              placeholder="Create your Password"
              type="password"
              value={password}
              field="password"
              name="user[password]"
              errors={errors}
            />
            <Text level="6">Confirm your Password</Text>
            <InputForm
              onChange={onInputChange('confirmPassword')}
              placeholder="Confirm your Password"
              type="password"
              value={confirmPassword}
              field="confirmPassword"
              name="user[confirmPassword]"
              errors={errors}
            />
          </Col>
          <ButtonProduct onClick={onNextClick} width="100%" height="44px">
            CONTINUE
          </ButtonProduct>
        </Row>
      </div>
    </FormRegisterStyle>
  );
};

export default FormRegister;

FormRegister.propTypes = {
  onNextClick: PropTypes.func,
  onInputChange: PropTypes.func,
  display: PropTypes.bool,
  register: PropTypes.shape({
    name: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.number,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
  errors: PropTypes.array,
};
