import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import CheckoutForm from './CheckoutForm';
import {useGlobalContext} from '../../context';
import SkeletonPayment from './SkeletonPayment';
import OrderSummary from '../Cart/OrderSummary';
import ButtonProduct from '../core/ButtonProduct';
import {CardCart} from '../pages/CartPage/styles';
import {getCreditCards} from '../../API/makers/users';
import useImgixImage from '../../hooks/useImgixImage';
import {getOrder} from '../../services/temporalOrder';
import PersonalInformation from './PersonalInformation';
import {getCurrentUser} from '../../services/authentication';
import {addPayment, nextStepOrder} from '../../API/makers/orders';
import {InfoAddressStyle, StylePayments, CardLogo} from './styles';

const Payments = ({options, setNumberState}) => {
  const visaCard = useImgixImage('/CreditCards/card-logo-visa.svg');
  const amexCard = useImgixImage('/CreditCards/card-logo-amex.svg');
  const masterCard = useImgixImage('/CreditCards/card-logo-mastercard.svg');
  const unknownCard = useImgixImage('/CreditCards/card-logo-unknown.svg');
  const cardTypes = {
    visa: visaCard,
    amex: amexCard,
    mastercard: masterCard,
    unknown: unknownCard,
  };
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [listCreditCards, setListCreditCards] = useState();
  const {credit_cards, count} = listCreditCards || '';
  const {currentOrder, fetchCurrentOrder} = useGlobalContext();
  const {item_total, adjustment_total, total} = currentOrder;
  const paymentDefault = {
    amount: '',
    payment_method_id: null,
    source_attributes: {
      gateway_customer_profile_id: null,
      gateway_payment_profile_id: null,
      wallet_payment_source_id: '',
    },
  };
  const [payment, setPayment] = useState(paymentDefault);
  useEffect(() => {
    options.customer &&
      setPayment({
        ...payment,
        source_attributes: {
          ...payment.source_attributes,
          gateway_customer_profile_id: options.customer,
        },
      });
  }, [options.customer]);
  const {
    source_attributes: {wallet_payment_source_id, gateway_customer_profile_id},
  } = payment;
  const handleSendCheckoutPayment = () => {
    if (wallet_payment_source_id || gateway_customer_profile_id) {
      addPayment(
        {
          user: getCurrentUser()['Access-Token'],
          orderNumber: getOrder(),
          payment,
        },
        () => {
          nextStepOrder(
            {
              user: getCurrentUser()['Access-Token'],
              orderNumber: getOrder(),
            },
            () => {
              fetchCurrentOrder();
            }
          );
        }
      );
    }
  };
  const handleGetListCreditCards = id => {
    getCreditCards(
      {user: getCurrentUser()['Access-Token'], idUser: id},
      getCreditCard => setListCreditCards(getCreditCard)
    ).catch(error => setError(error));
  };
  const handleChangeGatewayPaymentProfileId = (objectKey, key, value) => {
    return () => {
      setPayment({
        ...payment,
        [objectKey]: {
          ...payment[objectKey],
          [key]: value,
        },
      });
    };
  };
  const handleValueChange = stripeResult => {
    stripeResult &&
      setPayment({
        amount: `${total}`,
        payment_method_id: currentOrder.payment_methods[0]?.id,
        source_attributes: {
          ...payment.source_attributes,
          gateway_payment_profile_id: `${stripeResult.payment_method}`,
        },
      });
  };
  const handlePaymentValueChange = () => {
    wallet_payment_source_id &&
      setPayment({
        ...payment,
        amount: `${total}`,
        payment_method_id: currentOrder.payment_methods[0]?.id,
      });
  };
  useEffect(() => {
    !listCreditCards && handleGetListCreditCards(getCurrentUser().id);
    listCreditCards && setIsLoading(false);
    error && setIsLoading(false);
  }, [listCreditCards, error]);
  useEffect(() => {
    if (count) {
      handleChangeGatewayPaymentProfileId(
        'source_attributes',
        'wallet_payment_source_id',
        credit_cards[0]?.wallet_id
      )();
    }
  }, [credit_cards, count]);
  useEffect(() => {
    payment.payment_method_id && handleSendCheckoutPayment();
  }, [payment.payment_method_id]);

  useEffect(() => {
    setNumberState(1);
  }, []);

  if (isLoading) {
    return <SkeletonPayment />;
  }
  return (
    <StylePayments>
      <Container>
        <Row
          justify={{xs: 'center', xl: 'space-around'}}
          align={{xs: 'center', xl: 'start'}}
        >
          <Col xs={3.5} md={4} lg={7} xl="5">
            <div className="container-payments">
              <Heading level="5" as="h3">
                PAYMENT INFORMATION
              </Heading>
              {!isLoading && count ? (
                <InfoAddressStyle>
                  <Row className="wrap-card-address">
                    {credit_cards.map(
                      ({id, cc_type, wallet_id: walletId, last_digits}) => (
                        <Col className="wrap-info-creditCard" key={id}>
                          <input
                            type="radio"
                            name="creditCards"
                            onClick={handleChangeGatewayPaymentProfileId(
                              'source_attributes',
                              'wallet_payment_source_id',
                              walletId
                            )}
                            value={id}
                            checked={
                              payment.source_attributes
                                .wallet_payment_source_id === walletId
                            }
                          />
                          <CardLogo
                            size="small"
                            type={cardTypes[cc_type ? cc_type : 'unknown']}
                          />
                          <PersonalInformation
                            title=""
                            name={` ${
                              cc_type ? cc_type : 'unknown'
                            } - XXXX - ${last_digits} ${id}`}
                          />
                        </Col>
                      )
                    )}
                    <Col lg="4" className="wrap-info-creditCard">
                      <input
                        type="radio"
                        name="creditCards"
                        value="addNewBillAddress"
                        onClick={handleChangeGatewayPaymentProfileId(
                          'source_attributes',
                          'wallet_payment_source_id',
                          null
                        )}
                        checked={!wallet_payment_source_id}
                      />
                      <PersonalInformation title="" name={'Add New Payment'} />
                    </Col>
                  </Row>
                </InfoAddressStyle>
              ) : (
                <CheckoutForm
                  wallet_payment_source_id={wallet_payment_source_id}
                  onValueChange={handleValueChange}
                />
              )}
              {/* {!isLoading && !wallet_payment_source_id && count && (
              )} */}
              {!isLoading && !wallet_payment_source_id && count ? (
                <CheckoutForm onValueChange={handleValueChange} />
              ) : (
                <Col align="center">
                  {wallet_payment_source_id && (
                    <ButtonProduct
                      width="200px"
                      height="40px"
                      onClick={handlePaymentValueChange}
                    >
                      Save and Continue
                    </ButtonProduct>
                  )}
                </Col>
              )}
            </div>
          </Col>
          <Col xs={4} md={4} lg={3.6} xl="3.6">
            <div className="container-order">
              <CardCart
                width="90%"
                height="100%"
                justify="center"
                align="center"
              >
                <OrderSummary
                  isCheckout
                  description={
                    <>
                      <div className="text-summary">
                        <Text level="6" as="h6">
                          Item Total:
                        </Text>
                        <Text level="6" as="h6">
                          Discount
                        </Text>
                        <Text level="6" as="h6">
                          Shipping total:
                        </Text>
                      </div>
                      <div className="calculate-summary">
                        <Text level="6" as="h4">
                          ${item_total}
                        </Text>
                        <Text level="6" as="h4">
                          {`$ ${adjustment_total || '0.0'}`}
                        </Text>
                        <Text level="6" as="h4">
                          To calculate
                        </Text>
                      </div>
                    </>
                  }
                  total={`$ ${total}`}
                />
              </CardCart>
            </div>
          </Col>
        </Row>
      </Container>
    </StylePayments>
  );
};

export default Payments;

Payments.propTypes = {
  options: PropTypes.object,
  setNumberState: PropTypes.number,
};
