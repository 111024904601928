import React from 'react';
import {Col, Hidden, Row} from 'react-awesome-styled-grid';
import {MoreProductsStyle} from './styles';

import Skeleton from 'react-loading-skeleton';

const SkeletonResultProducts = () => {
  return (
    <MoreProductsStyle>
      <div className="ContainerFilter">
        <Row justify={{xs: 'center'}}>
          <Col
            xs={0.9}
            sm={1.2}
            md={1}
            lg={1}
            xl={1}
            align={{xs: 'flex-start'}}
          >
            <Skeleton className="skeletonStyle" width={100} height={40} />
          </Col>
          <Col
            xs={0.81}
            sm={1.2}
            md={1}
            lg={1}
            xl={1}
            align={{xs: 'flex-start'}}
          >
            <Skeleton className="skeletonStyle" width={100} height={40} />
          </Col>
          <Col
            xs={0.9}
            sm={1.2}
            md={1}
            lg={1}
            xl={1}
            align={{xs: 'flex-start'}}
          >
            <Skeleton className="skeletonStyle" width={100} height={40} />
          </Col>
          <Col
            xs={1.3}
            sm={3.8}
            md={4.8}
            lg={8.5}
            xl={8}
            align={{xs: 'flex-start', sm: 'flex-end'}}
          >
            <Skeleton className="skeletonStyle" width={100} height={40} />
          </Col>
        </Row>
      </div>

      <Row>
        <Col align="center">
          <Hidden xs sm>
            <Skeleton className="skeletonResultProductStyle" count={5} />
          </Hidden>
          <Hidden md lg xl>
            <Skeleton className="skeletonResultProductStyle" count={4} />
          </Hidden>
        </Col>
      </Row>
    </MoreProductsStyle>
  );
};

export default SkeletonResultProducts;
