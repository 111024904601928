import {navigate} from 'gatsby-link';
import {useAlert} from 'react-alert';
import React, {useEffect, useState} from 'react';
import {FontWeight, Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import Cart from '../../Cart';
import {
  findMasterIdByVariantId,
  getOptionValueByOptionType,
  getSizeUnits,
} from '../../../utils/productHelper';
import SkeletonCart from './skeletonCart';
import MightAlsoLike from '../../MightAlsoLike';
import ButtonBlack from '../../core/ButtonBlack';
import {useGlobalContext} from '../../../context';
import OrderSummary from '../../Cart/OrderSummary';
import useImgixImage from '../../../hooks/useImgixImage';
import EmptyOrders from '../../EmptyAccount/EmptyOrders';
import {getOrder} from '../../../services/temporalOrder';
import {fetchProducts} from '../../../API/makers/products';
import {getCurrentUser} from '../../../services/authentication';
import {CartPageStyle, CardCart, InputDiscount} from './styles';
import {
  addDiscountCode,
  deleteItemOrder,
  nextStepOrder,
} from '../../../API/makers/orders';

const CartPage = () => {
  const heartblack = useImgixImage('/images/heart-black.png');
  const heartgold = useImgixImage('/images/heart-gold.png');
  const tooltip = useImgixImage('/images/tooltip.png');
  const trash = useImgixImage('/images/trash.png');
  const alert = useAlert();
  const {
    currentOrder,
    fetchCurrentOrder,
    disableBtn,
    setDisableBtn,
    handleChangeOnFavorite,
    getProduct_id,
  } = useGlobalContext();
  const {
    total_quantity,
    state,
    item_total,
    adjustment_total,
    total,
    line_items,
  } = currentOrder || {};
  const {products, isLoading} = fetchProducts();
  const [discountCode, setDiscountCode] = useState('');
  const handleDeleteItem = id => {
    deleteItemOrder(
      {
        user: getCurrentUser()['Access-Token'],
        variant_id: id,
        orderNumber: getOrder(),
      },
      () => {
        fetchCurrentOrder();
      }
    );
  };
  const handleCheckout = () => {
    nextStepOrder(
      {
        user: getCurrentUser()['Access-Token'],
        orderNumber: getOrder(),
      },
      () => {
        fetchCurrentOrder();
        navigate('/checkout');
      }
    );
  };
  const handleApplyDiscount = () => {
    addDiscountCode(
      {
        user: getCurrentUser()['Access-Token'],
        orderNumber: getOrder(),
        coupon_code: discountCode,
      },
      () => {
        fetchCurrentOrder();
        alert.success('you have added a discount code');
      }
    ).catch(item => {
      alert.error(item.error);
    });
  };
  useEffect(() => {
    if (getOrder() && state) {
      state !== 'cart' && navigate('/checkout');
    }
  }, [state]);
  useEffect(() => {
    if (getOrder()) {
      !total_quantity ? setDisableBtn(true) : setDisableBtn(false);
    }
  }, []);
  useEffect(() => {
    getOrder() && !total_quantity ? setDisableBtn(true) : setDisableBtn(false);
  }, [total_quantity]);

  if (isLoading) {
    return <SkeletonCart />;
  }
  return (
    <Container>
      <CartPageStyle>
        <Row>
          <Heading as="h2" level="6">
            Makers | <span>Cart</span>
          </Heading>
        </Row>
        <Row>
          <Heading as="h1" level="3">
            Your cart
          </Heading>
        </Row>
        <Row align="start">
          <Col xs={4} md={5.5}>
            {!isLoading && getOrder() && total_quantity ? (
              line_items.map(
                ({
                  quantity,
                  id,
                  variant_id,
                  variant: {price, name, images, option_values, product_id},
                }) => {
                  return (
                    <Cart
                      key={id}
                      img={images[0]?.product_url}
                      numberReff={product_id}
                      reff={` REF ${
                        product_id
                          ? `${product_id}`.padStart(6, '0')
                          : 'REF 001234'
                      }`}
                      price={price}
                      trashLogo={trash}
                      idFavorite={findMasterIdByVariantId(products, variant_id)}
                      imageHeart={
                        getProduct_id(
                          findMasterIdByVariantId(products, variant_id)
                        )
                          ? heartgold
                          : heartblack
                      }
                      name={name}
                      favoriteId={`${findMasterIdByVariantId(
                        products,
                        variant_id
                      )}`}
                      onClick={() => handleDeleteItem(id)}
                      onClickFavorite={() =>
                        handleChangeOnFavorite(
                          findMasterIdByVariantId(products, variant_id)
                        )
                      }
                      description={
                        <>
                          <Text
                            fontWeight={(FontWeight.Normal = '500')}
                            level="6"
                            as="h4"
                          >
                            Model:
                            <span>
                              {' '}
                              {name} ·{' '}
                              {getOptionValueByOptionType(
                                option_values,
                                'color',
                                'name'
                              )}{' '}
                            </span>
                          </Text>

                          <Text
                            fontWeight={(FontWeight.Normal = '500')}
                            level="6"
                            as="h4"
                          >
                            Size Run: <span>{getSizeUnits(option_values)}</span>
                          </Text>
                          <Text
                            fontWeight={(FontWeight.Normal = '500')}
                            level="6"
                            as="h4"
                          >
                            Quantity: <span>{quantity}</span>
                          </Text>
                        </>
                      }
                    />
                  );
                }
              )
            ) : (
              <EmptyOrders title={'there are no orders in the cart'} />
            )}
          </Col>
          <Col xs={4} sm={8} md={2.5}>
            <Row justify="center" align={{xs: 'center'}}>
              <CardCart
                width="90%"
                height="250px"
                justify="center"
                align="center"
              >
                <Heading level="4" as="h5">
                  Do you have a discount code?
                </Heading>
                <InputDiscount
                  onChange={e => setDiscountCode(e.target.value)}
                  placeholder="Enter your discount code"
                  value={discountCode}
                />
                <ButtonBlack
                  disabled={disableBtn}
                  className="containter-button"
                  width="85%"
                  onClick={handleApplyDiscount}
                >
                  apply code
                </ButtonBlack>
              </CardCart>
              <CardCart
                width="90%"
                height="358px"
                justify="center"
                align="center"
              >
                <OrderSummary
                  disabled={disableBtn}
                  onClick={handleCheckout}
                  description={
                    <>
                      <div className="text-summary">
                        <Text level="5" as="h6">
                          Subtotal
                        </Text>
                        <Text level="5" as="h6">
                          Discount
                        </Text>
                        <Text level="5" as="h6">
                          Shipping <img src={tooltip} />
                        </Text>
                      </div>
                      <div className="calculate-summary">
                        <Text level="5" as="h4">
                          {`$ ${item_total || '0.0'}`}
                        </Text>
                        <Text level="5" as="h4">
                          {`$ ${adjustment_total || '0.0'}`}
                        </Text>
                        <Text level="5" as="h4">
                          To calculate
                        </Text>
                      </div>
                    </>
                  }
                  total={`$ ${total || '0.0'}`}
                />
              </CardCart>
            </Row>
          </Col>
        </Row>
      </CartPageStyle>
      <MightAlsoLike />
    </Container>
  );
};

export default CartPage;

CartPage.defaultProps = {
  height: '235px',
  width: '360px',
};
