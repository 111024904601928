import * as React from 'react';
import {graphql, useStaticQuery} from 'gatsby';

import ClipSection from '../../Clips';
import {LookbookStyled} from './styles';

// Contenful static components
import Banner from '../../DynamicBanner/slidershow';
import StaticCategory from '../../StaticCategory';
import {parseRichText} from '../../../utils/staticContent';

const LookBookQuery = graphql`
  query pageQuery {
    contentfulPages(slug: {eq: "makersshoes"}) {
      id
      slug
      topSection {
        ... on ContentfulImageGallery {
          id
          images {
            publicUrl
            mimeType
            filename
          }
          internal {
            type
          }
        }
        ... on ContentfulSections {
          internal {
            type
          }
          banners {
            url
            id
            image {
              publicUrl
              mimeType
              filename
              title
            }

            mobileImage {
              publicUrl
              mimeType
              filename
              title
            }
            internal {
              type
            }
          }
        }
        ... on ContentfulTwoColumns {
          image {
            publicUrl
          }
          leftSideImage
          headlineImage {
            publicUrl
            mimeType
            filename
          }
          id
          internal {
            type
          }
          bodyText {
            raw
          }
          headline
        }
        ... on ContentfulParallaxBackgroundImage {
          id
          internal {
            type
          }
          image {
            publicUrl
            mimeType
            filename
          }
        }
      }
    }
  }
`;

const LookBook = () => {
  const {
    contentfulPages: {topSection},
  } = useStaticQuery(LookBookQuery);

  return (
    <div style={{marginBottom: 48}}>
      {topSection?.map((section, key) => {
        if (section?.internal?.type === 'ContentfulSections') {
          return (
            <Banner
              key={`section-${key}`}
              tagName="lookbook-banner"
              arrow="false"
              banners={section.banners}
            />
          );
        }

        if (section?.internal?.type === 'ContentfulTwoColumns') {
          return (
            <ClipSection
              key={`section-${key}`}
              title={section?.headline}
              text1={parseRichText(section?.bodyText?.raw)}
              clipTag={section.leftSideImage ? 'left' : 'right'}
              video={section.image?.publicUrl}
              headerImage={section.headlineImage?.publicUrl}
            />
          );
        }

        if (section?.internal?.type === 'ContentfulImageGallery') {
          return (
            <StaticCategory
              key={`section-${key}`}
              images={section.images.map(({publicUrl}) => publicUrl)}
            />
          );
        }

        if (section?.internal?.type === 'ContentfulParallaxBackgroundImage') {
          return (
            <LookbookStyled
              background={section?.image?.publicUrl}
              key={`section-${key}`}
            />
          );
        }
      })}
    </div>
  );
};

export default LookBook;
