import * as React from 'react';
import {FontWeight, Text} from 'styled-typography';
import PropTypes from 'prop-types';

import {StyledButtonCategory} from './styles';

const Button = ({children, ...props}) => {
  return (
    <StyledButtonCategory {...props}>
      <Text level="5" fontWeight={FontWeight.Light}>
        {children}
      </Text>
    </StyledButtonCategory>
  );
};

Button.propTypes = {
  children: PropTypes.node,
};

Button.defaultProps = {
  width: '118px',
  height: '44px',
};

export default Button;
