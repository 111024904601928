import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const CartPageStyle = styled.div`
  width: 98%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  h2 {
    padding: 0px;
    margin: 0px;
    padding-left: 20px;
    color: ${({theme}) => theme.colors.secondary2};
    padding-bottom: 10px;
    ${props => config(props).media['md']`
    padding-left: 40px;
    padding-bottom: 30px;
    `}
  }
  h1 {
    padding-left: 20px;
    padding-bottom: 10px;
    ${props => config(props).media['md']`
    padding-left: 40px;
    padding-bottom: 20px;
    `}
  }
  span {
    color: black;
  }
  .skeletonStyle {
    width: 90%;
    height: 135px;
    align-items: center;
    margin: 0px 0px 40px 0px;
  }
`;
export const CardCart = styled.div`
  display: flex;
  align-items: ${({align}) => align};
  justify-content: ${({justify}) => justify};
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: ${({marginTop}) => marginTop};
  /* width: ${({widthxs}) => (widthxs ? widthxs : '350px')}; */
  width: 100%;
  height: ${({height}) => height};
  margin-top: 0px;
  ${props => config(props).media['sm']`
    margin-top: 0px;
    margin-left: 0px;
  `}
  ${props => config(props).media['md']`
    border-radius: 20px;
    height: ${({height}) => (height ? height : '358px')};
    width: ${({width}) => width};
  `}
  h5 {
    margin-bottom: 20px;
  }
  .span-discount {
    color: ${({theme}) => theme.colors.primary2};
  }
  h3 {
    width: 90%;
    text-align: center;
    margin: 0 auto;
    color: ${({theme}) => theme.colors.secondary2};
    margin-top: 20px;
  }
  h4 {
    margin-bottom: 10px;
  }
  h6 {
    margin-left: 30px;
    color: ${({theme}) => theme.colors.secondary};
  }
  h1 {
    padding-left: 0px !important;
    margin-left: 0px;
    ${props => config(props).media['md']`
    margin-left: 0px;
    margin-right: 0px;
    `}
  }
  .containter-button {
    margin-top: 20px;
  }
  button {
    align-items: center;
    justify-content: center;
  }
`;
export const InputDiscount = styled.input`
  width: 80%;
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.secondary2};
  :focus {
    outline: none;
    border: solid 1px ${({theme}) => theme.colors.primary2};
  }
`;
