import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-awesome-styled-grid';
import {FontWeight, Heading, Text} from 'styled-typography';

import {ClipsStyled} from './styles';
import useImgixImage from '../../hooks/useImgixImage';

export const clipSection = ({
  title,
  text1,
  text2,
  text3,
  video,
  clipTag,
  headlineImage,
}) => {
  const Logo = useImgixImage('/images/Menu-Desktop-Makers-logo.svg');

  return (
    <ClipsStyled>
      {clipTag === 'right' ? (
        <Row className="clip-section" tag="right">
          <Col xs={3} sm={4} lg={4} className="clip-section-info">
            <Heading as="h3" fontWeight={FontWeight.Bold} level="2">
              {title?.trim() ? title : <img src={headlineImage || Logo} />}
            </Heading>
            <div className="line" />
            <Text as="p" fontWeight={FontWeight.Normal} level="6">
              {text1 || ''}
            </Text>
            <Text as="h4" fontWeight={FontWeight.Normal} level="6">
              {text2 || ''}
            </Text>
            <Text as="h4" fontWeight={FontWeight.Normal} level="6">
              {text3 || ''}
            </Text>
          </Col>
          <Col sm={4} lg={5} className="clip-section-video">
            <video muted="true" autoPlay="true" loop="true">
              <source src={video} type="video/mp4" />
            </video>
          </Col>
        </Row>
      ) : (
        <Row className="clip-section" tag="left">
          <Col xs={4} sm={4} lg={5} className="clip-section-video">
            <video muted="true" autoPlay="true" loop="true">
              <source src={video} type="video/mp4" />
            </video>
          </Col>
          <Col xs={3} sm={4} lg={4} className="clip-section-info">
            <Heading as="h3" fontWeight={FontWeight.Bold} level="2">
              {title?.trim() ? title : <img src={headlineImage || Logo} />}
            </Heading>
            {text1 && <div className="line" />}
            <Text as="h4" fontWeight={FontWeight.Normal} level="6">
              {text1 || ''}
            </Text>
            <Text as="h4" fontWeight={FontWeight.Normal} level="6">
              {text2 || ''}
            </Text>
            <Text as="h4" fontWeight={FontWeight.Normal} level="6">
              {text3 || ''}
            </Text>
          </Col>
        </Row>
      )}
    </ClipsStyled>
  );
};
export default clipSection;

clipSection.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  video: PropTypes.video,
  clipTag: PropTypes.string,
};
