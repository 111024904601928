import {navigate} from 'gatsby';
import {loadStripe} from '@stripe/stripe-js';
import React, {useEffect, useState} from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {Heading, Link, Text} from 'styled-typography';
import {Col, Container, Hidden, Row, Visible} from 'react-awesome-styled-grid';

import Address from '../../Checkout';
import {CheckoutPageStyle} from './styles';
import Confirm from '../../Checkout/Confirm';
import Payments from '../../Checkout/Payment';
import Complete from '../../Checkout/Complete';
import {useGlobalContext} from '../../../context';
import {clearOrder, orderUpdate} from '../../../API/makers/orders';
import {getOrder} from '../../../services/temporalOrder';
import {getSetupIntentId} from '../../../API/makers/payment';
import {getCurrentUser} from '../../../services/authentication';

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);

const CheckoutPage = () => {
  const {currentOrder, fetchCurrentOrder} = useGlobalContext();
  const {state} = currentOrder || '';
  const [options, setOptions] = useState({});
  const [stateLocal, setStateLocal] = useState();
  const [numberState, setNumberState] = useState(null);
  const [localCurrentOrder, setLocalCurrentOrder] = useState();

  const handleGetSetupIntent = () => {
    getSetupIntentId(
      {user: getCurrentUser()['Access-Token']},
      ({client_secret, customer}) => {
        if (client_secret) {
          setOptions({clientSecret: client_secret, customer});
        }
      }
    );
  };

  useEffect(() => {
    if (!options.clientSecret) {
      handleGetSetupIntent();
    }
  }, []);

  useEffect(() => {
    state === 'cart' && navigate('/cart');
    fetchCurrentOrder();
  }, [state]);

  useEffect(() => {
    if (state !== 'cart' && state !== 'confirm') {
      setStateLocal(state);
    }
  }, []);
  useEffect(() => {
    if (state !== 'cart' && state !== 'confirm') {
      setStateLocal(state);
    }
  }, [currentOrder]);
  useEffect(() => {
    stateLocal;
  }, [state]);

  const clearThisOrder = () => {
    clearOrder(
      {
        user: getCurrentUser()['Access-Token'],
        orderNumber: getOrder(),
      },
      () => {
        orderUpdate(
          {
            user: getCurrentUser()['Access-Token'],
            orderNumber: getOrder(),
            state: 'cart',
          },
          () => {
            fetchCurrentOrder();
            navigate('/');
          }
        );
      }
    );
  };

  const handlePrevious = (value, number) => {
    !numberState && setNumberState(number);
    if (number < numberState && stateLocal !== 'confirm') {
      setStateLocal(value);
      setNumberState(number);
    }
  };

  return (
    <Container>
      <CheckoutPageStyle>
        <Row className="head-menu-checkout" xs="4" lg="12">
          <Visible xs>
            <Heading level="4" as="h4">
              Checkout
            </Heading>
          </Visible>
          <Col className="menu-checkout" xs="2.6" lg="8">
            <Hidden xs>
              <Heading level="4" as="h4">
                Checkout
              </Heading>
            </Hidden>
            <div onClick={() => handlePrevious('address', 0)}>
              <Text
                level="6"
                as="h2"
                fontWeight={stateLocal === 'address' ? 800 : 400}
              >
                Address
              </Text>
            </div>
            <div onClick={() => handlePrevious('payment', 1)}>
              <Text
                level="6"
                as="h2"
                fontWeight={stateLocal === 'payment' ? 800 : 400}
              >
                Payment
              </Text>
            </div>
            <div onClick={() => handlePrevious('pending', 2)}>
              <Text
                level="6"
                as="h2"
                fontWeight={stateLocal === 'pending' ? 800 : 400}
              >
                confirm
              </Text>
            </div>
            <div>
              <Text
                level="6"
                as="h2"
                fontWeight={stateLocal === 'complete' ? 800 : 400}
              >
                complete
              </Text>
            </div>
          </Col>
          <Col
            xs="0.8"
            lg="2"
            justify="center"
            className="menu-checkout"
            onClick={() => clearThisOrder()}
          >
            {stateLocal !== 'complete' && (
              <Link level="6" fontWeight={800}>
                Cancel Order
              </Link>
            )}
          </Col>
        </Row>
        {stateLocal === 'address' && (
          <Address setNumberState={setNumberState} />
        )}
        {stateLocal === 'payment' && options.clientSecret && (
          <Elements stripe={stripePromise} options={options}>
            <Payments
              currentOrder={currentOrder}
              options={options}
              setNumberState={setNumberState}
            />
          </Elements>
        )}
        {stateLocal === 'pending' && (
          <Confirm
            setStateLocal={setStateLocal}
            setLocalCurrentOrder={setLocalCurrentOrder}
            setNumberState={setNumberState}
          />
        )}
        {stateLocal === 'complete' && (
          <Complete localCurrentOrder={localCurrentOrder} />
        )}
      </CheckoutPageStyle>
    </Container>
  );
};

export default CheckoutPage;
