import React from 'react';
import HeaderPrivacyPolicy from '../../HeaderPrivacyPolicy';
import {Text, Heading} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';
import {StyledPrivacyPolicy} from './styles';

const PrivacyPolicyPage = () => {
  return (
    <>
      <HeaderPrivacyPolicy />
      <StyledPrivacyPolicy>
        <Container>
          <Col>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text level="4">
                  This privacy statement covers the site MARKET.MAKERSSHOES.COM
                  . This policy statement strives to explain the following
                  issues:
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <ol>
                    <li>
                      What personally identifiable information
                      MARKET.MAKERSSHOES.COM collects.
                    </li>
                    <li>
                      What personally identifiable information third parties
                      collect through the website.
                    </li>
                    <li>What organization collects the information.</li>
                    <li>How MARKET.MAKERSSHOES.COM uses the information.</li>
                    <li>
                      With whom MARKET.MAKERSSHOES.COM may share user
                      information.
                    </li>
                    <li>
                      What choices are available to users regarding collection,
                      use and distribution of the information.
                    </li>
                    <li>
                      What types of security procedures are in place to protect
                      the loss, misuse or alteration of information under
                      MARKET.MAKERSSHOES.COM control.
                    </li>
                    <li>
                      How users can correct any inaccuracies in the information.
                    </li>
                    <li>What organization collects the information.</li>
                  </ol>
                </Text>
                <Text>
                  If users have questions or concerns regarding this statement,
                  they should first contact us by email at
                  CONTACT@MAKERSSHOES.COM and then by phone at +1 (305)
                  576-1195.
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h3" level="4">
                  INFORMATION COLLECTION AND USE
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  INFORMATION COLLECTION
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    The ownership of user information collected in is twofold.
                    Primarily, MARKET.MAKERSSHOES.COM owns all the information
                    collected on the site. However, as and when a user requests
                    access to a particular store, then that store also owns that
                    user&apos;s information. MARKET.MAKERSSHOES.COM information
                    from our users at several different points on our website.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  REGISTRATION
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  In order to use this website, a user must first complete the
                  registration form. During registration a user is required to
                  provide contact information (such as name and email address).
                  We use this information to contact the user throughout the
                  duration of the business transaction and to inform them about
                  new products and services on our site for which he/she has
                  expressed interest. It is also required for the user to
                  provide unique identifiers (such as, username and password) in
                  order to facilitate the security concerns of the individual
                  boutique owners.
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  ORDER
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  We request information from the user on our checkout form. A
                  user must provide contact information (such as name, email,
                  and shipping address) and financial information (such as
                  method of payment, credit card number, expiration date). This
                  information is used solely for billing purposes and to fill
                  customers&apos; orders. If we have trouble processing an
                  order, the information is used to contact the user.
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  INFORMATION USE
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    MARKET.MAKERSSHOES.COM is an online marketplace of apparel
                    manufacturers and wholesalers. The information collected at
                    the time of registration and checkout are used to carry out
                    the primary task of facilitating a sale from the apparel
                    manufacturer/wholesaler to the customer. As soon as a user
                    registers with a store or requests access to a store,
                    his/her registration information is sent to that particular
                    store. The store representative then can either immediately
                    grant access to the user or contact the user for further
                    questions/clarifications before granting access. The user
                    can view and purchase clothing from those stores where
                    he/she has been granted access. However, the right to cancel
                    or grant access to any users is at the sole discretion of
                    the store representative. Once granted access, the user will
                    be able to access the store using his/her username and
                    password (login information). It is the responsibility of
                    the user and of the store representative to maintain the
                    secrecy of the username and password. Any fraudulent use of
                    login information will lead to legal action or at the very
                    least cancellation of access. The website also keeps track
                    of user statistics such as: date of registration, date of
                    last visit, the number of times visited, etc. This
                    information is used for aggregate analysis and to make
                    business decisions. Again, the individual stores have access
                    to this user information only if those users have requested
                    access from them.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  The user&apos;s contact information is used for the following
                  purposes:
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <ol>
                    <li>User identity verification</li>
                    <li>Communicate new products and services</li>
                    <li>
                      Communication website information (Maintenance shutdowns,
                      new store launches, store removals, etc.)
                    </li>
                    <li>
                      Communication during/throughout the business transaction
                    </li>
                  </ol>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  The information collected at the time of checkout is used for
                  the following purposes:
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    <ol>
                      <li>
                        Track order status during/throughout the business
                        transaction
                      </li>
                      <li>Maintain order history</li>
                      <li>Facilitate billing and shipping</li>
                    </ol>
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  MARKET.MAKERSSHOES.COM reserves the right to share the
                  registration information with any third party that we deem is
                  a business associate. This will only be done as a business
                  enhancement exercise.
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  PROFILE
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    We store information that we collect through the
                    registration page, cookies, etc. to create a profile of our
                    users. A profile is stored information that we keep on
                    individual users which details their viewing
                    preferences/habits. Consequently, collected information is
                    tied to the users&apos; personally identifiable information
                    to provide offers and improve the content of the site for
                    the user. This profile is used to direct pertinent marketing
                    promotions to them. We sometimes share your profile with
                    other third parties, as deemed applicable for pertinent
                    business decisions.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  COOKIES
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    A cookie is a piece of data stored on the user&apos;s
                    computer tied to information about the user. We use session
                    ID cookies only. This cookie will help the system in
                    recognizing that a particular user is logged in and allow
                    him/her to access all the stores that he/she has access to.
                    Session ID cookies are designed in such a way that once a
                    user closes the browser, the cookie simply terminates.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  LOG FILES
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    Like most standard websites our servers use log files. These
                    logs include internet protocol (IP) addresses, browser type,
                    internet service provider (ISP), referring/exit pages,
                    platform type, date/time stamp, and number of clicks to
                    analyze trends, administer the site, track users&apos;
                    movement in the aggregate, and gather broad demographic
                    information for aggregate use. IP addresses and other
                    collected data are not linked to personally identifiable
                    information.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h3" level="4">
                  COMMUNICATIONS FROM THE SITE
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  SPECIAL OFFERS AND UPDATES
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    We send all new members a welcoming email to verify their
                    password and username. Established members will occasionally
                    receive information on products, services, special deals,
                    and a newsletter. Out of respect for the privacy of our
                    users we present the option to not receive these types of
                    communications in the emails themselves. Please see the
                    Choice and Opt-out sections.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>SERVICE ANNOUNCEMENTS AND CUSTOMER SERVICE</Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    On rare occasions it is necessary to send out a strictly
                    service related announcement. For instance, if our service
                    is temporarily suspended for maintenance we might send users
                    an email. Generally, users may not opt-out of these
                    communications, although they can deactivate their account
                    to refrain from receiving further emails. These
                    communications are not promotional in nature, and are
                    provided as a service to inform users of periods of possible
                    interrupted service.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h3" level="4">
                  SHARING
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  LEGAL DISCLAIMER
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    Although we make every effort to preserve user privacy, we
                    may need to disclose personal information when required by
                    law wherein we have a good-faith belief that such action is
                    necessary to comply with a current judicial proceeding, a
                    court order or legal process served on our website.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h3" level="4">
                  AGGREGATE INFORMATION (NON-PERSONALLY IDENTIFIABLE)
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    We share aggregated demographic information with our
                    partners and advertisers. This is not linked to any
                    personally identifiable information.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  THIRD PARTY INTERMEDIARIES
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    We use an outside shipping company to ship orders, and a
                    credit card processing company to bill users for goods and
                    services. These companies do not retain, share, store or use
                    personally identifiable information for any secondary
                    purposes.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  BUSINESS TRANSITIONS
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    We use an outside shipping company to ship orders, and a
                    credit card processing company to bill users for goods and
                    services. These companies do not retain, share, store or use
                    personally identifiable information for any secondary
                    purposes.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  LINKS
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    This website contains links to other sites. Please be aware
                    that we, MARKET.MAKERSSHOES.COM , are not responsible for
                    the privacy practices of such other sites. We encourage our
                    users to be aware when leaving our site and to read the
                    privacy statements of each and every website that collects
                    personally identifiable information. This privacy statement
                    applies solely to information collected by this website.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  SECURITY
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    This website takes every precaution to protect our
                    users&apos; information. When users submit sensitive
                    information via the website, their information is protected
                    both online and offline. We do everything in our power to
                    protect our users&apos; information. All of our users&apos;
                    information, not just the sensitive information mentioned
                    above, is restricted within our offices. Only employees who
                    need the information to perform a specific job function (for
                    example, our billing clerk or a customer service
                    representative) are granted access to personally
                    identifiable information. Our employees must use
                    password-protected screen-savers when they leave their desks
                    to prevent unauthorized access. When they return, they must
                    re-enter their password to re-gain access to their
                    workstation, and in turn user information. Furthermore, ALL
                    employees are kept up-to-date on our security and privacy
                    practices. Every quarter, as well as any time new policies
                    are added, our employees are notified and/or reminded about
                    the importance we place on privacy, and what they must do to
                    ensure our users&apos; information is protected. If users
                    have any questions about the security at our website, users
                    can send an email to CONTACT@MAKERSSHOES.COM.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  CORRECTING/UPDATING/DELETING/DEACTIVATING PERSONAL INFORMATION
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    If a user&apos;s personally identifiable information changes
                    (such as zip code, phone number, email or postal address),
                    or if a user no longer desires our service, we provide a way
                    to correct, update or delete/deactivate users&apos;
                    personally identifiable information. This can be done at the
                    My Account page available at the top of each store
                    MARKET.MAKERSSHOES.COM . The changes made in My Account
                    section of any store will reflect in all the store accounts
                    of the user.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  NOTIFICATION OF CHANGES
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    If we decide to change our privacy policy, we will post
                    those changes to this privacy statement, the homepage, and
                    other places we deem appropriate so our users are always
                    aware of what information we collect, how we use it, and
                    under what circumstances, if any, we disclose it. We will
                    use information in accordance with the privacy policy under
                    which the information was collected.
                  </p>
                </Text>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Heading as="h4" level="4">
                  CONTACT INFORMATION
                </Heading>
              </Row>
            </div>
            <div className="container-row">
              <Row xs={4} sm={6} md={8} lg={12}>
                <Text>
                  <p>
                    If users have any questions or suggestions regarding our
                    privacy policy, please contact us at: WWW.MAKERSSHOES.COM
                  </p>
                </Text>
              </Row>
            </div>
          </Col>
        </Container>
      </StyledPrivacyPolicy>
    </>
  );
};

export default PrivacyPolicyPage;
