import * as React from 'react';
import {Hidden, Row} from 'react-awesome-styled-grid';

import {Heading, Text} from 'styled-typography';
import useImgixImage from '../../hooks/useImgixImage';
import {HeaderWeAreMakersStyle} from './styles';

const HeaderWeAreMakers = () => {
  const collageImage = useImgixImage('/images/collage-2.png', {
    w: 930,
    h: 736,
  });
  const collageImageXS = useImgixImage('/images/collage-2.png', {
    w: 630,
    h: 336,
  });

  return (
    <HeaderWeAreMakersStyle>
      <div className="ContainerText">
        <div className="Line" />
        <Text level="6" as="h1">
          we are maker’s
        </Text>
        <div className="Line" />
      </div>
      <div className="ContainerHeading">
        <Heading level="2" as="h2">
          We are known for our production speed, care, and style!
        </Heading>
      </div>
      <Hidden xs>
        <div className="ContainerImage">
          <img src={collageImage}></img>
        </div>
      </Hidden>
      <Hidden sm md lg xl>
        <div className="ContainerImage">
          <img src={collageImageXS}></img>
        </div>
      </Hidden>
      <Row>
        <span>Maker’s Shoes was founded in 2005, based in Miami.</span>
      </Row>
      <div className="ContainerInformation">
        <Row>
          <Text level="3" as="h3">
            Creating women’s footwear that produces not only confidence, but a
            kick of fashion in their everyday lives. Maker’s derived from the
            word <span className="italico">hacedores</span> in Spanish. We
            believe that the greatest gift is in creating.
          </Text>
        </Row>
      </div>
    </HeaderWeAreMakersStyle>
  );
};

export default HeaderWeAreMakers;
