import React from 'react';
import HeaderWeekly from '../../HeaderWeekly';
import Collections from '../../Collections';
import ResultProducts from '../../ResultProducts';

const WeeklyArrivalsPage = () => {
  return (
    <>
      <HeaderWeekly />
      <ResultProducts isWeekly />
      <Collections />
    </>
  );
};

export default WeeklyArrivalsPage;
