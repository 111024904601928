import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';
export const EmptyOrdersStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  ${props => config(props).media['sm']`
    justify-content: flex-start;
    margin-bottom: 50px;
  `}
  .container {
    margin-top: 15px;
    margin-left: -15px;
    ${props => config(props).media['md']`
    margin-left: -35px;
    `}
  }
  h2 {
    text-align: center;
  }
  p {
    text-align: center;
  }
  img {
    width: 30%;
    margin-bottom: 15px;
    ${props => config(props).media['md']`
      width: 20%;
      margin-bottom: 30px;
    `}
  }
  h4 {
    color: ${({theme}) => theme.colors.secondary};
    text-align: center;
  }
  .container-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-button {
    margin-top: 15px;
    margin-bottom: 0px;
    ${props => config(props).media['sm']`
      margin-top: 15px;
      margin-bottom: 20px;
    `}
  }
`;
