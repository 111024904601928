import React from 'react';
import PropTypes from 'prop-types';
import {FontWeight, Heading, Text} from 'styled-typography';

import Order from '../Order';
import {
  getOptionValueByOptionType,
  handleDateByListOrder,
} from '../../utils/productHelper';
import {Col} from 'react-awesome-styled-grid';
import {OrdersStyle, CardCart} from './styles';

const Orders = ({orders}) => {
  return (
    <OrdersStyle>
      <CardCart height="100%" width="100%">
        <div className="container">
          <div className="container-order">
            <Heading level="3" as="h2" fontWeight={(FontWeight.Bold = 600)}>
              Orders
            </Heading>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Col className="info-order-number">
                      <Text
                        level="6"
                        as="h3"
                        fontWeight={(FontWeight.Bold = 600)}
                      >
                        # ORDER
                      </Text>
                    </Col>
                  </td>
                  <td>
                    <Col className="info-product">
                      <Text
                        level="6"
                        as="h3"
                        fontWeight={(FontWeight.Bold = 600)}
                      >
                        PRODUCTS
                      </Text>
                    </Col>
                  </td>
                  <td>
                    <Col className="info-date">
                      <Text
                        level="6"
                        as="h3"
                        fontWeight={(FontWeight.Bold = 600)}
                      >
                        DATE
                      </Text>
                    </Col>
                  </td>
                  <td>
                    <Col className="info-status">
                      <Text
                        level="6"
                        as="h3"
                        fontWeight={(FontWeight.Bold = 600)}
                      >
                        STATUS
                      </Text>
                    </Col>
                  </td>
                  <td>
                    <Col className="info-total">
                      <Text
                        level="6"
                        as="h3"
                        fontWeight={(FontWeight.Bold = 600)}
                      >
                        TOTAL
                      </Text>
                    </Col>
                  </td>
                </tr>
                {orders &&
                  orders?.map(
                    ({
                      id,
                      number,
                      line_items,
                      display_total,
                      state,
                      completed_at,
                      invoice_url,
                    }) => {
                      return (
                        <tr key={id}>
                          <Order
                            order={number}
                            invoice={invoice_url}
                            product={
                              line_items.length !== 0
                                ? line_items.map(
                                    ({
                                      quantity,
                                      id,
                                      variant: {name, option_values},
                                    }) => {
                                      return (
                                        <div key={id}>
                                          {`x${quantity} - ${name}
                                    ${getOptionValueByOptionType(
                                      option_values,
                                      'color',
                                      'presentation'
                                    )}`}
                                        </div>
                                      );
                                    }
                                  )
                                : 'empty'
                            }
                            date={
                              completed_at
                                ? handleDateByListOrder(completed_at)
                                : 'empty'
                            }
                            status={state === 'cart' ? 'incomplete' : state}
                            total={display_total}
                          />
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </CardCart>
    </OrdersStyle>
  );
};

export default Orders;

Orders.propTypes = {
  orders: PropTypes.array,
  isLoading: PropTypes.bool,
};
