import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const PersonalInfoStyle = styled.div`
  display: flex;
  width: 100%;
  padding: 0px;
  margin: 0px;
  margin-bottom: 60px;
  ${props => config(props).media['md']`
    width: 90%;
    margin-bottom: 92px;
  `}

  .container {
    margin: 0px;
    padding: 20px 20px 0px 20px;
    h1 {
      font-size: 30px;
      margin-bottom: 25px;
    }
    h2 {
      font-size: 20px;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .wrapper-info {
      display: flex;
      padding: 0px;
      margin: 0px;
      flex-direction: column;
      .field {
        display: flex;
        justify-content: space-between;
        label {
          width: 48%;
        }
        .input-select {
          display: flex;
          height: 44px;
          width: 100%;
          background-color: white;
          border-radius: 6px;
          outline: none;
          padding: 0 20px;
          font-size: 0.8em;
          border-style: solid;
          border-color: ${({theme}) => theme.colors.secondary2};
          border-width: 1.5px;
          margin-top: 10px;
          margin-bottom: 28px;
          :focus {
            outline: none;
            border: solid 1px ${({theme}) => theme.colors.primary2};
          }
        }
      }
    }
  }
  .container-button {
    display: flex;
    justify-content: center;
    width: 100%;
    ${props => config(props).media['sm']`
      justify-content: end;
    `}
    button {
      margin-right: 0px;
      ${props => config(props).media['sm']`
        margin-right: 20px;
      `}
    }
  }
  span {
    color: ${({theme}) => theme.colors.primary2};
  }
`;

export const OrdersStyle = styled.div`
  width: 100%;
  margin: 0px;
  margin-bottom: 60px;
  ${props => config(props).media['md']`
    width: 90%;
    margin-bottom: 92px;
  `}
  .container {
    padding: 10px 20px 10px 20px;
    a {
      font-size: 12px;
      margin-bottom: 8px;
      align-items: start;
      font-weight: bold;
      text-decoration-line: underline;
      cursor: pointer;
      color: ${({theme}) => theme.colors.primary2};
      :hover {
        color: ${({theme}) => theme.colors.primary3};
      }
    }
    .container-order {
      h2 {
        margin-bottom: 25px;
      }
      h3 {
        text-align: start;
        margin: 0px;
        width: 100%;
        color: ${({theme}) => theme.colors.secondary};
      }
      h5 {
        text-align: start;
        margin-top: 15px;
        margin-bottom: 8px;
        margin-left: 0px;
      }
      table {
        border-collapse: collapse;
        width: 95%;
        ${props => config(props).media['sm']`
          width: 100%;
        `}
        tr {
          border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
          :last-child {
            border-bottom: none;
            span {
              margin-bottom: 0px;
            }
          }
          display: flex;
          flex-direction: row;
          td {
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .info-order-number {
              width: 100%;
              ${props => config(props).media['sm']`
                width: 100%;
              `}
              h5 {
              }
            }
            .info-product {
              width: 100%;
              min-width: 200px;
              ${props => config(props).media['sm']`
              width: 100%;
              `}
            }
            .info-date {
              min-width: 120px;
              max-width: 120px;
            }
            .info-status {
              max-width: 100px;
              width: 80%;
              ${props => config(props).media['sm']`
                width: 100%;
              `}
            }
            .info-total {
              max-width: 100px;
              width: 80%;
              ${props => config(props).media['sm']`
                width: 100%;
              `}
            }
          }
        }
      }
    }
  }
`;

export const OrdersStyleSkeleton = styled.div`
  width: 100%;
  margin: 0px;
  margin-bottom: 60px;
  ${props => config(props).media['md']`
    width: 90%;
    margin-bottom: 92px;
  `}
  .container {
    padding: 10px 20px 10px 20px;
    .container-order {
      h3 {
        margin-left: 5px;
        margin-top: 0;
        color: ${({theme}) => theme.colors.secondary};
      }
      span {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        tr {
          border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
          height: 26.75px;
          :last-child {
            border-bottom: none;
          }
          td {
            width: 138.797px;
            height: 52.75px;
            .container-info {
              padding-bottom: 26px;
            }
          }
        }
      }
      h2 {
        margin-right: 20px;
      }
    }
    h5 {
      margin-left: 0px;
      margin-bottom: 8px;
    }
  }
  .skeletonResultProductStyle {
    width: 150px;
    height: 170px;
    border-radius: 20px;
    align-items: center;
    ${props => config(props).media['md']`
      width: 207px;
      height: 207px;
    `}
    ${props => config(props).media['lg']`
      width: 307px;
      height: 307px;
      margin-left: 30px;
      margin-top: 30px;
    `}
  }
  .skeletonStyle {
    margin-left: 25px;
    border-radius: 10%;
    :last-child {
      margin-right: 35px;
    }
  }
`;

export const AddressesStyle = styled.div`
  width: 100%;
  margin: 0px;
  margin-bottom: 60px;
  ${props => config(props).media['md']`
    width: 90%;
    margin-bottom: 92px;
    `}
  .container {
    padding: 10px 20px 10px 20px;
    h2 {
      margin-bottom: 25px;
    }
    h3 {
      width: 100%;
      margin: 0px;
      padding: 0px;
      text-align: start;
      color: ${({theme}) => theme.colors.secondary};
    }
    h5 {
      text-align: start;
      margin-top: 15px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
    a {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
      align-items: start;
      font-weight: bold;
      text-decoration-line: underline;
      cursor: pointer;
      color: ${({theme}) => theme.colors.primary2};
      :hover {
        color: ${({theme}) => theme.colors.primary3};
      }
    }
    .container-order {
      table {
        border-collapse: collapse;
        width: 95%;
        ${props => config(props).media['sm']`
          width: 100%;
          `}
        tr {
          border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
          :first-child {
          }
          :last-child {
            border-bottom: none;
          }
          td {
            width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-left: 20px;
            .container-info {
              padding-bottom: 10px;
            }
          }
        }
      }
      .wrapper-button {
        display: flex;
        width: 80px;
        div {
          margin: 0 auto;
          button {
            margin: auto;
            :hover {
              background-color: ${({theme}) => theme.colors.primary2};
              border: 1px solid white;
            }
          }
        }
      }
    }
  }
`;

export const CardCart = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: column;
  width: ${({width}) => width};
  height: ${({height}) => height};
  background-color: white;
  border-radius: 20px;
  margin-bottom: 20px;
  margin-right: 55px;
  ${props => config(props).media['md']`
          margin-right: 0px;
  `}
  h5 {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  .span-discount {
    color: ${({theme}) => theme.colors.primary2};
    margin-left: 3px;
  }
  h3 {
    margin-left: 20px;
    color: ${({theme}) => theme.colors.secondary2};
    margin-top: 20px;
  }
  h4 {
    margin-left: 20px;
    margin-bottom: 15px;
  }
  h6 {
    margin-left: 30px;
    margin-bottom: 15px;
    color: ${({theme}) => theme.colors.secondary};
  }
  h1 {
    padding-left: 10px !important;
    margin-left: 20px;
    ${props => config(props).media['md']`
    margin-left: 0px;
    margin-right: 0px;
    `}
  }
  .containter-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${({theme}) => theme.colors.secondary2};
    padding-top: 15px;
    h6 {
      margin-left: 0px !important;
    }
    margin-left: 20px;
    margin-right: 20px;
    ${props => config(props).media['md']`
    margin-left: 0px;
    margin-right: 0px;
    `}
  }
  button {
    margin-left: 20px;
    ${props => config(props).media['sm']`
    margin-left: 0px;
    `}
  }
`;
