import useSWR from 'swr';

import fetcher from './fetcher';
import {countries_url} from './urls';

export const fetchCountries = () => {
  const {data, error} = useSWR(`${countries_url}/?per_page=252/`, fetcher);
  return {
    countries: (data && data.countries) || [],
    error: error,
    isLoading: !data && !error,
  };
};

export const fetchStates = id => {
  const {data, error} = useSWR(`${countries_url}${id}/states`, fetcher);
  return {
    states: (data && data.states) || [],
    error: error,
    isLoading: !data && !error,
  };
};
