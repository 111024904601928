import React from 'react';
import PropTypes from 'prop-types';
import {FontWeight, Text} from 'styled-typography';

import {StyledButtonAllShoes} from './styles';

const ButtonAllShoes = ({children, ...props}) => {
  return (
    <StyledButtonAllShoes {...props}>
      <Text level="5" fontWeight={FontWeight.Light}>
        {children}
      </Text>
    </StyledButtonAllShoes>
  );
};

ButtonAllShoes.propTypes = {
  children: PropTypes.node,
};

ButtonAllShoes.defaultProps = {
  height: '40px',
};

export default ButtonAllShoes;
