import styled from 'styled-components';

export const HeaderSearchStyle = styled.div`
  display: flex;
  width: 100%;
  height: 256px;
  background-color: white;
  justify-content: center;
  flex-direction: column;
  h1 {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.secondary};
    font-weight: bold;
    margin-left: 60px;
  }
  h2 {
    color: ${({theme}) => theme.colors.primary2};
    margin-left: 60px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  h3 {
    margin-left: 60px;
  }
`;
