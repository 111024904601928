import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';

import {MultiSelectStyle} from './styles';
import DropdowOption from './DropdownOption';
import {MultiSelect} from 'react-multi-select-component';

const DropdownMultiSelect = ({
  options,
  label,
  selected: defaultSelected,
  onChange,
  ...props
}) => {
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    if (defaultSelected != selected) {
      onChange(label, selected);
    }
  }, [selected]);

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  return (
    <div className="pretty p-default p-curve p-thick">
      <MultiSelectStyle {...props}>
        <MultiSelect
          hasSelectAll={false}
          options={[...options, {type: 'button', setSelected, label}]}
          ItemRenderer={DropdowOption}
          disableSearch={true}
          value={selected}
          onChange={setSelected}
          ClearSelectedIcon={null}
          labelledBy="Select"
          disabled={false}
          valueRenderer={() => label}
        />
      </MultiSelectStyle>
    </div>
  );
};

DropdownMultiSelect.defaultProps = {
  options: [],
  width: '218px',
  selected: [],
};

DropdownMultiSelect.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  selected: PropTypes.func,
  onChange: PropTypes.func,
};

export default DropdownMultiSelect;
