import React from 'react';
import PropTypes from 'prop-types';
import {Col} from 'react-awesome-styled-grid';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';

import ButtonProduct from '../core/ButtonProduct';

const CheckoutForm = ({onValueChange}) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = event => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    stripe
      .confirmSetup({
        elements,
        redirect: 'if_required',
      })
      .then(result => {
        result && onValueChange(result.setupIntent);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Col align="center">
        <ButtonProduct width="200px" disabled={!stripe} height="40px">
          Save and Continue
        </ButtonProduct>
      </Col>
    </form>
  );
};

export default CheckoutForm;

CheckoutForm.propTypes = {
  onValueChange: PropTypes.func,
};
