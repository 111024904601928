import React, {useState} from 'react';
import {Container} from 'react-awesome-styled-grid';
import {Text} from 'styled-typography';
import {CardCart} from '../CartPage/styles';
import {ResetPasswordStyle} from './styles';
import InputForm from '../../core/InputForm';
import ButtonProduct from '../../core/ButtonProduct';
import {useQueryParam, StringParam} from 'use-query-params';
import {resetPassword} from '../../../API/makers/users';
import {useAlert} from 'react-alert';

const ResetPassword = () => {
  const [PageQueryPassword] = useQueryParam(
    'reset_password_token',
    StringParam
  );
  const alert = useAlert();

  const passwordDefault = {
    password: '',
    passwordConfirmation: '',
    tokenPassword: PageQueryPassword,
  };

  const [password, setPassword] = useState(passwordDefault);

  const handleValueChange = (key, value) => {
    setPassword({
      ...password,
      [key]: value,
    });
  };

  const handlePassword = e => {
    e.preventDefault();
    if (
      (password.password === '' && password.passwordConfirmation === '') ||
      password.password !== password.passwordConfirmation
    ) {
      alert.error('Passwords must match ');
    } else {
      resetPassword(password),
        alert.success('Password Update'),
        setPassword(passwordDefault);
    }
  };

  return (
    <Container>
      <ResetPasswordStyle>
        <CardCart width="390px" height="350px">
          <form>
            <Text as="h1" level="4" fontWeight={500}>
              Reset Password
            </Text>
            <div className="wrapper-input">
              <Text level="5" fontWeight={400}>
                Password
              </Text>
              <InputForm
                value={password.password}
                type="password"
                onChange={e => handleValueChange('password', e.target.value)}
              />
              <Text level="5" fontWeight={400}>
                Password Confirmation
              </Text>
              <InputForm
                value={password.passwordConfirmation}
                type="password"
                onChange={e =>
                  handleValueChange('passwordConfirmation', e.target.value)
                }
              />
            </div>
            <div className="container-button">
              <ButtonProduct
                width="345px"
                height="50px"
                onClick={handlePassword}
              >
                <Text level="5"> Change Password</Text>
              </ButtonProduct>
            </div>
          </form>
        </CardCart>
      </ResetPasswordStyle>
    </Container>
  );
};
export default ResetPassword;
