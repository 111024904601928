import React from 'react';

import Collections from '../../Collections';
import ResultProducts from '../../ResultProducts';
import HeaderAllShoes from '../../HeaderAllShoes/index';

const AllShoesPage = () => {
  return (
    <>
      <HeaderAllShoes />
      <ResultProducts />
      <Collections />
    </>
  );
};

export default AllShoesPage;
