import React from 'react';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import {CompleteStyle} from './styles';
import ButtonProduct from '../core/ButtonProduct';

const Complete = ({localCurrentOrder}) => {
  const {number} = localCurrentOrder;

  return (
    <Container>
      <CompleteStyle>
        <Heading level="3" as="h1" justify="start" align="start">
          Thank you.
        </Heading>
        <Text level="4">
          <span>Your order was completed successfully.</span>
        </Text>
        <div className="container-heading"></div>
        <Row justify="center">
          <Col md={8}>
            <Heading level="5" as="h3">
              COMPLETE ORDER # {number}
            </Heading>
          </Col>
        </Row>
        <ButtonProduct
          width="200px"
          height="40px"
          onClick={() => navigate('/')}
        >
          back to Home
        </ButtonProduct>
      </CompleteStyle>
    </Container>
  );
};

export default Complete;

Complete.propTypes = {
  localCurrentOrder: PropTypes.object,
};
