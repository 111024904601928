import {Container, config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const HeaderWeeklyStyled = styled.div`
  background-color: white;
  padding-bottom: 30px;
  ${Container} {
    display: flex;
    flex-direction: column;
    height: 128px;
    justify-content: flex-start;
    align-items: flex-start;
    ${props => config(props).media['sm']`
    padding-left: 49px;

  `}
    h1 {
      width: 100%;
      margin-bottom: 25px;
      margin-top: 45px;
      padding-left: 49px;
      text-transform: capitalize;
      ${props => config(props).media['sm']`
      padding-left: 0px;
    `}
    }
    h2 {
      width: 75%;
      margin-bottom: 25px;
      ${props => config(props).media['sm']`
      width: 55%;
     
    
  `}
    }
    h3 {
      color: black;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      margin-left: 15px;
      margin-right: 15px;
      :active {
        color: white;
      }
    }
  }
  .skeletonStyle {
    margin-left: 40px;
    border-radius: 10%;
    :first-child {
      margin-left: 0px;
    }
  }
`;
