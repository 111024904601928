import React from 'react';
import PropTypes from 'prop-types';
import {Col} from 'react-awesome-styled-grid';
import {FontWeight, Heading, Text} from 'styled-typography';

import {EmptyOrdersStyle} from './styles';
import {CardCart} from '../pages/CartPage/styles';
import ButtonProduct from '../core/ButtonProduct';
import useImgixImage from '../../hooks/useImgixImage';
import {navigate} from 'gatsby';

const EmptyOrders = ({title, subtitle, text}) => {
  const logoEmpty = useImgixImage('/images/no-results.png');

  return (
    <EmptyOrdersStyle>
      <CardCart height="100%" width="80%">
        <Col xs="4" md={8} className="container">
          <Heading level="3" as="h2" fontWeight={(FontWeight.Bold = 600)}>
            {title}
          </Heading>
        </Col>
        <Col xs="4" md="8" className="container-image" align="center">
          <img src={logoEmpty} />
        </Col>
        <Col xs="4" md={8} align="center">
          <Text className="title1-normal" fontWeight={(FontWeight.Bold = 600)}>
            {subtitle}
          </Text>
        </Col>
        <Col xs="4" md={8} align="center">
          <Text level="5" as="h4" fontWeight={(FontWeight.Bold = 400)}>
            {text}
          </Text>
        </Col>
        <Col xs="4" md={8} align="center">
          <div className="container-button">
            <ButtonProduct
              width="131px"
              height="30px"
              onClick={() => navigate('/all-shoes')}
            >
              GO TO SHOP
            </ButtonProduct>
          </div>
        </Col>
      </CardCart>
    </EmptyOrdersStyle>
  );
};

export default EmptyOrders;

EmptyOrders.defaultProps = {
  text: 'The orders you make in our store will appear here.',
  subtitle: 'You have not placed orders',
};

EmptyOrders.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};
