import {post} from './fetcher';
import {contact_url} from './urls';

const registerContact = async (contactDefault, callback = () => {}) => {
  const {nameContact, companyOrStore, emailContact, phoneNumber, subject} =
    contactDefault;

  const response = await post(contact_url, {
    body: JSON.stringify({
      contact_us: {
        name: nameContact,
        company_or_store: companyOrStore,
        email: emailContact,
        phone_number: phoneNumber,
        subject: subject,
      },
    }),
  });
  if (!response.ok) {
    return;
  } else if (response.ok) {
    callback();
  }
};
export default registerContact;
