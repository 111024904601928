import React from 'react';
import PropTypes from 'prop-types';

import {useGlobalContext} from '../context';
import {isLoggedIn} from '../services/authentication';

const PrivateRoute = ({component: Component, ...rest}) => {
  const {setModalIsOpen} = useGlobalContext();

  if (!isLoggedIn()) {
    setModalIsOpen(true);
    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.node,
  path: PropTypes.string,
};
