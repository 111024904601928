import React, {useCallback, useState} from 'react';
import registerContact from '../../../API/makers/registerContact';
import {contactSchema} from '../../../formSchema/validator';
import LetsBePartnersInfo from '../../LetsBePartnersInfo';
import {useAlert} from 'react-alert';

const LetsBePartnersPage = () => {
  const contactDefault = {
    nameContact: '',
    companyOrStore: '',
    emailContact: '',
    phoneNumber: '',
    subject: '',
  };
  const [contact, setContact] = useState(contactDefault);
  const [errors, setErrors] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const alert = useAlert();

  const handleInputChange = (key, defaultValue = undefined) => {
    return event => {
      setContact({
        ...contact,
        [key]: defaultValue || event.target.value,
      });
    };
  };
  const handleRegister = () => {
    if (validate()) {
      contact && registerContact(contact);
      setContact(contactDefault);
      setValidForm(false);
      alert.success('information sent');
    }
  };

  const validate = useCallback(() => {
    const schema = contactSchema;
    const errors =
      schema.validate(contact, {
        allowUnknown: true,
        abortEarly: false,
      })?.error?.details || [];
    const isValid = !errors.length;
    setValidForm(isValid);
    setErrors(errors);
    return isValid;
  }, [contact]);
  return (
    <LetsBePartnersInfo
      onRegister={handleRegister}
      contact={contact}
      validForm={validForm}
      errors={errors}
      onInputChange={handleInputChange}
    />
  );
};

export default LetsBePartnersPage;
