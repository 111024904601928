import {rawPost} from './fetcher';
import {register_url} from './urls';

const registerUser = async (register, callback = () => {}) => {
  const response = await rawPost(register_url, {
    body: register,
  });
  if (!response.ok) {
    const error = await response.json();
    throw error;
  } else if (response.ok) {
    callback();
  }
};

export default registerUser;
