import {Link} from 'gatsby';
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import {Col, Container} from 'react-awesome-styled-grid';

import {StyledSlider} from './styles';
import CardCategory from '../core/CardCategory';
import shoes2 from '../../images/Banners/wedges.png';
import useImgixImage from '../../hooks/useImgixImage';
import {generateImageUrl} from '../../utils/productHelper';

const SliderCategory = ({taxons, images, onFilterStyle}) => {
  const imgEmpty = useImgixImage('/images/no-image.png');
  const settings = {
    className: 'center',
    dots: false,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1650,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: false,
    arrows: false,
  };

  return (
    <Container>
      <StyledSlider taxonsTag={taxons}>
        <link
          rel="stylesheet"
          type="text/css"
          // charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <Slider {...settings}>
          {taxons && (
            <Col align="center" justify="center">
              <Link to={'/all-shoes'} onClick={() => onFilterStyle('ALL')}>
                <CardCategory background={shoes2} name="All shoes" />
              </Link>
            </Col>
          )}

          {taxons
            ? taxons.map(taxon => (
                <Col align="center" justify="center" key={taxon.id}>
                  <Link
                    to={`/all-shoes?style=${taxon.id},${taxon.name}`}
                    onClick={() => onFilterStyle(taxon)}
                    // className="LinkMenu"
                  >
                    <CardCategory
                      background={
                        taxon.image_url
                          ? generateImageUrl(taxon.image_url)
                          : imgEmpty
                      }
                      name={taxon.name}
                    />
                  </Link>
                </Col>
              ))
            : images.map((image, index) => (
                <Col
                  className="slider-category-lookbook"
                  align="center"
                  justify="center"
                  key={index}
                >
                  <Link>
                    <CardCategory background={image} />
                  </Link>
                </Col>
              ))}
        </Slider>
      </StyledSlider>
    </Container>
  );
};

export default SliderCategory;

SliderCategory.propTypes = {
  taxons: PropTypes.object,
  images: PropTypes.object,
  onFilterStyle: PropTypes.func,
};
