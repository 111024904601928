import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const HeaderAllShoesStyled = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 0 16px;
  padding-right: 0px;
  ${props => config(props).media['sm']`
    padding: 0px 48px;
  `}
  div {
    padding-left: 0px;
  }
  h1 {
    width: 100%;
    margin-bottom: 25px;
    margin-top: 45px;
    padding-left: 8px;
    text-transform: capitalize;
    ${props => config(props).media['sm']`
      padding-left: 0px;
    `}
  }
  p {
    color: black;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 8px;
    margin-right: 8px;
    ${props => config(props).media['sm']`
      font-size: 13px;
      margin-left: 15px;
      margin-right: 15px;
    `}
    :active {
      color: white;
    }
  }
  .skeletonStyle {
    margin-left: 40px;
    border-radius: 10%;
    :first-child {
      margin-left: 0px;
    }
  }
  .wrap__categories {
    width: 100%;
    .btn-categories {
      margin: 0;
      margin-right: 16px;
      padding: 0;
      ${props => config(props).media['sm']`
        flex-basis: 10%;
      `}

      button {
        width: 100%;
        min-width: 100px;
        max-width: 100px;
        ${props => config(props).media['sm']`
          min-width: 140px;
          max-width: 140px;
      `}
      }
    }
  }
`;
