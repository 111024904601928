import styled from 'styled-components';

export const HeaderRegisterStyle = styled.div`
  display: flex;

  background-color: white;
  h1 {
    margin-top: 60px;
    font-weight: 500;
  }
  h3 {
    color: ${({theme}) => theme.colors.secondary};
    text-align: center;
    margin-top: 20px;
  }
`;
export const PaginationRegister = styled.button`
  width: 35px;
  height: 35px;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 1px solid #d7d9dc;
  cursor: pointer;
  border-radius: 100%;
  :first-child {
    margin-right: 17px;
  }
  :hover {
    border: 1px solid ${({theme}) => theme.colors.secondary2};
  }
  :disabled {
    cursor: default;
  }

  ${({active, theme}) =>
    active &&
    `
    background-color: ${theme.colors.primary2};
    border-color: ${theme.colors.primary2};
    color: white;
    h1 {
      color: white;
    }
  `}
`;
