import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const AccountStyle = styled.div`
  .wrap_menu_acount {
    margin: 0;
    ${props => config(props).media['md']`
      margin 0 auto;
    `}
  }
  .links-routes {
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: 10px;
    ${props => config(props).media['sm']`
      margin-left: 0px;
    `}
    h4 {
      padding: 0px;
      margin: 0px;
      color: ${({theme}) => theme.colors.secondary2};
    }
    h5 {
      color: black;
    }
  }
`;
