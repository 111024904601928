import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {FontWeight, Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import FormAddress from './FormAddress';
import {useGlobalContext} from '../../context';
import OrderSummary from '../Cart/OrderSummary';
import {getAddress} from '../../API/makers/users';
import {CardCart} from '../pages/CartPage/styles';
import ButtonProduct from '../core/ButtonProduct';
import {getOrder} from '../../services/temporalOrder';
import PersonalInformation from './PersonalInformation';
import {InfoAddressStyle, InfoCheckStyle} from './styles';
import {getCurrentUser} from '../../services/authentication';
import {checkoutAddress, nextStepOrder} from '../../API/makers/orders';

const Address = ({setNumberState}) => {
  const {currentOrder, fetchCurrentOrder, disableBtn, setDisableBtn} =
    useGlobalContext();
  const {item_total, total} = currentOrder && currentOrder;
  const infoAddressDefault = {
    use_billing: true,
    bill_address_attributes: {
      id: '',
      name: '',
      address1: '',
      city: '',
      country_id: process.env['GATSBY_DEFAULT_COUNTRY_ID'],
      state_id: '',
      zipcode: '',
      phone: '',
      company: '',
    },
    ship_address_attributes: {
      id_ship: '',
      name_ship: '',
      address1_ship: '',
      city_ship: '',
      country_id_ship: process.env['GATSBY_DEFAULT_COUNTRY_ID'],
      state_id_ship: '',
      zipcode_ship: '',
      phone_ship: '',
      company_ship: '',
    },
  };
  const [infoAddress, setInfoAddress] = useState(infoAddressDefault);
  const {use_billing} = infoAddress;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [listAddress, setListAddress] = useState('');

  const handleValueChange = key => {
    return () => {
      setInfoAddress({
        ...infoAddress,
        [key]: !infoAddress[key],
      });
    };
  };

  const handleSendCheckoutAddress = () => {
    checkoutAddress(
      {
        user: getCurrentUser()['Access-Token'],
        orderNumber: getOrder(),
        infoAddress,
      },
      () => {
        nextStepOrder(
          {
            user: getCurrentUser()['Access-Token'],
            orderNumber: getOrder(),
          },
          () => {
            fetchCurrentOrder();
          }
        );
        setDisableBtn(true);
      }
    );
  };

  const handleGetListAddress = id => {
    getAddress(
      {user: getCurrentUser()['Access-Token'], idUser: id},
      getAddress => setListAddress(getAddress)
    ).catch(error => setError(error));
  };

  const handleChangeAddressOption = (objectKey, key, value) => {
    return () => {
      setInfoAddress({
        ...infoAddress,
        [objectKey]: {
          ...infoAddress[objectKey],
          [key]: value,
        },
      });
    };
  };

  useEffect(() => {
    setDisableBtn(false);
    setNumberState(0);
  }, []);

  useEffect(() => {
    !listAddress && handleGetListAddress(getCurrentUser().id);
    listAddress && setIsLoading(false);
    error && setIsLoading(false);
  }, [listAddress, error]);

  const [idAddress, setIdAddress] = useState();

  useEffect(() => {
    if (listAddress) {
      const defaultId = listAddress?.find(item => item.default === true);
      defaultId && setIdAddress(defaultId.id);
      handleChangeAddressOption('bill_address_attributes', 'id', idAddress)();
    }
  }, [listAddress, idAddress]);

  return (
    <Container>
      <Row justify={{xs: 'center', md: 'space-around'}}>
        <Col xs={4} md={3} lg={6.5} xl={6} align={{xs: 'center', md: 'start'}}>
          <div className="container-form">
            <Row>
              <Heading level="5" as="h1">
                BILLING ADDRESS
              </Heading>
            </Row>
            <Row>
              {isLoading ? (
                <Text>Loading</Text>
              ) : idAddress ? (
                <InfoAddressStyle>
                  <Row className="wrap-card-address">
                    {listAddress?.map(
                      ({
                        name,
                        address1,
                        city,
                        country,
                        state,
                        phone,
                        id,
                        default: isDefault,
                      }) => (
                        <Col lg="4" className="wrap-info-address" key={id}>
                          <input
                            type="radio"
                            id={id}
                            name="billing"
                            onClick={handleChangeAddressOption(
                              'bill_address_attributes',
                              'id',
                              id
                            )}
                            value={address1}
                            checked={
                              !infoAddress.bill_address_attributes.id
                                ? isDefault
                                : infoAddress.bill_address_attributes.id === id
                                ? true
                                : false
                            }
                          />
                          <PersonalInformation
                            title=""
                            name={name}
                            address={address1}
                            city={`${city} - ${state.name}`}
                            country={`${country.iso3} - ${country.name}`}
                            phone={phone}
                          />
                        </Col>
                      )
                    )}
                    <Col lg="4" className="wrap-info-address">
                      <input
                        type="radio"
                        name="billing"
                        value="addNewBillAddress"
                        onClick={handleChangeAddressOption(
                          'bill_address_attributes',
                          'id',
                          null
                        )}
                        checked={
                          infoAddress.bill_address_attributes.id === null
                        }
                      />
                      <Text
                        lvl="4"
                        as="h2"
                        fontWeight={(FontWeight.Bold = '400')}
                      >
                        Add New Address
                      </Text>
                    </Col>
                  </Row>
                </InfoAddressStyle>
              ) : (
                <FormAddress
                  infoAddress={infoAddress}
                  setInfoAddress={setInfoAddress}
                  typeForm="bill"
                />
              )}
            </Row>
            {infoAddress.bill_address_attributes.id === null && (
              <FormAddress
                infoAddress={infoAddress}
                setInfoAddress={setInfoAddress}
                typeForm="bill"
              />
            )}
            {use_billing === false && (
              <Row>
                <Heading level="5" as="h1">
                  SHIPPING ADDRESS
                </Heading>
              </Row>
            )}
            <Row>
              {use_billing === false &&
                (idAddress ? (
                  <InfoAddressStyle>
                    <Row className="wrap-card-address">
                      {listAddress?.map(
                        ({
                          name,
                          address1,
                          city,
                          country,
                          state,
                          phone,
                          id,
                          default: isDefault,
                        }) => (
                          <Col lg="4" className="wrap-info-address" key={id}>
                            <input
                              type="radio"
                              id={id}
                              name="shipping"
                              onClick={handleChangeAddressOption(
                                'ship_address_attributes',
                                'id',
                                id
                              )}
                              value={address1}
                              checked={
                                !infoAddress
                                  ? isDefault
                                  : infoAddress.ship_address_attributes.id ===
                                    id
                                  ? true
                                  : false
                              }
                            />
                            <PersonalInformation
                              title=""
                              name={name}
                              address={address1}
                              city={`${city} - ${state.name}`}
                              country={`${country.iso3} - ${country.name}`}
                              phone={phone}
                            />
                          </Col>
                        )
                      )}
                      <Col lg="4" className="wrap-info-address">
                        <input
                          type="radio"
                          name="shipping"
                          value="addNewAddress"
                          onClick={handleChangeAddressOption(
                            'ship_address_attributes',
                            'id',
                            null
                          )}
                          checked={
                            !infoAddress
                              ? false
                              : infoAddress.ship_address_attributes.id === null
                              ? true
                              : false
                          }
                        />
                        <Text
                          lvl="4"
                          as="h2"
                          fontWeight={(FontWeight.Bold = '400')}
                        >
                          Add New Address
                        </Text>
                      </Col>
                    </Row>
                  </InfoAddressStyle>
                ) : (
                  <FormAddress
                    infoAddress={infoAddress}
                    setInfoAddress={setInfoAddress}
                    typeForm="ship"
                  />
                ))}
            </Row>
            {infoAddress.ship_address_attributes.id === null && (
              <FormAddress
                infoAddress={infoAddress}
                setInfoAddress={setInfoAddress}
                typeForm="ship"
              />
            )}
            <InfoCheckStyle>
              <label>
                <input
                  type="checkbox"
                  value="use_billing"
                  onClick={handleValueChange('use_billing')}
                  checked={use_billing}
                />
                Use Shipping Address
              </label>
            </InfoCheckStyle>
          </div>
          <div className="wrapper-check">
            <ButtonProduct
              width="200px"
              height="40px"
              onClick={handleSendCheckoutAddress}
              disabled={disableBtn}
            >
              Save and Continue
            </ButtonProduct>
          </div>
        </Col>
        <Col xs={4} md={3} lg={3.5} xl={3.5} align="center">
          <div className="container-order">
            <CardCart height="180px" justify="center" marginTop="90px">
              <OrderSummary
                isCheckout
                description={
                  <>
                    <div className="text-summary">
                      <Text level="5" as="h6">
                        Item Total:
                      </Text>
                    </div>
                    <div className="calculate-summary">
                      <Text level="5" as="h4">
                        {`$ ${item_total}`}
                      </Text>
                    </div>
                  </>
                }
                total={`$ ${total}`}
              />
            </CardCart>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Address;

Address.defaultProps = {
  item_total: 0,
  total: 0,
};

Address.propTypes = {
  setNumberState: PropTypes.number,
};
