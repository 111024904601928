import Joi from 'joi';

export const contactSchema = Joi.object({
  nameContact: Joi.string()
    .required()
    .messages({'string.empty': 'This field is required'}),
  companyOrStore: Joi.string()
    .required()
    .messages({'string.empty': 'This field is required'}),
  emailContact: Joi.string()
    .email({
      tlds: {allow: false},
    })
    .required()
    .messages({
      'string.empty': 'This field is required',
      'string.email':
        'It must be a valid email, enter a 6-12 character password',
    }),
  phoneNumber: Joi.string()
    .pattern(/^[0-9/+]{10}$/)
    .required()
    .messages({
      'string.base':
        'Sorry! It looks like something went wrong. Please try later.',
      'string.pattern.base': 'Phone number must be a 10 digits number.',
      'string.empty': 'Phone Number is not allowed to be empty.',
    }),
  subject: Joi.string()
    .required()
    .messages({'string.empty': 'This field is required'}),
});

export const step1Schema = Joi.object({
  name: Joi.string()
    .required()
    .messages({'string.empty': 'This field is required'}),
  lastName: Joi.string()
    .required()
    .messages({'string.empty': 'This field is required'}),
  phoneNumber: Joi.string()
    .required()
    .pattern(new RegExp('^[0-9/#+*-]'))
    .messages({
      'string.base':
        'Sorry! It looks like something went wrong. Please try later.',
      'string.pattern.base': 'only numbers and signs #, +, -',
      'string.empty': 'Phone Number is not allowed to be empty.',
    }),
  email: Joi.string()
    .email({
      tlds: {allow: false},
    })
    .required()
    .messages({
      'string.empty': 'This field is required',
      'string.email': 'It must be a valid email',
    }),
  password: Joi.string()
    .required()
    .min(6)
    .pattern(new RegExp('^[a-z/A-Z/0-9/#?!@+$%^&*-]'))
    .messages({
      'string.empty': 'This field is required',
      'string.pattern.base': 'this password does not meet the requirements',
      'string.min': 'be at least 6 characters long',
    }),
  confirmPassword: Joi.string()
    .required()
    .valid(Joi.ref('password'))
    .messages({'any.only': 'Passwords do not match'}),
});

export const step2Schema = Joi.object({
  termsConditions: Joi.boolean()
    .required()
    .valid(true)
    .messages({'any.required': 'This field is required'}),
  company: Joi.object({
    companyName: Joi.string()
      .required()
      .messages({'string.empty': 'This field is required'}),
    cellNumber: Joi.string()
      .required()
      .pattern(new RegExp('^[0-9/#+*-]'))
      .messages({
        'string.base':
          'Sorry! It looks like something went wrong. Please try later.',
        'string.pattern.base': 'only numbers and signs #, +, -',
        'string.empty': 'Phone Number is not allowed to be empty.',
      }),
    companyEmail: Joi.string()
      .email({
        tlds: {allow: false},
      })
      .required()
      .messages({
        'string.empty': 'This field is required',
        'string.email': 'Debe ser un email Válido',
      }),
    address: Joi.string()
      .required()
      .messages({'string.empty': 'This field is required'}),
    country: Joi.string().required().messages({
      'string.empty': 'This field is required',
    }),
    state: Joi.string().required().messages({
      'string.empty': 'This field is required',
    }),
    city: Joi.string()
      .required()
      .messages({'string.empty': 'This field is required'}),
    postCode: Joi.string().required().messages({
      'string.empty': 'This field is required',
    }),
    taxOrIdNumber: Joi.string().required().messages({
      'string.empty': 'This field is required',
    }),
    certificateCompany: Joi.string().required().messages({
      'string.empty': 'This field is required',
    }),
  }),
});

export const updateSchema = Joi.object({
  password: Joi.string()
    .required()
    .pattern(new RegExp('^[a-z/A-Z/0-9]{6,12}$'))
    .messages({
      'string.empty': 'This field is required',
      'string.pattern.base':
        'this password does not meet the requirements [a-z/A-Z/0-9], min(6)max(12) character password, ',
    }),
  passwordConfirmation: Joi.string()
    .required()
    .valid(Joi.ref('password'))
    .messages({'any.only': 'Passwords do not match'}),
});
