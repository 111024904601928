import React from 'react';
import {Col} from 'react-awesome-styled-grid';
import {FontWeight, Heading, Text} from 'styled-typography';
import {CardCart} from '../pages/CartPage/styles';
import {EmptyOrdersStyle} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import ButtonProduct from '../core/ButtonProduct';

const EmptyAddress = () => {
  const logoEmpty = useImgixImage('/images/no-results.png');

  return (
    <EmptyOrdersStyle>
      <CardCart height="100%" width="80%">
        <div className="container">
          <Heading level="3" as="h2" fontWeight={(FontWeight.Bold = 600)}>
            Addresses
          </Heading>
        </div>
        <Col md={8} align="center">
          <img src={logoEmpty} />
        </Col>
        <Col md={8} align="center">
          <Text className="title1-normal" fontWeight={(FontWeight.Bold = 600)}>
            You have no addresses saved
          </Text>
        </Col>
        <Col md={8} align="center">
          <Text level="5" as="h4" fontWeight={(FontWeight.Bold = 400)}>
            Here you will see all the addresses that you have added in your
            account
          </Text>
        </Col>
        <Col md={8} align="center">
          <div className="container-button">
            <ButtonProduct width="131px" height="30px">
              ADD ADDRESS +
            </ButtonProduct>
          </div>
        </Col>
      </CardCart>
    </EmptyOrdersStyle>
  );
};

export default EmptyAddress;
