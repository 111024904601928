import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import {CompleteStyle} from './styles';
import {signIn} from '../../API/makers/users';
import {useGlobalContext} from '../../context';
import ButtonProduct from '../core/ButtonProduct';

const CompleteRegister = ({userLogin, pageQueryRegister}) => {
  const {refreshUser, fetchCurrentOrder} = useGlobalContext();

  useEffect(() => {
    pageQueryRegister == 3 &&
      userLogin &&
      signIn({email: userLogin.email, password: userLogin.password}, () => {
        refreshUser();
        fetchCurrentOrder();
      });
  }, []);

  return (
    <Container>
      <CompleteStyle>
        <Heading level="3" as="h1" justify="start" align="start">
          CONGRATULATIONS!
        </Heading>
        <Text level="4">
          <span>YOUR ACCOUNT IS NOW READY.</span>
        </Text>
        <div className="container-heading">
          <Row justify="center">
            <Col lg={6}>
              <Text level="6" as="h3">
                WE WILL ADD YOU TO OUR NEWSLETTER SO THAT YOU STAY UP TO DATE
                WITH OUR WEEKLY ARRIVALS.
              </Text>
            </Col>
          </Row>
        </div>
        <ButtonProduct
          width="200px"
          height="40px"
          onClick={() => navigate('/')}
        >
          back to Home
        </ButtonProduct>
      </CompleteStyle>
    </Container>
  );
};

export default CompleteRegister;

CompleteRegister.propTypes = {
  userLogin: PropTypes.object,
  pageQueryRegister: PropTypes.string,
};
