import styled from 'styled-components';

export const NoResultCategoryStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 90px;
  .circle {
    background-color: transparent;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    margin-bottom: 30px;
  }
  h1 {
    font-weight: 500;
    margin-bottom: 15px;
  }
  h2 {
    color: ${({theme}) => theme.colors.secondary};
    margin-bottom: 30px;
  }
  .container-buttons {
    flex-direction: row;
  }
`;
