import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const FormRegisterStyle = styled.div`
  display: ${({display}) => (display ? 'flex' : 'none')};
  width: 100%;
  background-color: white;
  justify-content: center;
  .container-captcha {
    margin-bottom: 30px;
  }
  .container-form {
    width: 84%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
    ${props => config(props).media['sm']`
      width: 40%;
    `}
    ${props => config(props).media['md']`
      width: 60%;
    `}
    ${props => config(props).media['lg']`
      width: 40%;
    `}
    ${props => config(props).media['xl']`
      width: 30%;
    `}
    button {
      margin-top: 33px;
    }
    h2 {
      margin-bottom: 40px;
    }

    img {
      margin-left: 5px;
    }
    .input-select {
      height: 44px;
      width: 100%;
      background-color: white;
      border-radius: 6px;
      outline: none;
      padding: 0 20px;
      font-size: 0.8em;
      border-style: solid;
      border-color: ${({theme}) => theme.colors.secondary2};
      border-width: 1.5px;
      margin-top: 10px;
      margin-bottom: 10px;
      ${props => config(props).media['sm']`
        width: 270px;
      `}
      :focus {
        outline: none;
        border: solid 1px ${({theme}) => theme.colors.primary2};
      }
    }
  }

  .field {
    display: flex;
    justify-content: space-between;
  }
  input {
    margin-right: 10px;
  }
  a {
    color: ${({theme}) => theme.colors.primary2};
  }
  label {
    margin-bottom: 20px;
  }

  .error-wrapper {
    margin-bottom: 18px;

    > span {
      color: ${({theme}) => theme.colors.primary2};
      margin: 0px;
      padding: 0px;
      bottom: 0px;
      left: 0px;
      width: 80%;
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
    }
  }

  .popper-password {
    #tooltip {
      display: none;
    }
    :hover {
      #tooltip {
        display: inline-block;
      }
    }
  }

  #tooltip {
    display: inline-block;
    background: ${({theme}) => theme.colors.secondary3};
    font-weight: normal;
    padding: 15px 30px;
    font-size: 13px;
    border-radius: 4px;
  }

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
  }

  #arrow {
    visibility: hidden;
  }

  #arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  #tooltip[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
  }

  #tooltip[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
  }

  #tooltip[data-popper-placement^='left'] > #arrow {
    right: -4px;
  }

  #tooltip[data-popper-placement^='right'] > #arrow {
    left: -4px;
  }
`;

export const CompleteStyle = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    color: green;
    cursor: copy;
  }
  h3 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .container-heading {
    width: 60%;
  }
  button {
    margin: 20px 0px;
  }
`;
