import React, {useEffect, useState} from 'react';
import makerslogo from './../../images/logos/makers-monogram.svg';
import {LoadingStyle} from './styles';

const TransitionPage = () => {
  const [loadingText, setLoadingText] = useState();
  const text = 'Loading...';

  const animateText = () => {
    loadingText.innerHTML = '';
    let i = 0;
    let interval = setInterval(() => {
      loadingText.innerHTML += text[i];
      i++;
      if (i === text.length) {
        clearInterval(interval);
        setTimeout(() => {
          animateText();
        }, 1000);
      }
    }, 100);
  };

  useEffect(() => {
    !loadingText &&
      setTimeout(() => {
        setLoadingText(document.getElementById('loadingText'));
      }, 1000);
  }, []);

  useEffect(() => {
    loadingText && animateText();
  }, [loadingText]);

  return (
    <LoadingStyle>
      <img id="rotatingImage" src={makerslogo} alt="" />
      <p id="loadingText"></p>
    </LoadingStyle>
  );
};

export default TransitionPage;
