import React from 'react';
import {Col, Container, Hidden, Row} from 'react-awesome-styled-grid';
import {Heading, Text} from 'styled-typography';
import Form from './Form';
import PropTypes from 'prop-types';
import useImgixImage from '../../hooks/useImgixImage';
import Button from '../../components/core/ButtonProduct';
import {
  InstaGalleryStyle,
  LetsBePartnersInfoStyle,
  WeAreHereStyle,
} from './styles';
import {Link} from 'gatsby';

const LetsBePartnersInfo = ({contact, onInputChange, onRegister, errors}) => {
  const logoIg = useImgixImage('/images/ig-icn.png');
  const logoFb = useImgixImage('/images/fb-icn.png');
  const logoYt = useImgixImage('/images/youtube-icon-yellow.svg');

  const MapXS = useImgixImage('/images/Map.png', {
    w: 571,
    h: 255,
  });
  const regularMap = useImgixImage('/images/Map.png');
  const logoIgBlack = useImgixImage('/images/ig-icn-black.png');
  const igPhoto1 = useImgixImage('/images/igPhoto1.png');
  const igPhoto2 = useImgixImage('/images/igPhoto2.png');
  const igPhoto3 = useImgixImage('/images/igPhoto3.png');
  const igPhoto4 = useImgixImage('/images/igPhoto4.png');
  const igPhoto5 = useImgixImage('/images/igPhoto5.png');

  return (
    <Container>
      <LetsBePartnersInfoStyle>
        <Row>
          <Col
            justify={{xs: 'center', sm: 'left'}}
            xs={4}
            sm={8}
            md={4}
            lg={6}
            xl={6}
          >
            <div className="contact-info">
              <Heading className="head4-normal-semibold" as="h1">
                Contact Us
              </Heading>
              <Text level="5" as="h3">
                We will be more than happy to assist you.
              </Text>
              <Text className="title1-normal" as="h2">
                Phone numbers
              </Text>
              <Text level="5" as="h3">
                (305) 576 1195
              </Text>
              <Text className="title1-normal" as="h2">
                Email Address
              </Text>
              <Text level="5" as="h3">
                contact@makersshoes.com
              </Text>
              <Text className="title1-normal" as="h2">
                Social networks
              </Text>
              <div className="social">
                <div className="networks">
                  <img src={logoIg} />
                  <Link
                    to={'https://www.instagram.com/makersshoes_official/'}
                    target="_blank"
                  >
                    <Text level="6" as="h5">
                      Makersshoes_official
                    </Text>
                  </Link>
                </div>
                <div className="networks">
                  <img src={logoFb} />
                  <Link
                    to={'https://www.facebook.com/makersshoesofficial'}
                    target="_blank"
                  >
                    <Text level="6" as="h5">
                      MakersshoesOfficial
                    </Text>
                  </Link>
                </div>

                <div className="networks">
                  <img src={logoYt} />
                  <Link
                    to={
                      'https://www.youtube.com/channel/UCZVSsjb_374Isu65So8Wlig'
                    }
                    target="_blank"
                  >
                    <Text level="6" as="h5">
                      Maker&apos;s Shoes Official
                    </Text>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4} sm={8} md={4} lg={6} xl={6} align="center">
            <Form
              contact={contact}
              onInputChange={onInputChange}
              onRegister={onRegister}
              errors={errors}
            />
          </Col>
        </Row>
      </LetsBePartnersInfoStyle>

      <WeAreHereStyle>
        <Row>
          <Col
            xs={4}
            sm={8}
            md={3}
            lg={4}
            xl={4}
            align={{xs: 'center', md: 'flex-end'}} //PARA CAMBIAR POSICION DEPENDIENDO DE EL SIZE
          >
            <div className="weAreHere">
              <Heading className="head4-normal-semibold" as="h1">
                We are here
              </Heading>
              <Text level="4" as="h4">
                Showroom
              </Text>
              <Text level="4" as="h3">
                2059 NW 24th Avenue
              </Text>
              <Text level="4" as="h3">
                Miami FL 33142
              </Text>
              <Link
                to={
                  'https://www.google.com/maps/dir//2059+NW+24th+Ave,+Miami,+FL+33142,+EE.+UU./@25.7954158,-80.2375747,17z/data=!4m17!1m7!3m6!1s0x88d9b7288389a919:0x5d1715122dde9db7!2s2059+NW+24th+Ave,+Miami,+FL+33142,+EE.+UU.!3b1!8m2!3d25.795411!4d-80.235386!4m8!1m0!1m5!1m1!1s0x88d9b7288389a919:0x5d1715122dde9db7!2m2!1d-80.235386!2d25.795411!3e2?hl=es'
                }
                target="_blank"
              >
                <Button>MAP INDICATIONS</Button>
              </Link>
              <hr />
              <Text className="title1-normal" as="h2">
                Showroom Hours
              </Text>

              <Text level="5" as="h3">
                Monday - Friday: 9:00 AM - 5:00 PM
              </Text>
            </div>
          </Col>
          <Col
            xs={4}
            sm={8}
            md={5}
            lg={8}
            xl={8}
            justify="center"
            align="center"
          >
            <Hidden xs>
              <div className="map">
                <img src={regularMap} />
              </div>
            </Hidden>
            <Hidden sm md lg xl>
              <div className="map">
                <img src={MapXS} />
              </div>
            </Hidden>
          </Col>
        </Row>
      </WeAreHereStyle>

      <InstaGalleryStyle>
        <Row>
          <Col xs={4} sm={8} md={8} lg={12} xl={12} align="center">
            <img src={logoIgBlack} />
            <Heading className="title2-normal" as="h1">
              We are on Instagram
            </Heading>
            <Link
              to={'https://www.instagram.com/makersshoes_official/'}
              target="_blank"
            >
              <Text level="5" as="h2">
                @makersshoes_official
              </Text>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={2} sm={2.6} md={1.6} lg={2.4} xl={2.4} align="center">
            <div className="instaImg">
              <img src={igPhoto1} />
            </div>
          </Col>
          <Col xs={2} sm={2.6} md={1.6} lg={2.4} xl={2.4} align="center">
            <div className="instaImg">
              <img src={igPhoto2} />
            </div>
          </Col>
          <Col xs={2} sm={2.6} md={1.6} lg={2.4} xl={2.4} align="center">
            <div className="instaImg">
              <img src={igPhoto3} />
            </div>
          </Col>
          <Col xs={2} sm={4} md={1.6} lg={2.4} xl={2.4} align="center">
            <div className="instaImg">
              <img src={igPhoto4} />
            </div>
          </Col>
          <Col xs={4} sm={4} md={1.6} lg={2.4} xl={2.4} align="center">
            <div className="instaImg">
              <img src={igPhoto5} />
            </div>
          </Col>
        </Row>
      </InstaGalleryStyle>
    </Container>
  );
};

export default LetsBePartnersInfo;
LetsBePartnersInfo.propTypes = {
  contact: PropTypes.object,
  onInputChange: PropTypes.func,
  onRegister: PropTypes.func,
  errors: PropTypes.array,
};
