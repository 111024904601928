import styled from 'styled-components';

export const ResetPasswordStyle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 60px auto;

  .styles__CardCart-ksJDkD {
    margin-right: 0px;
    margin-left: 0px;
  }
  .styles__StyledButtonProduct-iZCPvx {
    margin-left: 0px;
  }
  h1 {
    justify-content: center;
    align-items: center;
    display: flex;
    color: ${({theme}) => theme.colors.primary2};
    padding-left: 0px !important;
  }
  .wrapper-input {
    margin-left: 20px;
    margin-right: 20px;
  }
  .container-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
