import styled from 'styled-components';

export const ButtonAddres = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 30px;
  width: 30px;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.colors.secondary};
  margin-right: 18px;
  margin-top: 40px;
  :hover {
    cursor: pointer;
    border: 1px solid ${({theme}) => theme.colors.primary};
  }
  :active {
    border: 1px solid ${({theme}) => theme.colors.primary2};
  }
`;
