import React, {useCallback} from 'react';
import {Text} from 'styled-typography';
import PropTypes from 'prop-types';
import {ButtonPagination, PaginationStyle} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import {useQueryParam, StringParam} from 'use-query-params';

const Pagination = ({pages, currentPage}) => {
  const arrowleft = useImgixImage('/images/icon/arrows/arrow-left.png');
  const arrowright = useImgixImage('/images/icon/arrows/arrow-right.png');
  const [, setPageQuery] = useQueryParam('page', StringParam);
  const pagesArray = Array.from(Array(pages).keys());
  const isNextDisabled = currentPage === pages;
  const isPrevDisabled = currentPage === 1;

  const handlePageQuery = page => {
    setPageQuery(page + 1);
  };

  const handlePageArrowsAhead = useCallback(
    page => {
      !isNextDisabled && setPageQuery(page + 1);
    },
    [isNextDisabled]
  );

  const handlePageArrowsAttracted = useCallback(
    page => {
      !isPrevDisabled && setPageQuery(page - 1);
    },
    [isPrevDisabled]
  );

  return (
    <PaginationStyle>
      <ButtonPagination
        disabled={isPrevDisabled}
        onClick={() => handlePageArrowsAttracted(currentPage)}
      >
        <img src={arrowleft}></img>
      </ButtonPagination>
      {pagesArray.map((page, index) => (
        <ButtonPagination
          key={index}
          active={currentPage === index + 1}
          onClick={() => handlePageQuery(index)}
        >
          <Text as="h1" level="5">
            {page + 1}
          </Text>
        </ButtonPagination>
      ))}
      <ButtonPagination
        disabled={isNextDisabled}
        onClick={() => handlePageArrowsAhead(currentPage)}
      >
        <img src={arrowright}></img>
      </ButtonPagination>
    </PaginationStyle>
  );
};

export default Pagination;
Pagination.propTypes = {
  pages: PropTypes.object,
  perPage: PropTypes.object,
  currentPage: PropTypes.object,
};
