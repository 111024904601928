import styled from 'styled-components';

import {config} from 'react-awesome-styled-grid';

export const HeaderWeAreMakersStyle = styled.div`
  display: flex;
  width: 100%;
  padding-top: 85px;
  padding-bottom: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ContainerText {
    display: flex;
    width: 100%;
    justify-content: center;
    h1 {
      display: flex;
      color: ${({theme}) => theme.colors.secondary};
      text-transform: uppercase;
      font-weight: 700;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 40px;
      margin-left: 34px;
      margin-right: 34px;
      letter-spacing: 0.1em;
    }
    .Line {
      display: flex;
      position: relative;
      top: 10px;
      border-top: 1px solid ${({theme}) => theme.colors.secondary2};
      width: 21%;
    }
  }
  .ContainerHeading {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 40px;
    h2 {
      display: flex;
      justify-content: center;
      color: ${({theme}) => theme.colors.primary2};
      text-align: center;
      font-weight: 500;
      width: 459px;
      font-size: 30px;
      ${props => config(props).media['md']`
        width: 859px;
      font-size: 46px;
    `}
    }
  }
  .ContainerImage {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 38px;

    img {
      padding-left: 10px;
    }
  }
  span {
    flex-direction: column;
    font-size: 30px;
    padding-top: 60px;
    text-align: center;
    color: ${({theme}) => theme.colors.primary2};
    ${props => config(props).media['md']`
    padding-top: 120px;
 
  `}
  }
  .ContainerInformation {
    display: flex;
    margin: 0 auto;
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${props => config(props).media['md']`
    margin: 0 auto;
  width: 71%;
  `}
    h3 {
      flex-direction: column;
    }
    .italico {
      font-style: italic;
      color: black;
    }
  }
`;
