import React from 'react';
import PropTypes from 'prop-types';
import {FontWeight, Heading, Text} from 'styled-typography';

import Address from '../Address';
import {AddressesStyle, CardCart} from './styles';
import ButtonProduct from '../core/ButtonProduct';

const Addresses = ({listAddress}) => {
  return (
    <AddressesStyle>
      <CardCart height="100%" width="100%">
        <div className="container">
          <div className="container-button">
            <Heading level="3" as="h2" fontWeight={(FontWeight.Bold = 600)}>
              Addresses
            </Heading>
            {!process.env['GATSBY_ORDER_ACTIVATION'] === false ? (
              ''
            ) : (
              <ButtonProduct width="147px">NEW ADDRESS +</ButtonProduct>
            )}
          </div>
          <div className="container-order">
            <table>
              <tbody>
                <tr>
                  <td>
                    <Text
                      level="6"
                      as="h3"
                      fontWeight={(FontWeight.Bold = 600)}
                    >
                      NAME
                    </Text>
                  </td>
                  <td>
                    <Text
                      level="6"
                      as="h3"
                      fontWeight={(FontWeight.Bold = 600)}
                    >
                      ADDRESS
                    </Text>
                  </td>
                  <td>
                    <Text
                      level="6"
                      as="h3"
                      fontWeight={(FontWeight.Bold = 600)}
                    >
                      LOCATION
                    </Text>
                  </td>
                  {/* <td></td> */}
                </tr>
                {listAddress &&
                  listAddress?.map(
                    (
                      {address1, address2, city, name, country, state},
                      index
                    ) => {
                      return (
                        <tr key={index}>
                          <Address
                            name={name}
                            address={`${address1}, ${city} ${
                              address2 ? address2 : ''
                            }`}
                            location={`${state.name}, ${country.name}`}
                          />
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </CardCart>
    </AddressesStyle>
  );
};

export default Addresses;

Addresses.propTypes = {
  listAddress: PropTypes.array,
};
