import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const MoreProductsStyle = styled.div`
  padding: 0 6px;
  ${props => config(props).media['sm']`
    padding: 0px 30px;
    `}
  h3 {
    margin-top: 60px;
    margin-left: 24px;
  }
  .ContainerFilter {
    margin-top: 30px;
    margin-bottom: 0px;
    ${props => config(props).media['sm']`
      margin-bottom: 30px;
    `}
    .dropdown-container {
      margin-right: 15px;
      margin-bottom: 16px;
      min-width: 120px;
      ${props => config(props).media['sm']`
        margin-bottom: 0px;
      `}
    }
    .wrap__filter-button {
      display: flex;
      flex-direction: column;
      ${props => config(props).media['sm']`
        flex-direction: row;
      `}
      .wrap_style-price {
        display: flex;
      }
      .wrap_size-sortby {
        display: flex;
        justify-content: start;
        width: 100%;
        ${props => config(props).media['sm']`
          justify-content: space-between;
        `}
      }
    }
  }

  .FilterSelected {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 8px;
    .ContainerSelected {
      margin-bottom: 16px;
      display: flex;
      text-transform: capitalize;
      position: relative;
      margin-right: 15px;
      border-radius: 17px;
      border: 1.5px solid ${({theme}) => theme.colors.secondary2};
      height: 1.875em;
      ${props => config(props).media['sm']`
        height: 1.75em;
      `}
      &:last-child {
        margin-bottom: 36px;
      }
      p {
        display: flex;
        font-weight: 600;
        align-items: center;
        margin: 0 12px;
        white-space: nowrap;
      }
      .DeleteIcon {
        right: 88px;
        bottom: 5px;
        margin-bottom: 5px;
        margin-top: 6px;
        margin-right: 7px;
        margin-left: 14px;
        cursor: pointer;
        ${props => config(props).media['sm']`
          margin-top: 4px;
          margin-bottom: 5px ;
        `}
      }
    }
  }

  .skeletonResultProductStyle {
    width: 150px;
    height: 170px;
    border-radius: 20px;
    margin-left: 60px;
    margin-top: 30px;
    align-items: center;
    ${props => config(props).media['md']`
      width: 207px;
      height: 207px;
      margin-left: 30px;
      margin-top: 30px;
    `}
    ${props => config(props).media['lg']`
      width: 307px;
      height: 307px;
      margin-left: 30px;
      margin-top: 30px;
    `}
  }

  .skeletonStyle-loadingCard {
    width: 365px;
    height: 350px;
    ${props => config(props).media['md']`
      width: 1040px;
    `}
  }

  .skeletonStyle {
    margin-left: 25px;
    border-radius: 10%;
    margin-top: 30px;
    :last-child {
      margin-right: 35px;
    }
  }
`;
