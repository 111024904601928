import {config} from 'react-awesome-styled-grid';
import styled from 'styled-components';

export const StyledButtonAllShoes = styled.button`
  height: ${({height}) => height};
  background-color: ${({theme}) => theme.colors.bgColor};
  border: 1px solid ${({theme}) => theme.colors.secondary2};
  border-radius: 28px;
  outline: none;
  color: white;
  cursor: pointer;
  margin-bottom: 10px;
  min-width: 120px;
  max-width: 120px;
  margin-right: 4px;
  margin-left: 4px;
  ${props => config(props).media['sm']`
    min-width: 140px;
    max-width: 140px;
    margin-right: 20px;
    margin-left: 0px;
  `}
  p {
    color: black;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 15px;
    margin-right: 15px;
    ${props => config(props).media['sm']`
      font-size: 13px;
    `}
  }
  :hover {
    border: 1px solid ${({theme}) => theme.colors.secondary};
  }
  &:active {
    outline: none;
    background-color: ${({theme}) => theme.colors.primary2};
    border: none;
    p {
      color: white;
    }
  }
  &:focus {
    outline: none;
    background-color: ${({theme}) => theme.colors.primary2};
    border: none;
    p {
      color: white;
    }
  }
`;
