import React from 'react';
import PropTypes from 'prop-types';
import {Col} from 'react-awesome-styled-grid';
import {FontWeight, Link, Text} from 'styled-typography';

const Order = ({order, product, date, status, total, invoice}) => {
  return (
    <>
      <td>
        <Col className="info-order-number">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 600)}>
            {order}
          </Text>
        </Col>
        <Col>
          {invoice && (
            <Link
              href={invoice}
              target="_blank"
              fontWeight={(FontWeight.Bold = 600)}
            >
              Invoice
            </Link>
          )}
        </Col>
      </td>
      <td>
        <Col className="info-product">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 450)}>
            {product}
          </Text>
        </Col>
        <Col>
          <Link fontWeight={(FontWeight.Bold = 600)}>See all</Link>
        </Col>
      </td>
      <td>
        <Col className="info-date">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 450)}>
            {date}
          </Text>
        </Col>
      </td>
      <td>
        <Col className="info-status">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 450)}>
            {status}
          </Text>
        </Col>
        <Col>
          <Link fontWeight={(FontWeight.Bold = 600)}>Follow</Link>
        </Col>
      </td>
      <td>
        <Col className="info-total">
          <Text level="6" as="h5" fontWeight={(FontWeight.Bold = 600)}>
            {total}
          </Text>
        </Col>
      </td>
    </>
  );
};

export default Order;

Order.propTypes = {
  order: PropTypes.string,
  product: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  total: PropTypes.string,
  invoice: PropTypes.string,
};
