import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import {useGlobalContext} from '../../../context';

const DropdownButton = ({option, ...props}) => {
  const {filter, setFilter} = useGlobalContext();
  const label = option.label;

  const handleClear = key => {
    setFilter({
      ...filter,
      [key]: [],
    });
  };

  return (
    <Button
      width="100%"
      height="30px"
      {...props}
      onClick={() => handleClear(label)}
    >
      CLEAR
    </Button>
  );
};

export default DropdownButton;

DropdownButton.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
  }),
};
