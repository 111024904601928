import React from 'react';
import {Heading} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import MightAlsoLike from '../../MightAlsoLike';
import {CartPageStyle, CardCart} from './styles';
import Skeleton from 'react-loading-skeleton';

const SkeletonCart = () => {
  return (
    <Container>
      <CartPageStyle>
        <Row>
          <Heading as="h2" level="6">
            Makers | <span>Cart</span>
          </Heading>
        </Row>
        <Row>
          <Heading as="h1" level="3">
            Your cart
          </Heading>
        </Row>
        <Row align="start">
          <Col xs={4} md={5.5}>
            <Skeleton className="skeletonStyle" circle={false} count={1} />
          </Col>
          <Col xs={4} sm={8} md={2.5}>
            <Skeleton className="skeletonStyle" circle={false} count={1} />
          </Col>
        </Row>
      </CartPageStyle>
      <MightAlsoLike />
    </Container>
  );
};

export default SkeletonCart;

CardCart.defaultProps = {
  height: '235px',
  width: '360px',
  item_total: 0,
  total: 0,
};
