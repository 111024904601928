import {setup_intent_url} from './urls';
import {post} from './fetcher';

export const getSetupIntentId = async ({user}, callback = () => {}) => {
  const response = await post(`${setup_intent_url}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user}`,
    },
  });

  if (!response.ok) {
    return;
  }

  const stripe_response = await response.json();

  callback(stripe_response);
};
