import React from 'react';
import PropTypes from 'prop-types';

const DropdownItem = ({checked, option, onClick, disabled}) => {
  return (
    <div className={`item-renderer ${disabled && 'disabled'}`}>
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span>{option.label}</span>
    </div>
  );
};
DropdownItem.propTypes = {
  checked: PropTypes.string,
  option: PropTypes.array,
  onClick: PropTypes.element,
  disabled: PropTypes.element,
};

export default DropdownItem;
