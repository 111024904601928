import React from 'react';
import PropTypes from 'prop-types';
import {FontWeight, Text} from 'styled-typography';

const PersonalInformation = ({title, name, address, city, country, phone}) => {
  return (
    <>
      <div className="text-wrapper">
        <Text level="5" as="h5" fontWeight={(FontWeight.Bold = '700')}>
          {title}
        </Text>
        <Text level="6" as="h2" fontWeight={(FontWeight.Bold = '400')}>
          {name}
        </Text>
        <Text level="6" as="h2" fontWeight={(FontWeight.Bold = '400')}>
          {address}
        </Text>
        <Text level="6" as="h2" fontWeight={(FontWeight.Bold = '400')}>
          {city}
        </Text>
        <Text level="6" as="h2" fontWeight={(FontWeight.Bold = '400')}>
          {country}
        </Text>
        <Text level="6" as="h2" fontWeight={(FontWeight.Bold = '400')}>
          {phone}
        </Text>
      </div>
    </>
  );
};

export default PersonalInformation;
PersonalInformation.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  phone: PropTypes.string,
};
