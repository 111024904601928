import React, {useEffect, useState} from 'react';
import {FontWeight, Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import Cart from '../../Cart';
import SkeletonFavorite from './skeletonFav';
import {YourFavoritePageStyle} from './styles';
import MightAlsoLike from '../../MightAlsoLike';
import {useGlobalContext} from '../../../context';
import useImgixImage from '../../../hooks/useImgixImage';
import EmptyOrders from '../../EmptyAccount/EmptyOrders';
import {fetchProductIds} from '../../../API/makers/products';

const YourFavoritePage = () => {
  const trash = useImgixImage('/images/trash.png');
  const {
    listFavoriteId,
    getListFavorites,
    favorite_products,
    deleteOnListFavorite,
  } = useGlobalContext();
  const [error, setError] = useState(false);
  const [productIds, setProductIds] = useState();
  const {products, isLoading} = fetchProductIds(productIds);
  useEffect(() => {
    length;
    getListFavorites();
    if (favorite_products.length === 0) {
      setTimeout(() => setError(true), 100);
    }
  }, []);

  useEffect(() => {
    const productIds = favorite_products.map(({product_id}) => product_id);
    setProductIds(productIds);
    if (favorite_products.length === 0) {
      setTimeout(() => setError(true), 1000);
    }
  }, [listFavoriteId]);

  if (isLoading) {
    return <SkeletonFavorite />;
  }

  return (
    <Container>
      <YourFavoritePageStyle>
        <Row>
          <Heading as="h2" level="6">
            Maker’s | <span>Favorite</span>
          </Heading>
        </Row>
        <Row>
          <Heading as="h1" level="3">
            Your favorites
          </Heading>
        </Row>
        <Row align="center" lg={10}>
          {!isLoading && favorite_products.length !== 0 && (
            <Col xs={4} md={6} lg={6} align="center" justify="center">
              {products.map(({id, name, price, master: {images}}) => {
                return (
                  <Cart
                    key={id}
                    name={name}
                    img={images[0]?.product_url}
                    numberReff={id}
                    reff={` REF ${
                      id ? `${id}`.padStart(6, '0') : 'REF 001234'
                    }`}
                    price={price}
                    trashLogo={trash}
                    disableFavorite
                    onClick={() => deleteOnListFavorite(id)}
                    description={
                      <>
                        <Text
                          fontWeight={(FontWeight.Normal = '500')}
                          level="6"
                          as="h5"
                        >
                          Status: <span>In Stock</span>
                        </Text>

                        <Text
                          fontWeight={(FontWeight.Normal = '500')}
                          level="6"
                          as="h6"
                        >
                          Sizes run available: <span>S · L · O · A · B</span>
                        </Text>
                      </>
                    }
                  />
                );
              })}
            </Col>
          )}
          {!isLoading && error && favorite_products.length === 0 && (
            <Col xs="4" md={8} lg={8}>
              <EmptyOrders
                title={'there are no favorites on your wish list'}
                subtitle={'You have not placed favorites'}
                text={'The favorites you add in our store will appear here.'}
              />
            </Col>
          )}
        </Row>
      </YourFavoritePageStyle>
      <MightAlsoLike />
    </Container>
  );
};

export default YourFavoritePage;
