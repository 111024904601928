import React from 'react';
import {Link, navigate} from 'gatsby';
import {Text, FontWeight} from 'styled-typography';

import {useGlobalContext} from '../../context';
import {CardCart} from '../pages/CartPage/styles';
import {MenuAccountStyle, MenuItem} from './styles';
import {logout} from '../../services/authentication';
import useImgixImage from '../../hooks/useImgixImage';
import {useQueryParam, StringParam} from 'use-query-params';
import {clearCurrentOrder} from '../../services/temporalOrder';

const MenuAccount = () => {
  const iconAccount = useImgixImage('/images/account-icn.png');
  const iconAddress = useImgixImage('/images/address-icn.png');
  const iconLogout = useImgixImage('/images/logout-icn.png');
  const iconShop = useImgixImage('/images/shop-icn.png');
  const {refreshUser} = useGlobalContext();

  const handleLogOut = () => {
    logout(() => {
      navigate('/');
      clearCurrentOrder();
      refreshUser();
    });
  };

  const [PageQueryAccount] = useQueryParam('setting', StringParam);
  return (
    <>
      <MenuAccountStyle>
        <CardCart height="248px" width="360px">
          <div className="wrapper-menu">
            <div className="menu">
              <Text level="5" as="h1" fontWeight={FontWeight.Bold}>
                Menu
              </Text>
            </div>
            <Link to={'/account?setting=personal-info'}>
              <MenuItem
                level="5"
                as="h2"
                active={PageQueryAccount === 'personal-info'}
              >
                <img src={iconAccount} /> Account
              </MenuItem>
            </Link>
            {!process.env['GATSBY_ORDER_ACTIVATION'] === true ? (
              ''
            ) : (
              <Link to={'/account?setting=orders'}>
                <MenuItem
                  level="5"
                  as="h2"
                  active={PageQueryAccount == 'orders'}
                >
                  <img src={iconShop} /> Orders
                </MenuItem>
              </Link>
            )}
            {!process.env['GATSBY_ORDER_ACTIVATION'] === true ? (
              ''
            ) : (
              <Link to={'/account?setting=addresses'}>
                <MenuItem
                  level="5"
                  as="h2"
                  active={PageQueryAccount == 'addresses'}
                >
                  <img src={iconAddress} /> Addresses
                </MenuItem>
              </Link>
            )}
            <MenuItem level="5" as="h2" onClick={handleLogOut}>
              <img src={iconLogout} /> Log out
            </MenuItem>
          </div>
        </CardCart>
      </MenuAccountStyle>
    </>
  );
};

export default MenuAccount;
