import React from 'react';

import {Col, Container, Row} from 'react-awesome-styled-grid';
import {HeaderAllShoesStyled} from './styles';
import Skeleton from 'react-loading-skeleton';

const SkeletonAllShoes = () => {
  return (
    <HeaderAllShoesStyled>
      <Container>
        <Row>
          <Skeleton />
        </Row>
        <Row>
          <Skeleton width={200} height={50} />
        </Row>
        <Row>
          <Skeleton width={600} height={90} />
        </Row>
        <Row>
          <Col>
            <Skeleton
              className="skeletonStyle"
              width={100}
              height={40}
              count={7}
            />
          </Col>
        </Row>
      </Container>
    </HeaderAllShoesStyled>
  );
};

export default SkeletonAllShoes;
