import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const InfoFormStyle = styled.div`
  width: 90%;
`;

const cardTypeSizes = {
  small: 4,
  large: 1.5,
};
const width = 160;
const height = 160;

export const CardLogo = styled.div`
  margin-left: 20px;
  display: inline-block;
  background-image: url(${({type}) => [type]});
  width: ${({size}) =>
    cardTypeSizes[size] ? width / cardTypeSizes[size] : width}px;
  height: ${({size}) =>
    cardTypeSizes[size] ? height / cardTypeSizes[size] : height}px;
  background-size: ${({size}) =>
      cardTypeSizes[size] ? width / cardTypeSizes[size] : width}px
    ${({size}) =>
      cardTypeSizes[size] ? height / cardTypeSizes[size] : height}px;
`;

export const OrderSummaryWrapp = styled.div`
  margin-left: 30px;
  ${props => config(props).media['md']`
    margin-left: 0px;
  `}
  .container-order {
    margin-top: 0px;
    margin-bottom: 40px;
    ${props => config(props).media['md']`
      margin-top: 60px;
    `}
  }
`;

export const InfoAddressStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0px;
  h1 {
    margin: 0px;
    margin-bottom: 20px;
    font-size: ${({theme}) => theme.typography.fontSizes[5]};
  }
  h2 {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    text-align: left;
    justify-content: flex-start !important;
    font-size: ${({theme}) => theme.typography.fontSizes[5]};
  }
  h5 {
    margin-bottom: 0px;
  }
  .wrap-card-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${props => config(props).media['xs']`
      align-items: flex-start;
      justify-content: flex-start;
      `}
    ${props => config(props).media['md']`
      flex-direction: row;
      align-items: center;
      `}
    .wrap-info-address {
      width: 90%;
      height: 120px;
      background-color: white;
      border-radius: 5px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 0px 20px 20px 0px;
      ${props => config(props).media['md']`
      min-width: 40%;
      `}
    }
  }
  label {
    margin: 20px;
  }
  input {
    margin: auto 0px auto 20px;
    :hover {
      cursor: pointer;
    }
  }
`;

export const InfoCheckStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  label {
    margin-top: 20px;
  }
`;

export const StylePayments = styled.div`
  width: 100%;
  margin-top: 60px;
  .wrap-info-creditCard {
    min-width: 100%;
    height: 70px;
    background-color: white;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 0px 20px 0px;
    h2 {
      text-transform: uppercase;
    }
  }
  .container-payments {
    width: 100%;
    display: flex;
    flex-direction: column;
    ${props => config(props).media['md']`
      width: 100%;
      margin-left: 0px;
    `}
    .input-select {
      width: 95%;
    }
    .wrapper-radius {
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        justify-content: center;
        align-items: center;
      }
    }
  }
  h1 {
    margin-top: 16px;
  }
  .skeletonStyle {
    width: 135px;
    height: 135px;
    border-radius: 10%;
    align-items: center;
    margin: 0px 0px 30px 0px;
    ${props => config(props).media['md']`
      width: 500px;
      height: 250px;
      margin: 0px 0px 50px 0px;
    `};
  }
  .container-order {
    width: 100%;
    margin: 0px;
    display: flex;
    align-items: flex-start !important;
    justify-content: center;
    margin-bottom: 40px;
  }
`;

export const ConfirmStyle = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    margin-top: 60px;
    margin-bottom: 20px;
  }
  h5 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .container-view {
    margin-top: 20px;
    margin-bottom: 40px;
    a {
      cursor: pointer;
      font-weight: 600;
      :hover {
        color: ${({theme}) => theme.colors.primary2};
        border-bottom: 1px solid ${({theme}) => theme.colors.primary2};
      }
    }
  }
  .container-heading {
    width: 60%;
  }
  .container-confirm {
    width: 90%;
    display: flex;
    background-color: white;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 20px;
    ${props => config(props).media['md']`
      width: 60%;
    `}
    .wrapper-information {
      display: flex;
      width: 90%;
      /* justify-content: center; */
      flex-direction: column;
      ${props => config(props).media['md']`
        justify-content: space-around;
        flex-direction: row;
      `}
      h5 {
        padding-left: 10px;
        text-align: center;
        ${props => config(props).media['md']`
          padding-left: 20px;
          text-align: left;
        `}
      }
    }
    .text-wrapper {
      display: flex;
      flex-direction: column;
      margin-right: 10px;
      h2 {
        justify-content: center !important;
        margin-bottom: 5px;
        text-align: center;
        ${props => config(props).media['md']`
        justify-content: flex-start !important;
          text-align: left;
        `}
      }
    }
    p {
      margin-bottom: 5px;
    }
  }
  .text-area {
    margin: 0px 60px 20px 60px;
    width: 80%;
    border-top: 1px solid ${({theme}) => theme.colors.secondary2};
    label {
      margin: 20px 0px 20px 0px;
      padding: 20px 20px 0px 20px;
    }
    input {
      font-size: ${({theme}) => theme.typography.fontSizes[5]};
      margin: 0 20px 0px 20px;
      border: 1px solid ${({theme}) => theme.colors.secondary2};
      padding: 0px 10px 0px 10px;
      &:active {
        outline: none;
        border: 2px solid 1px solid ${({theme}) => theme.colors.primary2};
      }
      &:focus {
        outline: none;
        border: 2px solid 1px solid ${({theme}) => theme.colors.primary2};
      }
    }
    p {
      margin: 0px 20px 20px 20px !important;
      text-align: justify;
    }
  }
  button {
    margin: 20px 20px;
  }
  span {
    font-weight: 800;
  }
`;

export const ProductStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${props => config(props).media['md']`
    margin-left: 0px;
  `}
  .container-product {
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid ${({theme}) => theme.colors.secondary2};
    border-bottom: 1px solid ${({theme}) => theme.colors.secondary2};
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .image-setting {
      width: 80%;
      max-width: 150px;
      margin-right: 10px;
      margin-top: 30px;
      margin-bottom: 20px;
      ${props => config(props).media['md']`
        
      `}
    }
    .image-setting-empty {
      width: 40%;
      margin-right: 10px;
      margin-top: 30px;
      margin-bottom: 20px;
      ${props => config(props).media['md']`
        
      `}
    }
  }
`;

export const CompleteStyle = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    color: green;
    cursor: copy;
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h1 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .container-heading {
    width: 60%;
  }
  button {
    margin: 20px 20px;
  }
`;
