import styled from 'styled-components';
import {config} from 'react-awesome-styled-grid';

export const MultiSelectStyle = styled.div`
  .rmsc .gray {
    font-size: 1em;
    letter-spacing: 0.1em;
    font-weight: 600;
    text-transform: uppercase;
    color: black;
    justify-content: center;
  }
  & > div {
    --rmsc-main: ${({theme}) => theme.colors.primary2};
    --rmsc-primary: black;
    --rmsc-selected: transparent;
    --rmsc-background: #fff;
    --rmsc-spacing: 10px;
    --rmsc-height: 30px;
    padding-left: 7px;

    .dropdown-container {
      display: flex;
      align-items: center;
      background-color: transparent;
      width: ${({width}) => width};
      height: 1.875em;
      border-radius: 20px;
      border-color: ${({theme}) => theme.colors.secondary2};
      .dropdown-content {
        width: 14.95em;
        border-radius: 10px;
        left: ${({rightSide}) => (rightSide ? '0px' : 'unset')};
        ${props => config(props).media['sm']`
          left: ${({rightSide}) => (rightSide ? '-120px' : 'unset')};
        `}
        .select-item {
          input {
            border: none;
            :checked {
            }
          }
        }
      }
      .dropdown-heading {
        border-radius: 10px;
        align-items: center;
        cursor: pointer;
        ${props => config(props).media['sm']`
        align-items: center;
        
        `}
        :hover {
          color: ${({theme}) => theme.colors.primary2};
        }
        :active {
          color: ${({theme}) => theme.colors.primary2};
        }
      }
      .dropdown-heading-value {
        font-size: 0.8em;
        letter-spacing: 0.1em;
        font-weight: 600;
        text-transform: uppercase;
        color: black;
        justify-content: center;
        :hover {
          color: ${({theme}) => theme.colors.primary2};
        }
        :active {
          color: ${({theme}) => theme.colors.primary2};
        }
      }
      /* .dropdown-heading-dropdown-arrow {
        
      } */
      .dropdown-search-clear-icon {
        display: none;
      }
    }
  }
  &:hover {
    .dropdown-container {
      border-color: ${({theme}) => theme.colors.secondary};
    }
  }
  &:active {
    .rmsc .gray {
      color: ${({theme}) => theme.colors.primary2};
    }
  }
`;
