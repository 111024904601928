import React from 'react';
import {Container} from 'react-awesome-styled-grid';
import {Text} from 'styled-typography';
import {Link} from 'gatsby';
import {NoResultCategoryStyle} from './styles';
import useImgixImage from '../../hooks/useImgixImage';
import ButtonCategory from '../core/ButtonCategory';
import ButtonSpecial from '../core/ButtonSpecial';

const NoResultCategory = () => {
  const logoEmpty = useImgixImage('/images/no-results.png');

  return (
    <Container>
      <NoResultCategoryStyle>
        <div className="circle">
          <img src={logoEmpty} className="empty-state"></img>
        </div>
        <Text level="3" as="h1">
          No results found
        </Text>
        <Text level="5" as="h2">
          Continue shopping with our latest collection and various categories.
        </Text>
        <div className="container-buttons">
          <Link to={'/all-shoes'}>
            <ButtonCategory>ALL SHOES</ButtonCategory>
          </Link>
          <ButtonSpecial> SPECIAL DEALS</ButtonSpecial>
        </div>
      </NoResultCategoryStyle>
    </Container>
  );
};

export default NoResultCategory;
