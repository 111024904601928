import React from 'react';
import PropTypes from 'prop-types';
import {Heading, Text} from 'styled-typography';
import {Col, Container, Row} from 'react-awesome-styled-grid';

import ButtonBlack from '../core/ButtonBlack';
import {OrderSummaryCard, OrderSummaryStyle} from './styles';

const OrderSummary = ({isCheckout, description, total, onClick, disabled}) => {
  return (
    <Container>
      <OrderSummaryCard justify="center">
        <Row>
          <Heading level="3" as="h1">
            Order summary
          </Heading>
        </Row>
        <Row>
          <OrderSummaryStyle>{description}</OrderSummaryStyle>
          <div className="containter-total">
            <Text level="5" as="h6">
              Total:
            </Text>
            <Text level="5" as="h4">
              {total}
            </Text>
          </div>
        </Row>
        {!isCheckout && (
          <Row>
            <Col justify="center" align="center" className="containter-button">
              <ButtonBlack width="100%" onClick={onClick} disabled={disabled}>
                checkout
              </ButtonBlack>
            </Col>
          </Row>
        )}
      </OrderSummaryCard>
    </Container>
  );
};

export default OrderSummary;
OrderSummary.propTypes = {
  isCheckout: PropTypes.bool,
  description: PropTypes.string,
  total: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
