import * as React from 'react';
import HeaderEmptySearch from '../../HeaderEmptySearch';
import MightAlsoLike from '../../MightAlsoLike';

const ProductsEmptyState = () => {
  return (
    <>
      <HeaderEmptySearch />
      <MightAlsoLike />
    </>
  );
};

export default ProductsEmptyState;
